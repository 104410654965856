export default {
  getUsecases(state, usecases = []) {
    if (!state.authToken) return
    if (Array.isArray(usecases)) state.usecases = usecases
    if (state.usecases.length < 1) state.usecases = [usecases]
    else {
      state.usecases = state.usecases.map(usecase =>
        usecases.usecase_id === usecase.usecase_id ? usecases : usecase
      )
    }
  },

  addUsecase(state, usecase) {
    state.usecases.push(usecase)
  },

  updateUsecase(state, updatedUsecase) {
    state.usecases = state.usecases.map(usecase =>
      Number(usecase.usecase_id) === Number(updatedUsecase.usecase_id)
        ? { ...usecase, ...updatedUsecase }
        : usecase
    )
  },

  deleteUsecase(state, deletedUsecaseId) {
    state.usecases = state.usecases.filter(
      ({ usecase_id }) => deletedUsecaseId !== usecase_id
    )
  }
}
