<template>
  <section>
    <h1 class="mb-2">{{ page.title }}</h1>
    <p
      v-if="usecase && city && usecase.slug && city.slug"
      style="margin-top: -1rem"
      class="mb-2 muted"
    >
      Ga (terug) naar use case
      <router-link :to="`/city/${city.slug}/${usecase.slug}`">{{
        usecase.name
      }}</router-link>
      of naar de stad
      <router-link :to="`/city/${city.slug}`">{{ city.name }}</router-link>
    </p>
    <p v-else style="margin-top: -1rem" class="mb-2 muted">
      <a @click="$router.go(-1)">Ga terug</a>
    </p>
    <div class="page" v-html="page.content"></div>
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  name: "Page",

  computed: {
    usecases() {
      return this.$store.state.usecases
    },
    cities() {
      return this.$store.state.cities
    },
    pages() {
      return this.$store.state.pages
    },
    page() {
      return (
        this.pages.find(({ slug }) => this.$route.params.slug === slug) || {}
      )
    },
    usecase() {
      if (!this.page) return {}
      return this.usecases.find(
        ({ usecase_id }) => usecase_id === this.page.usecase
      )
    },
    city() {
      if (!this.usecase) return {}
      return this.cities.find(({ city_id }) => city_id === this.usecase.city)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getCities")
    this.$store.dispatch("getPages")
  }
})
</script>

<style lang="scss" scoped></style>
