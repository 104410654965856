export default {
  session: null,
  authToken: null,

  user: {},

  users: [],
  orgs: [],
  pages: [],
  projects: [],
  usecases: [],
  cities: [],
  measurements: [],
  kpis: [],
  vehicles: [],
  segments: [],

  error: null,

  showModal: false,

  currentWork: [],

  navigation: {
    usecase: null
  },

  api: {
    up: null
  }
}
