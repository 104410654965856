<template>
  <div>
    <p class="loading-message" v-if="gettingOrgList">
      Organisaties aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th>Naam</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(org, index) in orgs" :key="index">
          <td>{{ org.name }}</td>
          <td class="ta-r actions">
            <router-link
              v-if="userIsAdmin"
              :to="{ path: `/admin/organisations/update/${org.slug}` }"
              class="show-on-hover"
            >
              Wijzig
            </router-link>
            <a v-if="userIsGod" v-on:click="deleteOrg(org)">Verwijder</a>
            <a
              v-else-if="userIsAdmin"
              class="muted"
              v-tooltip="
                'Alleen Christian van Ommeren kan organisaties verwijderen'
              "
              >Verwijder</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingOrg"
    >
      <h3 slot="header">Voeg een organisatie toe</h3>
      <div
        slot="body"
        :class="gettingOrgList || savingOrg ? 'loading-form' : '  '"
      >
        <div class="field">
          <label>Organisatie naam</label>
          <input
            type="text"
            placeholder="Vul organisatie naam in..."
            v-model="name"
          />

          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam in
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"

import { properName } from "../../lib/validators"
import Modal from "../../components/Modal.vue"

export default Vue.extend({
  data() {
    return {
      name: null
    }
  },

  validations: {
    name: { required, minLength: minLength(2), properName }
  },

  components: { Modal },

  computed: {
    ...mapGetters(["gettingOrgList", "userIsAdmin", "userIsGod", "savingOrg"]),

    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.slug
    },

    org() {
      if (!this.orgs) return
      return this.orgs.find(({ slug }) => slug === this.$route.params.slug)
    },

    orgs() {
      return this.$store.state.orgs.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    }
  },

  methods: {
    async save() {
      if (this.savingOrg) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.org ? "updateOrg" : "addOrg"
      const payload = { name: this.name }
      if (this.org) payload.organisation_id = this.org.organisation_id

      const error = await this.$store.dispatch(action, payload)
      if (!(error instanceof Error)) this.closeModal()
    },

    closeModal() {
      return this.$router.push("/admin/organisations")
    },

    deleteOrg({ organisation_id, name }) {
      if (
        this.$store.state.usecases.find(
          ({ organisations }) =>
            organisations && organisations.indexOf(organisation_id) > -1
        )
      )
        return alert(
          `Er zijn use cases gelinkt aan ${name} – link deze aan een andere organisatie voordat je deze kan organisatie te verwijderen.`
        )

      if (
        this.$store.state.users.find(
          ({ organisation }) => organisation === organisation_id
        )
      )
        return alert(
          `Er zijn gebruikers gelinkt aan ${name} – link deze aan een andere organisatie voordat je deze kan organisatie te verwijderen.`
        )

      if (!confirm(`Weet je zeker dat je ${name} wilt verwijderen?`)) return

      this.$store.dispatch("deleteOrg", organisation_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getOrgs", { queueWork: true }).then(() => {
      if (this.org) {
        this.name = this.org.name

        if (this.$v) this.$v.$reset()
      }
    })

    this.$store.dispatch("getUsers")
    this.$store.dispatch("getUsecases")
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null

      if (this.$v) this.$v.$reset()

      return next()
    }

    const org = this.orgs.find(({ slug }) => slug === to.params.slug)

    if (org) {
      this.name = org.name

      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>
