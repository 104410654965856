<template>
  <svg
    height="100%"
    :viewBox="!position.includes('middle') ? '0 0 100 10' : '0 0 10 100'"
    :width="!position.includes('middle') ? '100' : '10'"
    :style="
      `position: absolute; z-index: 1; top: 0; ${
        ['left', 'right'].indexOf(position) > -1 ? position : 'left'
      }: 0; bottom: 0; right: 0;`
    "
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-width="12"
      :transform="
        !position.includes('middle') ? 'rotate(90 5 0) translate(5 -95)' : ''
      "
    >
      <path
        v-if="
          showLine({
            type: 'ride',
            electric: false
          })
        "
        :d="pathPoints(90)"
        :stroke="greyColor"
      />
      <path
        v-if="
          showLine({
            type: 'sail',
            electric: true
          })
        "
        :d="pathPoints(70)"
        :stroke="pinkColor"
      />
      <path
        v-if="
          showLine({
            type: 'ride',
            electric: true
          })
        "
        :d="pathPoints(50)"
        :stroke="yellowColor"
      />
      <path
        v-if="
          showLine({
            type: 'sail',
            electric: false
          })
        "
        :d="pathPoints(30)"
        :stroke="blueColor"
      />
      <path
        v-if="
          showLine({
            type: 'bike'
          })
        "
        :d="pathPoints(10)"
        :stroke="greenColor"
      />
    </g>
  </svg>
</template>

<script>
import { showLine } from "../lib/helpers"

export default {
  name: "FlowChartStripes",

  props: {
    position: {
      default: "left",
      type: String
    },
    journeys: {
      default() {
        return []
      },
      type: Array
    },
    vertical: {
      default() {
        return []
      },
      type: Array
    },
    horizontal: {
      default() {
        return []
      },
      type: Array
    },
    grey: {
      default: "none",
      type: String
    },
    pink: {
      default: "none",
      type: String
    },
    yellow: {
      default: "none",
      type: String
    },
    blue: {
      default: "none",
      type: String
    },
    green: {
      default: "none",
      type: String
    }
  },

  data() {
    return {
      greyColor: "#69696e",
      pinkColor: "#c20764",
      yellowColor: "#f7c209",
      blueColor: "#538cbd",
      greenColor: "#6fbe49"
    }
  },

  methods: {
    showLine(journey) {
      const direction = this.position.startsWith("middle")
        ? "horizontal"
        : "vertical"

      return showLine({
        direction,
        journeys: this.journeys,
        vertical: this.vertical,
        horizontal: this.horizontal,
        journey
      })
    },

    pathPoints(height) {
      return this.position === "middle-left"
        ? `m0 ${height}h5`
        : this.position === "middle-right"
        ? `m5 ${height}h10`
        : `m0 ${height}h10`
    }
  }
}
</script>
