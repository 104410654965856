import ajax from "../lib/ajax"
import { CSVU_DELIMITERS } from "../config/env"

export default {
  async uploadJourney(
    { commit, dispatch, state },
    {
      delimiter = CSVU_DELIMITERS[0].value,
      usecase_id,
      file,
      save = false
    } = {}
  ) {
    dispatch("currentAction", "uploadJourney")
    try {
      return await ajax(
        `journeys?usecase_id=${usecase_id}&save=${save}&delimiter=${delimiter}`,
        {
          authToken: state.authToken,
          method: "POST",
          file
        }
      )
    } catch (error) {
      commit("saveError", error.message)
      throw error
    } finally {
      dispatch("completeAction", "uploadJourney")
    }
  }
}
