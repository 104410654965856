export default {
  gettingKPIList(state) {
    return state.currentWork.indexOf("getKPIs") > -1
  },

  savingKPI(state) {
    return (
      state.currentWork.indexOf("addKPI") > -1 ||
      state.currentWork.indexOf("updateKPI") > -1
    )
  }
}
