<template>
  <li :class="color" v-if="getStat(options)" v-tooltip="getTooltip(options)">
    {{ getStat(options) }}
  </li>
</template>

<script>
import { getStat } from "../lib/helpers"

export default {
  name: "FlowChartLabel",

  props: ["color", "journeys", "options"],

  methods: {
    getStat(...props) {
      return getStat(this.journeys, ...props)
    },
    getTooltip(options) {
      return this.getStat(options, true)
        .filter(({ count }) => count > 0)
        .map(({ name, count }) => `${name}: ${count}`)
        .join("<br>")
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;
  background-color: white;
  border-radius: 100px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
  margin: 0 0 0.5rem -0.5rem;
  padding: 0.5rem 1rem;
  z-index: 5;

  @media (max-width: $medium-width) {
    white-space: unset;
    font-size: 12px;
  }

  &.grey {
    background-color: $grey-color;
    color: white;
  }
  &.pink {
    background-color: $pink-color;
    color: white;
  }
  &.yellow {
    background-color: $yellow-color;
  }
  &.blue {
    background-color: $blue-color;
    color: white;
  }
  &.green {
    background-color: $green-color;
    color: white;
  }
}
</style>
