import Landing from "./Landing.vue"
import Error404 from "./Error404.vue"
import Login from "./auth/Login.vue"
import PasswordForgot from "./auth/PasswordForgot.vue"
import PasswordReset from "./auth/PasswordReset.vue"
import UsersTable from "./admin/Users.vue"
import AdminIndex from "./admin/Index.vue"
import AdminOrgs from "./admin/Organisations.vue"
import AdminUsecases from "./admin/Usecases.vue"
import AdminProjects from "./admin/Projects.vue"
import AdminCities from "./admin/Cities.vue"
import AdminDataIndex from "./data/Index.vue"
import AdminDataImport from "./data/Import.vue"
import AdminDataExport from "./data/Export.vue"
import Page from "./Page.vue"
import CityPage from "./CityPage.vue"
import UsecaseForm from "./admin/usecases/UsecaseForm.vue"
import UsecaseTable from "./admin/usecases/Index.vue"
import ProjectForm from "./admin/projects/ProjectForm.vue"
import ProjectTable from "./admin/projects/Index.vue"
import AdminKPIs from "./admin/KPIs.vue"
import AdminPages from "./admin/Pages.vue"
import AdminPagesEdit from "./admin/PagesEdit.vue"
import UsecasePage from "./UsecasePage.vue"
import AdminDataUpload from "./data/Upload.vue"
import AdminVehicles from "./admin/Vehicles.vue"
import AdminSegments from "./admin/Segments.vue"

import store from "../store"

export default [
  { path: "/", component: Landing, name: "landing" },

  {
    path: "/admin",
    component: AdminIndex,

    meta: {
      linkedInBreadscrumbs: false,
      mustHaveScope: ["admin"]
    },

    children: [
      // This is an alias that takes care of people trying to go to /admin and sends them to users
      { path: "", name: "Admin", redirect: "/admin/users" },

      {
        path: "users",
        component: UsersTable,
        name: "Gebruikers",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een gebruiker toe" },
          { path: "update/:slug", name: "Update gebruiker" }
        ]
      },
      {
        path: "organisations",
        component: AdminOrgs,
        name: "Organisatie",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een organisatie toe" },
          { path: "update/:slug", name: "Update organisatie" }
        ]
      },
      {
        path: "projects",
        component: AdminProjects,
        name: "Projects",
        meta: { showAddButton: true },
        children: [
          {
            path: "/",
            name: "Projects",
            component: ProjectTable,
            meta: { hideInBreadcrumbs: true }
          },
          {
            path: "create",
            name: "Voeg een project toe",
            component: ProjectForm
          },
          {
            path: "update/:slug",
            name: "Update project",
            component: ProjectForm
          }
        ]
      },
      {
        path: "usecases",
        component: AdminUsecases,
        name: "Usecases",
        meta: { showAddButton: true },
        children: [
          {
            path: "/",
            name: "Usecases",
            component: UsecaseTable,
            meta: { hideInBreadcrumbs: true }
          },
          {
            path: "create",
            name: "Voeg een use case toe",
            component: UsecaseForm
          },
          {
            path: "update/:slug",
            name: "Update use case",
            component: UsecaseForm
          }
        ]
      },
      {
        path: "/admin/cities",
        component: AdminCities,
        name: "Steden",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een stad toe" },
          { path: "update/:slug", name: "Update stad" }
        ]
      },
      {
        path: "/admin/vehicles",
        component: AdminVehicles,
        name: "Vehicles",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een vervoersmiddel toe" },
          { path: "update/:slug", name: "Update vervoersmiddel" }
        ]
      },
      {
        path: "/admin/kpis",
        component: AdminKPIs,
        name: "KPIs",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een KPI toe" },
          { path: "update/:id", name: "Update KPI" }
        ]
      },
      {
        path: "/admin/segments",
        component: AdminSegments,
        name: "Logistieke segmenten",
        meta: { showAddButton: true },
        children: [
          { path: "create", name: "Voeg een logistiek segment toe" },
          { path: "update/:id", name: "Update logistiek segment" }
        ]
      },
      {
        path: "/admin/pages",
        component: AdminPages,
        name: `Pagina's`,
        meta: { showAddButton: true },
        children: [{ path: "create", name: "Voeg een pagina toe" }]
      },
      {
        // No child route because we don't want to show this in a modal
        path: "/admin/pages/update/:id",
        component: AdminPagesEdit,
        name: `Update pagina`,
        meta: { showAddButton: true }
      }
    ]
  },

  {
    path: "/data",
    name: "Data",
    component: AdminDataIndex,
    meta: { mustHaveScope: ["admin", "uploader"] },
    children: [
      {
        path: "",
        name: "Data",
        redirect: "/data/import"
      },
      {
        path: "import",
        component: AdminDataImport,
        name: "Importeren"
      },
      {
        path: "export",
        component: AdminDataExport,
        name: "Exporteren"
      },
      {
        path: "import/:slug",
        name: "Upload Data",
        component: AdminDataUpload
      }
    ]
  },

  { path: "/page/:slug", component: Page, name: "Pagina" },
  { path: "/city/:slug", component: CityPage, name: "Stad" },
  {
    path: "/city/:citySlug/:usecaseSlug",
    component: UsecasePage,
    name: "Usecase"
  },

  { path: "/login", component: Login, name: "Inloggen" },
  {
    path: "/password/forgot",
    component: PasswordForgot,
    name: "Wachtwoord Vergeten"
  },
  {
    path: "/password/reset",
    component: PasswordReset,
    name: "Herstel Wachtwoord"
  },

  { path: "*", component: Error404, name: "Pagina niet gevonden" }
]
