export default {
  userProject({ projects, user }) {
    return user && projects.length > 0
      ? projects.find(({ project_id }) => project_id === user.project)
      : null
  },

  gettingProjectList(state) {
    return state.currentWork.indexOf("getProjects") > -1
  },

  savingProject(state) {
    return (
      state.currentWork.indexOf("addProject") > -1 ||
      state.currentWork.indexOf("updateProject") > -1
    )
  }
}
