/*
  Getters are used to grab data from the store, but serialising it into whatever shape
  you want in a repeatable way.

  This allows you to de-couple state shape from the state data you use. For example, the
  state might contain a filename of an avatar image, like "avatar.jpg". But you might want
  to retrieve its full path without re-writing such logic, so you'd make a getter that appends
  the path to it.
*/

import api from "./api"
import auth from "./auth"
import base from "./base"
import cities from "./cities"
import journeys from "./journeys"
import kpis from "./kpis"
import measurements from "./measurements"
import orgs from "./orgs"
import projects from "./projects"
import usecases from "./usecases"
import users from "./users"
import vehicles from "./vehicles"
import navigation from "./navigation"
import segments from "./segments"

export default {
  ...api,
  ...auth,
  ...base,
  ...cities,
  ...journeys,
  ...kpis,
  ...measurements,
  ...orgs,
  ...projects,
  ...usecases,
  ...users,
  ...vehicles,
  ...navigation,
  ...segments
}
