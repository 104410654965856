<template>
  <div>
    <p class="loading-message" v-if="gettingUsecaseList">
      Use case aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th></th>
          <th>Naam</th>
          <th>Stad</th>
          <th>Project</th>
          <th>Duur</th>
          <th>Organisaties</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="usecase in usecases">
          <td>
            <SafeImage :src="usecase.photo" width="40px" height="40px" />
          </td>
          <td>{{ usecase.name }}</td>
          <td>
            <span v-show="!gettingCityList && usecaseCity(usecase)">{{
              usecaseCity(usecase).name
            }}</span>
          </td>
          <td>
            <span v-show="!gettingProjectList && usecaseProject(usecase)">{{
              usecaseProject(usecase).name
            }}</span>
          </td>
          <td>
            {{ formatDateNicely(usecase.start_date) }} –
            {{ formatDateNicely(usecase.end_date) }}
          </td>
          <td>
            <span v-for="org in usecaseOrgs(usecase)"
              >{{ org.name }}&nbsp;</span
            >
          </td>
          <td>{{ usecase.email }}</td>
          <td class="ta-r actions">
            <router-link
              v-if="userIsAdmin"
              :to="{ path: `/admin/usecases/update/${usecase.slug}` }"
              class="show-on-hover"
            >
              Wijzig
            </router-link>
            <a v-if="userIsAdmin" v-on:click="deleteUsecase(usecase)">
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import SafeImage from "../../../components/SafeImage.vue"
import { formatDateNicely } from "../../../lib/helpers.js"

export default Vue.extend({
  data() {
    return {}
  },

  components: { SafeImage },

  computed: {
    ...mapGetters([
      "gettingUsecaseList",
      "gettingOrgList",
      "gettingCityList",
      "gettingProjectList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin"
    ]),
    usecases() {
      return this.$store.state.usecases.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    orgs() {
      return this.$store.state.orgs
    },
    cities() {
      return this.$store.state.cities
    },
    projects() {
      return this.$store.state.projects
    }
  },

  methods: {
    formatDateNicely,

    usecaseOrgs({ organisations }) {
      if (!organisations) return
      return this.orgs.filter(
        ({ organisation_id }) => organisations.indexOf(organisation_id) > -1
      )
    },
    usecaseCity({ city } = {}) {
      if (!city) return {}
      return this.cities.find(({ city_id }) => city === city_id) || {}
    },
    usecaseProject({ project } = {}) {
      if (!project) return {}
      return (
        this.projects.find(({ project_id }) => project === project_id) || {}
      )
    },

    deleteUsecase({ usecase_id, name }) {
      if (this.$store.state.kpis.find(({ usecase }) => usecase === usecase_id))
        return alert(
          `Er zijn KPIs in ${name} – link deze aan een ander use case voordat je ze verwijderd.`
        )

      if (
        !confirm(
          `Weet je zeker dat je ${name} wilt verwijderen? Dit verwijderd ook alle gerelateerde ritten!`
        )
      )
        return
      this.$store.dispatch("deleteUsecase", usecase_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getCities")
    this.$store.dispatch("getKPIs")
  }
})
</script>

<style lang="scss">
.v-select {
  position: relative;
  top: -2px;

  .vs__dropdown-toggle {
    border: 0;
    min-width: 200px;
    border-bottom: 1px solid black;
    border-radius: 0;
    padding-bottom: 0;

    & .vs__search {
      border-bottom: none !important;

      &::placeholder {
        color: black !important;
      }
    }
  }
}
</style>
