<template>
  <section :class="chartLoaded ? '' : 'loading-chart'">
    <ExplanationText
      :kpis="explanationKpis"
      :hasAmbitions="hasAmbitions"
      appendix="Hieronder vindt u de verdeling van emissies over typen ritten (en vaarten)."
    ></ExplanationText>

    <div class="split" v-if="!showSegments">
      <div>
        <ul class="legend horizontal-list">
          <li>Vaarten binnen de stad</li>
          <li>Ritten binnen de stad</li>
          <li>Vaarten buiten de stad</li>
          <li>Ritten buiten de stad</li>
        </ul>
      </div>
    </div>

    <div class="three-columns responsive">
      <div>
        <div class="data-header mt-2">
          <h3>CO<sub>2</sub></h3>
          <p>Koolstofdioxide</p>
        </div>

        <doughnut-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'doughnut'"
          :chartdata="co2DoughnutChartData"
          :height="300"
        >
        </doughnut-chart>

        <bar-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'bar'"
          unit="ton"
          :chartdata="co2DoughnutChartData"
          :height="300"
          labelStringY="ton"
        >
        </bar-chart>

        <p
          v-if="weekStart === weekEnd && chartLoaded && showStacks"
          class="mt-2 chart-placeholder"
        >
          De grafiek wordt alleen getoond bij meerdere weken
        </p>
        <line-chart
          class="chart"
          v-else-if="chartLoaded && showStacks"
          :chartdata="co2StackChartData"
          :height="300"
          :legend="false"
          unit="ton"
          :stacked="true"
        >
        </line-chart>

        <segments-chart
          class="chart"
          emission="co2"
          v-if="chartLoaded && showSegments"
          :chartdata="trafficData"
          :loading="loadingTraffic"
          :weekStart="weekStart"
          :weekEnd="weekEnd"
        >
        </segments-chart>

        <p class="mb-3px">Gerealiseerd</p>
        <h4>
          {{ formatNumber(unitConvert(actualTotals.co2, "ton"), { round: 1 }) }}
          ton
        </h4>

        <div class="two-columns no-gab" v-if="hasAmbitions && !hideKPIData">
          <div>
            <p class="mb-3px">Ambitie</p>
            <h4>
              {{
                formatNumber(unitConvert(goalTotals.co2, "ton"), { round: 1 })
              }}
              ton
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${(formatNumber(
                  unitConvert(actualTotals.co2, 'ton')
                ),
                { round: 1 })} ton) - Ambitie (${(formatNumber(
                  unitConvert(goalTotals.co2, 'ton')
                ),
                { round: 1 })} ton)`
              "
              :class="
                actualTotals.co2 - goalTotals.co2 < 0 ? 'negative' : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.co2 - goalTotals.co2, "ton"),
                  { round: 1 }
                )
              }}
            </h4>
          </div>
        </div>

        <div class="two-columns no-gab" v-if="!hideKPIData">
          <div>
            <p class="mb-3px">Referentie</p>
            <h4>
              {{
                formatNumber(unitConvert(referenceTotals.co2, "ton"), {
                  round: 1
                })
              }}
              ton
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${formatNumber(
                  unitConvert(actualTotals.co2, 'ton'),
                  { round: 1 }
                )} ton) - Referentie (${formatNumber(
                  unitConvert(referenceTotals.co2, 'ton'),
                  { round: 1 }
                )} ton)`
              "
              :class="
                actualTotals.co2 - referenceTotals.co2 < 0
                  ? 'negative'
                  : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.co2 - referenceTotals.co2, "ton"),
                  { round: 1 }
                )
              }}
            </h4>
          </div>
        </div>
      </div>

      <div>
        <div class="data-header mt-2">
          <h3>PM<sub>10</sub></h3>
          <p>Fijnstof</p>
        </div>

        <doughnut-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'doughnut'"
          :chartdata="pm10DoughnutChartData"
          :height="300"
        >
        </doughnut-chart>
        <bar-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'bar'"
          unit="kg"
          :chartdata="pm10DoughnutChartData"
          :height="300"
          labelStringY="kg"
        >
        </bar-chart>

        <p
          v-if="weekStart === weekEnd && chartLoaded && showStacks"
          class="mt-2 chart-placeholder"
        >
          De grafiek wordt alleen getoond bij meerdere weken
        </p>
        <line-chart
          class="chart"
          v-else-if="chartLoaded && showStacks"
          :chartdata="pm10StackChartData"
          :options="chartOptions"
          :height="300"
          :legend="false"
          unit="kg"
          :stacked="true"
        >
        </line-chart>

        <segments-chart
          class="chart"
          emission="pm10"
          v-if="chartLoaded && showSegments"
          :chartdata="trafficData"
          :loading="loadingTraffic"
          :weekStart="weekStart"
          :weekEnd="weekEnd"
        >
        </segments-chart>

        <p class="mb-3px">Gerealiseerd</p>
        <h4>{{ formatNumber(unitConvert(actualTotals.pm10, "kg")) }} kg</h4>

        <div class="two-columns no-gab" v-if="hasAmbitions && !hideKPIData">
          <div>
            <p class="mb-3px">Ambitie</p>
            <h4>{{ formatNumber(unitConvert(goalTotals.pm10, "kg")) }} kg</h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${formatNumber(
                  unitConvert(actualTotals.pm10, 'kg')
                )} kg) - Ambitie (${formatNumber(
                  unitConvert(goalTotals.pm10, 'kg')
                )} kg)`
              "
              :class="
                actualTotals.pm10 - goalTotals.pm10 < 0
                  ? 'negative'
                  : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.pm10 - goalTotals.pm10, "kg")
                )
              }}
            </h4>
          </div>
        </div>

        <div class="two-columns no-gab" v-if="!hideKPIData">
          <div>
            <p class="mb-3px">Referentie</p>
            <h4>
              {{ formatNumber(unitConvert(referenceTotals.pm10, "kg")) }} kg
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${formatNumber(
                  unitConvert(actualTotals.pm10, 'kg')
                )} kg) - Referentie (${formatNumber(
                  unitConvert(referenceTotals.pm10, 'kg')
                )} kg)`
              "
              :class="
                actualTotals.pm10 - referenceTotals.pm10 < 0
                  ? 'negative'
                  : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.pm10 - referenceTotals.pm10, "kg")
                )
              }}
            </h4>
          </div>
        </div>
      </div>

      <div>
        <div class="data-header mt-2">
          <h3>NO<sub>x</sub></h3>
          <p>Stikstofoxide</p>
        </div>

        <doughnut-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'doughnut'"
          :chartdata="noxDoughnutChartData"
          :height="300"
        >
        </doughnut-chart>
        <bar-chart
          class="chart"
          v-if="chartLoaded && showDoughnut && chartType === 'bar'"
          unit="kg"
          :chartdata="noxDoughnutChartData"
          :height="300"
          labelStringY="kg"
        >
        </bar-chart>

        <p
          v-if="weekStart === weekEnd && chartLoaded && showStacks"
          class="mt-2 chart-placeholder"
        >
          De grafiek wordt alleen getoond bij meerdere weken
        </p>
        <line-chart
          class="chart"
          v-else-if="chartLoaded && showStacks"
          :options="chartOptions"
          :chartdata="noxStackChartData"
          :height="300"
          :legend="false"
          unit="kg"
          :stacked="true"
        >
        </line-chart>

        <segments-chart
          class="chart"
          emission="nox"
          v-if="chartLoaded && showSegments"
          :chartdata="trafficData"
          :loading="loadingTraffic"
          :weekStart="weekStart"
          :weekEnd="weekEnd"
        >
        </segments-chart>

        <p class="mb-3px">Gerealiseerd</p>
        <h4>{{ formatNumber(unitConvert(actualTotals.nox, "kg")) }} kg</h4>

        <div class="two-columns no-gab" v-if="hasAmbitions && !hideKPIData">
          <div>
            <p class="mb-3px">Ambitie</p>
            <h4>{{ formatNumber(unitConvert(goalTotals.nox, "kg")) }} kg</h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${formatNumber(
                  unitConvert(actualTotals.nox, 'kg')
                )} kg) - Ambitie (${formatNumber(
                  unitConvert(goalTotals.nox, 'kg')
                )} kg)`
              "
              :class="
                actualTotals.nox - goalTotals.nox < 0 ? 'negative' : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.nox - goalTotals.nox, "kg")
                )
              }}
            </h4>
          </div>
        </div>

        <div class="two-columns no-gab" v-if="!hideKPIData">
          <div>
            <p class="mb-3px">Referentie</p>
            <h4>
              {{ formatNumber(unitConvert(referenceTotals.nox, "kg")) }} kg
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">&nbsp;</p>
            <h4
              v-tooltip="
                `Gerealiseerd (${formatNumber(
                  unitConvert(actualTotals.nox, 'kg')
                )} kg) - Referentie (${formatNumber(
                  unitConvert(referenceTotals.nox, 'kg')
                )} kg)`
              "
              :class="
                actualTotals.nox - referenceTotals.nox < 0
                  ? 'negative'
                  : 'positive'
              "
            >
              {{
                formatNumber(
                  unitConvert(actualTotals.nox - referenceTotals.nox, "kg")
                )
              }}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <p class="description ta-r pr-0 mt-3">
      <router-link class="muted" to="/page/tno-berekeningen"
        >Berekeningen</router-link
      >
    </p>
  </section>
</template>

<script>
import Vue from "vue"

import DoughnutChart from "./DoughnutChart.vue"
import BarChart from "./BarChart.vue"
import SegmentsChart from "./SegmentsChart.vue"
import ExplanationText from "./ExplanationText.vue"
import LineChart from "./LineChart.vue"
import {
  reduceToIndex,
  datasetCanStack,
  produceStackDataset
} from "../lib/helpers"
import {
  linearGrowthByPercent,
  startIndex,
  endIndex,
  endIndexTotals,
  cumulativeTotals
} from "../lib/calculators"
import { unitConvert, formatNumber } from "../lib/units"

export default Vue.extend({
  name: "ClimateGraphsStats",

  components: {
    DoughnutChart,
    BarChart,
    LineChart,
    ExplanationText,
    SegmentsChart
  },

  data() {
    return {
      chartOptions: {},
      backgroundColors: ["#538CBD", "#093F5F", "#cf2576", "#73A00E"],
      chartOrder: [
        "inner_city_sails",
        "inner_city_drives",
        "outer_city_sails",
        "outer_city_drives"
      ],
      chartLabels: [
        "Vaarten binnen stad",
        "Ritten binnen de stad",
        "Vaarten buiten stad",
        "Ritten buiten stad"
      ],
      trafficData: null,
      loadingTraffic: true
    }
  },

  props: [
    "activeVisual",
    "chartType",
    "city",
    "data",
    "kpis",
    "hasAmbitions",
    "loading",
    "mode",
    "usecase",
    "week",
    "weekEnd",
    "weekStart",
    "year",
    "preciseWeeks",
    "hideKPIData"
  ],

  created() {
    this.getTraffic()
  },

  watch: {
    weekStart() {
      this.getTraffic()
    },
    weekEnd() {
      this.getTraffic()
    },
    year() {
      this.getTraffic()
    },
    week() {
      this.getTraffic()
    },
    city() {
      this.getTraffic()
    },
    usecase() {
      this.getTraffic()
    },
    project() {
      this.getTraffic()
    }
  },

  computed: {
    statsType() {
      if (this.week && this.usecase) return "usecase"
      if (this.year && this.city) return "city"
      return null
    },

    explanationKpis() {
      if (!this.chartLoaded) return []

      return [
        {
          label: "CO<sub>2</sub>",
          actual: this.actualTotals.co2,
          reference: this.referenceTotals.co2,
          ambition: this.goalTotals.co2
        },
        {
          label: "PM<sub>10</sub>",
          actual: this.actualTotals.pm10,
          reference: this.referenceTotals.pm10,
          ambition: this.goalTotals.pm10
        },
        {
          label: "NO<sub>x</sub>",
          actual: this.actualTotals.nox,
          reference: this.referenceTotals.nox,
          ambition: this.goalTotals.nox
        }
      ]
    },

    goalTotals: endIndexTotals("ambition", ["co2", "pm10", "nox"]),
    referenceTotals: endIndexTotals("reference", ["co2", "pm10", "nox"]),
    actualTotals: cumulativeTotals(
      ["co2", "pm10", "nox"],
      [
        "inner_city_sails",
        "inner_city_drives",
        "outer_city_sails",
        "outer_city_drives"
      ]
    ),

    showDoughnut() {
      return (
        (this.mode === "city" && this.activeVisual === "donut") ||
        (this.mode === "usecase" && this.activeVisual === "donut")
      )
    },

    showStacks() {
      return (
        (this.mode === "city" && this.activeVisual === "time") ||
        (this.mode === "usecase" && this.activeVisual === "time")
      )
    },

    showSegments() {
      return (
        (this.mode === "city" && this.activeVisual === "segment") ||
        (this.mode === "usecase" && this.activeVisual === "segment")
      )
    },

    chartLoaded() {
      return (
        !!this.data &&
        !!this.kpis &&
        Object.keys(this.kpis).length > 0 &&
        !this.loading
      )
    },

    co2DoughnutChartData() {
      return {
        labels: this.chartLabels,
        datasets: this.doughnutChart("co2"),
        unit: "ton"
      }
    },

    noxDoughnutChartData() {
      return {
        labels: this.chartLabels,
        datasets: this.doughnutChart("nox"),
        unit: "kg"
      }
    },

    pm10DoughnutChartData() {
      return {
        labels: this.chartLabels,
        datasets: this.doughnutChart("pm10"),
        unit: "kg"
      }
    },

    stackedLabels() {
      if (!this.chartLoaded) return []
      return Array.from(Array(this.endIndex - this.startIndex + 1)).map(
        (_, index) => this.startIndex + index + 1
      )
    },

    co2StackChartData() {
      return {
        labels: this.stackedLabels,
        datasets: this.stackChart("co2", "ton")
      }
    },

    noxStackChartData() {
      return {
        labels: this.stackedLabels,
        datasets: this.stackChart("nox", "kg")
      }
    },

    pm10StackChartData() {
      return {
        labels: this.stackedLabels,
        datasets: this.stackChart("pm10", "kg")
      }
    },

    startIndex,
    endIndex
  },

  methods: {
    unitConvert,
    formatNumber,
    produceStackDataset,

    async getTraffic() {
      if (
        !this.statsType ||
        (!this.usecase && !this.city) ||
        (this.city && !this.year)
      )
        return

      this.loadingTraffic = true
      switch (this.statsType) {
        case "usecase": {
          const data = await this.$store.dispatch(
            "getUsecaseTraffic",
            this.usecase
          )
          if (data && data.length) this.trafficData = data
          break
        }
        case "city": {
          const data = await this.$store.dispatch("getCityTraffic", {
            city_id: this.city.city_id,
            start_date: `${this.year}-01-01`,
            end_date: `${this.year}-12-31`
          })
          if (data && data.length) this.trafficData = data
        }
      }
      this.loadingTraffic = false
    },

    doughnutChart(gas) {
      if (!this.chartLoaded) return []

      const gasData = Object.keys(this.data).reduce((dataset, key) => {
        if (key.indexOf(gas) > -1)
          dataset[key.replace(`${gas}_`, "")] = this.data[key]
        return dataset
      }, {})

      if (!gasData || Object.keys(gasData).length < 1) return []

      const dataset = {
        backgroundColor: this.backgroundColors,
        data: this.chartOrder.map(measure =>
          reduceToIndex(this.endIndex, [gasData[measure]], this.startIndex)
        )
      }

      return [dataset]
    },

    stackChart(gas, unit) {
      if (!this.chartLoaded) return []

      const gasData = Object.keys(this.data).reduce((dataset, key) => {
        if (key.indexOf(gas) > -1)
          dataset[key.replace(`${gas}_`, "")] = this.data[key]
        return dataset
      }, {})

      if (!gasData || Object.keys(gasData).length < 1) return []

      const slices = this.endIndex - this.startIndex + 1
      const round = unit === "ton" ? 1 : 2

      const datasets = [
        {
          label: "Referentie",
          borderColor: "red",
          borderWidth: 3,
          data: linearGrowthByPercent({
            slices,
            total: this.referenceTotals[gas]
          }).map(({ y }) => ({
            y,
            actual: `${formatNumber(unitConvert(y, unit), {
              round
            })} ${unit} cumulatief`
          })),
          borderDash: [3, 5],
          yAxisID: "kpiline",
          fill: false
        }
      ]

      if (this.hasAmbitions) {
        datasets.unshift({
          label: "Ambitie",
          borderColor: "#000000",
          borderWidth: 3,
          data: linearGrowthByPercent({
            slices,
            total: this.goalTotals[gas]
          }).map(({ y }) => ({
            y,
            actual: `${formatNumber(unitConvert(y, unit), {
              round
            })} ${unit} cumulatief`
          })),
          borderDash: [3, 5],
          yAxisID: "kpiline",
          fill: false
        })
      }

      if (datasetCanStack(gasData.inner_city_sails))
        datasets.push({
          backgroundColor: this.backgroundColors[0],
          label: "Vaarten binnen stad",
          data: this.produceStackDataset(
            gasData.inner_city_sails,
            unit,
            this.startIndex,
            this.endIndex
          )
        })

      if (datasetCanStack(gasData.inner_city_drives))
        datasets.push({
          backgroundColor: this.backgroundColors[1],
          label: "Ritten binnen de stad",
          data: this.produceStackDataset(
            gasData.inner_city_drives,
            unit,
            this.startIndex,
            this.endIndex
          )
        })

      if (datasetCanStack(gasData.outer_city_sails))
        datasets.push({
          backgroundColor: this.backgroundColors[2],
          label: "Vaarten buiten stad",
          data: this.produceStackDataset(
            gasData.outer_city_sails,
            unit,
            this.startIndex,
            this.endIndex
          )
        })

      if (datasetCanStack(gasData.outer_city_drives))
        datasets.push({
          backgroundColor: this.backgroundColors[3],
          label: "Ritten buiten stad",
          data: this.produceStackDataset(
            gasData.outer_city_drives,
            unit,
            this.startIndex,
            this.endIndex
          )
        })

      return datasets
    }
  }
})
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.chart-placeholder {
  margin-top: 3rem !important;
  margin-bottom: 3rem;
  height: 300px;
}
</style>
