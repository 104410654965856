<template>
  <transition name="modal">
    <div class="modal-mask" @click="close">
      <div class="modal-wrapper" @click.stop>
        <div class="modal-container">
          <form @submit.prevent="submit">
            <div class="modal-header">
              <slot name="header">Wijzig</slot>
            </div>

            <div class="modal-body">
              <p v-if="error" class="error">{{ error }}</p>
              <slot name="body">Hier zou een formulier moeten staan.</slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button
                  class="button"
                  @click="submit"
                  :disabled="buttonDisabled"
                >
                  Opslaan
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue"
import { mapState } from "vuex"

export default Vue.extend({
  name: "Modal",

  props: ["buttonDisabled"],

  computed: {
    ...mapState(["error"])
  },

  beforeCreate() {
    this.$store.commit("resetError")
  },

  methods: {
    close() {
      this.$store.commit("resetError")
      this.$emit("close")
    },
    submit() {
      this.$store.commit("resetError")
      this.$emit("submit")
    }
  }
})
</script>

<style lang="scss" scoped></style>
