<template>
  <ul :class="classes">
    <li v-if="first" class="label-hub">Leverancier</li>
    <flow-chart-label
      :journeys="journeys"
      color="green"
      :options="{
        type: 'bike',
        trajectory,
        trajectories
      }"
    ></flow-chart-label>

    <flow-chart-label
      :journeys="journeys"
      color="blue"
      :options="{
        type: 'sail',
        electric: false,
        trajectory,
        trajectories
      }"
    ></flow-chart-label>

    <flow-chart-label
      :journeys="journeys"
      color="yellow"
      :options="{
        type: 'ride',
        electric: true,
        trajectory,
        trajectories
      }"
    ></flow-chart-label>

    <flow-chart-label
      :journeys="journeys"
      color="pink"
      :options="{
        type: 'sail',
        electric: true,
        trajectory,
        trajectories
      }"
    ></flow-chart-label>

    <flow-chart-label
      :journeys="journeys"
      color="grey"
      :options="{
        type: 'ride',
        electric: false,
        trajectory,
        trajectories
      }"
    ></flow-chart-label>
  </ul>
</template>

<script>
import { getStat } from "../lib/helpers"
import FlowChartLabel from "./FlowChartLabel.vue"

export default {
  name: "FlowChartLabels",

  components: {
    FlowChartLabel
  },

  props: ["trajectory", "trajectories", "journeys", "first", "right"],

  computed: {
    classes() {
      const classes = []
      if (this.first) classes.push("first")
      if (this.right) classes.push("right")
      return classes.join(" ")
    }
  },

  methods: {
    getStat(...props) {
      return getStat(this.journeys, ...props)
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  font-size: 15px;
  line-height: 15px;
  font-family: Helvetica, Arial, sans-serif;
  white-space: nowrap;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: auto;
  list-style-type: none;
  padding: 2rem 0 0 0;

  &.first {
    padding-top: 0;
    margin-top: -1rem;
  }

  &.right {
    align-items: flex-end;
    li {
      margin-right: -0.5rem;
    }
  }

  li {
    position: relative;
    background-color: white;
    border-radius: 100px;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
    margin: 0 0 0.5rem -0.5rem;
    padding: 0.5rem 1rem;
    z-index: 5;

    @media (max-width: $medium-width) {
      white-space: unset;
      font-size: 12px;

      &.label-hub {
        min-width: 120px;
        text-align: center;
      }
    }

    &.grey {
      background-color: $grey-color;
      color: white;
    }
    &.pink {
      background-color: $pink-color;
      color: white;
    }
    &.yellow {
      background-color: $yellow-color;
    }
    &.blue {
      background-color: $blue-color;
      color: white;
    }
    &.green {
      background-color: $green-color;
      color: white;
    }
  }
}
</style>
