import ajax from "../lib/ajax"

export default {
  async getVehicles({ commit, dispatch, state }) {
    const isRefresh = state.vehicles.length > 0

    if (!isRefresh) dispatch("currentAction", "getVehicles")

    try {
      const vehicles = await ajax("vehicles", { authToken: state.authToken })
      if (!vehicles.error) commit("getVehicles", vehicles)
      else throw new Error(vehicles.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getVehicles")
    }
  },

  async addVehicle({ commit, dispatch, state }, payload = {}) {
    dispatch("currentAction", "addVehicle")

    try {
      const vehicle = await ajax(`vehicles`, {
        authToken: state.authToken,
        method: "POST",
        body: payload
      })
      commit("addVehicle", vehicle)
      return vehicle
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addVehicle")
    }
  },

  async updateVehicle({ commit, dispatch, state }, payload = {}) {
    if (!payload.vehicle_id) return

    dispatch("currentAction", "updateVehicle")

    try {
      const vehicle = await ajax(`vehicles/${payload.vehicle_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: payload
      })
      commit("updateVehicle", vehicle)
      return vehicle
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateVehicle")
    }
  },

  async deleteVehicle({ commit, dispatch, state }, vehicleId) {
    dispatch("currentAction", "deleteVehicle")

    try {
      await ajax(`vehicles/${vehicleId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteVehicle", vehicleId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteVehicle")
    }
  },

  async getDataset({ dispatch, state, commit }, { vehicleId, year }) {
    if (!vehicleId || !year) return

    dispatch("currentAction", "getDataset")

    try {
      const startOfYear = `${year}-01-01`
      const endOfYear = `${year + 1}-01-01`

      const endpoint = `vehicles/${vehicleId}/data?start_date=${startOfYear}&end_date=${endOfYear}`
      return await ajax(endpoint, { authToken: state.authToken })
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "getDataset")
    }
  }
}
