import Vue from "vue"
import VuexPersistence from "vuex-persist"
import Vuex, { Store } from "vuex"

import defaultState from "./default-state"
import mutations from "../mutations"
import actions from "../actions"
import getters from "../getters"

Vue.use(Vuex)

// These state props will not survive a browser refresh
const ephemeralKeys = [
  "error",
  "currentWork",
  "session",
  "users",
  "cities",
  "usecases",
  "organisations",
  "kpis",
  "measurements",
  "segments",
  "projects"
]

const vuexLocal = new VuexPersistence({
  key: "tno:bouw:store",
  storage: window.localStorage,
  reducer: state =>
    Object.keys(state).reduce((persistedState, prop) => {
      if (ephemeralKeys.indexOf(prop) === -1) persistedState[prop] = state[prop]
      return persistedState
    }, {})
})

export default new Store({
  plugins: [vuexLocal.plugin],
  state: defaultState,
  actions,
  mutations,
  getters
})
