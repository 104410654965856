<template>
  <section>
    <h1 class="mb-2">TNO Monitoringstool<br />stadslogistiek</h1>
    <ErrorBox></ErrorBox>

    <p
      v-if="gettingCityList || gettingProjectList || gettingUsecaseList"
      class="loading-message"
    >
      Steden aan het laden...
    </p>

    <ul v-else>
      <li v-for="city in cities" :key="city.id">
        <router-link :to="{ name: 'Stad', params: { slug: city.slug } }">
          <SafeImage
            :src="city.image"
            width="100%"
            style="padding-bottom: 50%;"
          ></SafeImage>
        </router-link>

        <router-link
          :to="{ name: 'Stad', params: { slug: city.slug } }"
          style="text-decoration: none;"
        >
          <h2 class="mb-1 mt-1">{{ city.name }}</h2>
        </router-link>

        <div class="city-actions">
          <router-link
            class="button p-1"
            :to="{ name: 'Stad', params: { slug: city.slug } }"
          >
            Bekijk details
          </router-link>

          <p>Aantal use cases: {{ city.usecase_count }}</p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import SafeImage from "../components/SafeImage.vue"
import ErrorBox from "../components/ErrorBox.vue"

export default Vue.extend({
  data() {
    return {}
  },

  components: { SafeImage, ErrorBox },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "gettingUsecaseList",
      "gettingProjectList",
      "gettingCityList",
      "userIsAdmin",
      "currentUser"
    ]),

    projects() {
      if (this.userIsAdmin) return this.$store.state.projects

      return this.$store.state.projects.filter(
        ({ cityview_allowed_for = [] } = {}) =>
          cityview_allowed_for &&
          cityview_allowed_for.indexOf(this.currentUser.organisation) > -1
      )
    },

    usecases() {
      if (this.userIsAdmin) return this.$store.state.usecases

      const projectIds = this.projects.map(({ project_id }) => project_id)

      return this.$store.state.usecases.filter(
        ({ project, organisations }) =>
          projectIds.indexOf(project) > -1 ||
          organisations.indexOf(this.currentUser.organisation) > -1
      )
    },

    cities() {
      const cities = this.$store.state.cities
        .map(city => ({
          ...city,
          usecase_count: this.usecases.filter(
            ({ city: usecaseCity }) => usecaseCity === city.city_id
          ).length
        }))
        .filter(({ usecase_count }) => usecase_count > 0)

      if (this.userIsAdmin) return cities

      const allowedCityIds = this.usecases.reduce((list, { city }) => {
        if (list.indexOf(city) === -1) list.push(city)
        return list
      }, [])

      return cities.filter(
        ({ city_id }) => allowedCityIds.indexOf(city_id) > -1
      )
    }
  },

  beforeCreate() {
    if (!this.$store.getters.isAuthenticated) this.$router.push("login")

    const promises = [
      this.$store.dispatch("getProjects"),
      this.$store.dispatch("getUsecases"),
      this.$store.dispatch("getCities")
    ]
    Promise.all(promises).then(() => {
      if (this.cities.length === 1)
        this.$router.push(`/city/${this.cities[0].slug}`)
    })
  }
})
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -2rem;

  li {
    margin-top: 2rem;
    width: calc(100% / 3);
    padding: 0.5em;

    h2 {
      margin-top: 0.5em;
    }

    .city-actions {
      width: 100%;
      display: flex;
      flex-direction: row;

      .button {
        display: inline-block;
      }

      p {
        font-weight: bold;
        padding-left: 15px;
      }
    }
  }

  @media (max-width: $medium-width) {
    display: block;

    li {
      width: 100%;
    }
  }
}
</style>
