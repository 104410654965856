export const JOURNEYS_PLACEHOLDERS = [
  {
    type: "sail",
    trajectory: "supplier-regiohub",
    electric: false
  },
  {
    type: "sail",
    trajectory: "supplier-regiohub",
    electric: true
  },
  {
    type: "ride",
    trajectory: "supplier-regiohub",
    electric: false
  },
  {
    type: "ride",
    trajectory: "supplier-regiohub",
    electric: true
  },
  {
    type: "bike",
    trajectory: "supplier-regiohub",
    human: true
  },
  {
    type: "sail",
    trajectory: "supplier-cityhub",
    electric: false
  },
  {
    type: "sail",
    trajectory: "supplier-cityhub",
    electric: true
  },
  {
    type: "ride",
    trajectory: "supplier-cityhub",
    electric: false
  },
  {
    type: "ride",
    trajectory: "supplier-cityhub",
    electric: true
  },
  {
    type: "bike",
    trajectory: "supplier-cityhub",
    human: true
  },
  {
    type: "sail",
    trajectory: "supplier-destination",
    electric: false
  },
  {
    type: "sail",
    trajectory: "supplier-destination",
    electric: true
  },
  {
    type: "ride",
    trajectory: "supplier-destination",
    electric: false
  },
  {
    type: "ride",
    trajectory: "supplier-destination",
    electric: true
  },
  {
    type: "bike",
    trajectory: "supplier-destination",
    human: true
  },
  {
    type: "sail",
    trajectory: "regiohub-destination",
    electric: false
  },
  {
    type: "sail",
    trajectory: "regiohub-destination",
    electric: true
  },
  {
    type: "ride",
    trajectory: "regiohub-destination",
    electric: false
  },
  {
    type: "ride",
    trajectory: "regiohub-destination",
    electric: true
  },
  {
    type: "bike",
    trajectory: "regiohub-destination",
    human: true
  },
  {
    type: "sail",
    trajectory: "cityhub-destination",
    electric: false
  },
  {
    type: "sail",
    trajectory: "cityhub-destination",
    electric: true
  },
  {
    type: "ride",
    trajectory: "cityhub-destination",
    electric: false
  },
  {
    type: "ride",
    trajectory: "cityhub-destination",
    electric: true
  },
  {
    type: "bike",
    trajectory: "cityhub-destination",
    human: true
  }
]
