export default {
  isAuthenticated(state) {
    return !!state.authToken
  },

  currentUser(state) {
    return state.user
  },

  loggingIn(state) {
    return state.currentWork.indexOf("authenticating") > -1
  },

  passwordForgotRequest(state) {
    return state.currentWork.indexOf("sendPasswordReset") > -1
  },

  resetPasswordRequest(state) {
    return state.currentWork.indexOf("resetPasswordViaToken") > -1
  }
}
