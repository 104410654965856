<template>
  <svg
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-width="12"
      :transform="position === 'right' ? 'rotate(180, 50, 50)' : ''"
    >
      <!-- Corner lines when postion is left -->
      <g v-if="position === 'left'">
        <path
          v-if="
            showLine('horizontal', {
              type: 'ride',
              electric: false
            })
          "
          d="m10 0v40c0 28 22 50 50 50h40"
          :stroke="greyColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'sail',
              electric: true
            })
          "
          d="m30 0v30c0 22 18 40 40 40h30"
          :stroke="pinkColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'ride',
              electric: true
            })
          "
          d="m50 0v20c0 17 13 30 30 30h20"
          :stroke="yellowColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'sail',
              electric: false
            })
          "
          d="m70 0v10c0 11 9 20 20 20h10"
          :stroke="blueColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'bike'
            })
          "
          d="m90 0c0 6 4 10 10 10"
          :stroke="greenColor"
        />
      </g>

      <!-- Straight lines when postion is left -->
      <g v-if="straight && position === 'left'">
        <path
          v-if="
            showLine('vertical', {
              type: 'ride',
              electric: false
            })
          "
          d="m10-5v105"
          :stroke="greyColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'sail',
              electric: true
            })
          "
          d="m30-5v105"
          :stroke="pinkColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'ride',
              electric: true
            })
          "
          d="m50-5v105"
          :stroke="yellowColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'sail',
              electric: false
            })
          "
          d="m70-5v105"
          :stroke="blueColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'bike'
            })
          "
          d="m90-5v105"
          :stroke="greenColor"
        />
      </g>

      <!-- Corner lines when postion is right -->
      <g v-if="position === 'right'">
        <path
          v-if="
            showLine('horizontal', {
              type: 'bike'
            })
          "
          d="m10 0v40c0 28 22 50 50 50h40"
          :stroke="greenColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'sail',
              electric: false
            })
          "
          d="m30 0v30c0 22 18 40 40 40h30"
          :stroke="blueColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'ride',
              electric: true
            })
          "
          d="m50 0v20c0 17 13 30 30 30h20"
          :stroke="yellowColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'sail',
              electric: true
            })
          "
          d="m70 0v10c0 11 9 20 20 20h10"
          :stroke="pinkColor"
        />
        <path
          v-if="
            showLine('horizontal', {
              type: 'ride',
              electric: false
            })
          "
          d="m90 0c0 6 4 10 10 10"
          :stroke="greyColor"
        />
      </g>

      <!-- Straight lines when postion is right -->
      <g v-if="straight && position === 'right'">
        <path
          v-if="
            showLine('vertical', {
              type: 'bike'
            })
          "
          d="m10-5v105"
          :stroke="greenColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'sail',
              electric: false
            })
          "
          d="m30-5v105"
          :stroke="blueColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'ride',
              electric: true
            })
          "
          d="m50-5v105"
          :stroke="yellowColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'sail',
              electric: true
            })
          "
          d="m70-5v105"
          :stroke="pinkColor"
        />
        <path
          v-if="
            showLine('vertical', {
              type: 'ride',
              electric: false
            })
          "
          d="m90-5v105"
          :stroke="greyColor"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { showLine } from "../lib/helpers"

export default {
  name: "FlowChartCorner",

  props: {
    position: {
      default: "left",
      type: String
    },
    journeys: {
      default() {
        return []
      },
      type: Array
    },
    vertical: {
      default() {
        return []
      },
      type: Array
    },
    horizontal: {
      default() {
        return []
      },
      type: Array
    },
    straight: {
      default: true,
      type: Boolean
    },
    grey: {
      default: "none",
      type: String
    },
    pink: {
      default: "none",
      type: String
    },
    yellow: {
      default: "none",
      type: String
    },
    blue: {
      default: "none",
      type: String
    },
    green: {
      default: "none",
      type: String
    }
  },

  data() {
    return {
      greyColor: "#69696e",
      pinkColor: "#c20764",
      yellowColor: "#f7c209",
      blueColor: "#538cbd",
      greenColor: "#6fbe49"
    }
  },

  methods: {
    show(color, direction) {
      if (color === "none") return false
      if (!this.straight) return true
      return [direction, "all"].indexOf(color) > -1
    },

    showLine(...props) {
      const journey = props.pop()
      const direction = typeof props[0] === "string" ? props[0] : null
      return showLine({
        direction,
        journeys: this.journeys,
        vertical: this.vertical,
        horizontal: this.horizontal,
        journey
      })
    }
  }
}
</script>
