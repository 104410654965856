<template>
  <div class="two-columns">
    <div>
      <h3>
        Upload data <span v-if="usecase">to {{ usecase.name }}</span>
      </h3>
      <div
        :class="
          gettingUsecaseList || uploadingJourney ? 'mt-2 loading-form' : 'mt-2'
        "
      >
        <div class="field">
          <label>Scheidingsteken</label>
          <select v-model="delimiter">
            <option v-for="delimiter in delimiters" :value="delimiter.value">{{
              delimiter.label
            }}</option>
          </select>
        </div>
        <div class="field">
          <label>Upload een CSV-bestand</label>
          <input type="file" v-on:change="uploadFile" />
          <p class="error-message" v-if="$v.$dirty && $v.file.$error">
            Upload een geldig CSV-bestand
          </p>
        </div>

        <p class="error mb-0" v-show="error">{{ error }}</p>

        <button
          @click="verify"
          :disabled="gettingUsecaseList || uploadingJourney"
        >
          1. Controleer bestand
        </button>
      </div>
    </div>
    <div :class="resultClassnames" v-show="file">
      <h3 v-if="uploadHasErrors">{{ this.uploadErrors.length }} error(s)...</h3>
      <h3 v-else>Geweldig! We hebben data in dit bestand gevonden</h3>

      <ul v-if="uploadHasErrors">
        <li v-for="error of uploadErrors">
          <p>{{ error.message }}</p>
        </li>
      </ul>
      <div v-else>
        <p>
          <strong>{{ uploadLength }}</strong> ritten en of vaarten zijn
          gevonden<span v-if="file">
            in <strong>{{ file.name }}</strong></span
          >. Deze ritten en vaarten bevatten
          <strong>{{ totalInnerCity }} km</strong> binnen de stad en
          <strong>{{ totalOuterCity }} km</strong> buiten de stad.
        </p>

        <p>
          <button
            @click="save"
            :disabled="gettingUsecaseList || uploadingJourney"
          >
            2. Overschrijf data
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required } from "vuelidate/lib/validators"

import { isCSVu } from "../../lib/validators"
import { API_URI, CSVU_DELIMITERS } from "../../config/env"

export default Vue.extend({
  data() {
    return {
      file: null,
      delimiter: CSVU_DELIMITERS[0].value,
      delimiters: CSVU_DELIMITERS,
      uploadErrors: [],
      error: null,
      saveUploadedFile: false,
      uploaded: false,
      uploadLength: 0,
      totalInnerCity: 0,
      totalOuterCity: 0
    }
  },

  validations: {
    file: { required, isCSVu }
  },

  computed: {
    ...mapGetters(["gettingUsecaseList", "uploadingJourney", "currentUser"]),

    uploadHasErrors() {
      return this.uploadErrors.length > 0
    },

    resultClassnames() {
      const baseClass = "results"
      if (!this.uploaded) return baseClass

      return this.uploadHasErrors
        ? `${baseClass} has-errors`
        : `${baseClass} no-errors`
    },

    usecases() {
      return this.$store.state.usecases.filter(
        ({ organisations }) =>
          organisations &&
          organisations.indexOf(this.currentUser.organisation) > -1
      )
    },

    slug() {
      return this.$route.params.slug
    },

    usecase() {
      if (!this.usecases) return
      return this.usecases.find(({ slug }) => slug === this.slug)
    },

    uploading() {
      return this.$route.params && this.slug
    }
  },

  methods: {
    // We want to make sure the file we upload is the same one we just verified
    checkFileParity(newFile) {
      if (!this.file) return false

      return (
        this.file.size === newFile.size &&
        this.file.lastModified === newFile.lastModified &&
        this.file.name === newFile.name
      )
    },

    verify() {
      this.saveUploadedFile = false
      return this.save()
    },

    async save() {
      this.error = null
      if (!this.usecase) {
        // A simple timeout to make it clean in the UI that it's still not found
        // when they click the button again
        return setTimeout(() => {
          this.error = `Use case '${this.slug}' niet gevonden in admin`
        }, 200)
      }

      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.uploadingJourney) return
      if (this.uploadErrors.length > 0 && this.saveUploadedFile)
        return alert(
          "Er zijn errors gevonden in dit CSV-bestand. Los die op voordat je opnieuw upload."
        )

      const payload = {
        file: this.file,
        usecase_id: this.usecase.usecase_id,
        delimiter: this.delimiter,
        save: this.saveUploadedFile
      }

      this.$store
        .dispatch("uploadJourney", payload)
        .then(({ ok, length, errors, totalInnerCity, totalOuterCity }) => {
          if (this.saveUploadedFile)
            return this.$router.push({
              path: "/data/import",
              query: { upload: true }
            })

          this.uploaded = true
          this.saveUploadedFile = ok && length > 0
          this.uploadLength = length
          this.totalInnerCity = totalInnerCity || 0
          this.totalOuterCity = totalOuterCity || 0
          this.uploadErrors = []

          if (ok && length < 1)
            this.uploadErrors.push({
              message:
                "We kunnen geen data vinden in het bestand wat je net geupload hebt. Heb je het juist scheidingsteken geselecteerd?"
            })
          else if (errors) this.uploadErrors = errors
        })
        .catch(error => this.uploadErrors.push(error))
    },

    uploadFile($event) {
      const [file] = $event.target.files

      if (!file) return
      if (this.checkFileParity(file)) return

      this.file = file
      this.saveUploadedFile = false
      this.uploaded = false
      this.uploadErrors = []
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases", { slug: this.$route.params.slug })
  }
})
</script>
<style lang="scss" scoped>
.results {
  opacity: 0;
  padding: 30px;

  &.no-errors,
  &.has-errors {
    opacity: 1;
  }

  &.no-errors {
    background: #f6fef9;
  }
  &.has-errors {
    background: #fff5f7;
  }

  ul {
    margin-left: 25px;
  }
}

button {
  position: relative;
  top: 20px;
}
</style>
