<script>
import Chart from "chart.js"
import { Line } from "vue-chartjs"
import { unitConvert, formatNumber } from "../lib/units"

Chart.defaults.global.defaultFontColor = "#8a8a8a"
Chart.defaults.global.defaultFontFamily = "Helvetica, Arial, sans-serif"
Chart.defaults.global.pointHitDetectionRadius = 1

export default {
  name: "LineChart",

  extends: Line,

  data() {
    const displayLegend = typeof this.legend === "boolean" ? this.legend : true
    const stacked = typeof this.stacked === "boolean" ? this.stacked : false
    const ticks = typeof this.ticks === "boolean" ? this.ticks : true
    const axisLabels = {
      x: this.showAxisLabels && this.showAxisLabels.includes('x'),
      y: this.showAxisLabels && this.showAxisLabels.includes('y')
    }

    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: displayLegend,
          labels: {
            usePointStyle: true,
            fontSize: 13,
            generateLabels: function(chart) {
              const datasets = chart.data.datasets
              const options = chart.options.legend || {}
              const usePointStyle =
                options.labels && options.labels.usePointStyle

              return chart._getSortedDatasetMetas().map(meta => {
                const style = meta.controller.getStyle(
                  usePointStyle ? 0 : undefined
                )
                return {
                  text: datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  hidden: meta.hidden,
                  lineCap: style.borderCapStyle,
                  lineDash: datasets[meta.index].borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 3,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,

                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index
                }
              }, this)
            }
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          backgroundColor: "rgba(57, 115, 166, 0.8)",
          labelColors: "red",
          callbacks: {
            labelColor: function(tooltipItem, data) {
              const dataset =
                tooltipItem &&
                data &&
                data.data &&
                data.data.datasets &&
                data.data.datasets[tooltipItem.datasetIndex] &&
                data.data.datasets[tooltipItem.datasetIndex]
                  ? data.data.datasets[tooltipItem.datasetIndex]
                  : null
              const color =
                dataset && (dataset.borderColor || dataset.backgroundColor)
                  ? dataset.borderColor || dataset.backgroundColor
                  : "#000000"
              return {
                borderColor: "#FFFFFF",
                backgroundColor: color
              }
            },
            afterTitle: function() {
              window.total = { unit: null, current: 0, runningTotal: 0 }
            },
            title: function([{ label }], data) {
              return label.includes("Week") ? label : `Week ${label}` // (accumulatief)
            },
            label: function(tooltipItem, data) {
              let { label } = data.datasets[tooltipItem.datasetIndex]
              const { index } = tooltipItem
              if (label) label += ": "
              if (
                data.datasets[tooltipItem.datasetIndex] &&
                data.datasets[tooltipItem.datasetIndex].data &&
                data.datasets[tooltipItem.datasetIndex].data[index] &&
                typeof data.datasets[tooltipItem.datasetIndex].data[index]
                  .actual !== "undefined"
              ) {
                const actual =
                  data.datasets[tooltipItem.datasetIndex].data[index].actual
                const total =
                  data.datasets[tooltipItem.datasetIndex].data[index].total
                label +=
                  typeof actual === "number" ? Math.round(actual) : actual
              } else {
                label += Math.round(tooltipItem.yLabel)
              }

              if (
                data.datasets[tooltipItem.datasetIndex] &&
                data.datasets[tooltipItem.datasetIndex].data &&
                data.datasets[tooltipItem.datasetIndex].data[index] &&
                typeof data.datasets[tooltipItem.datasetIndex].data[index]
                  .total !== "undefined"
              ) {
                const total =
                  data.datasets[tooltipItem.datasetIndex].data[index].total
                window.total.unit = total.unit
                window.total.current += total.current
                window.total.runningTotal += total.runningTotal
              }
              return label
            },
            footer: function() {
              const { unit, current, runningTotal } = window.total || {}
              const round = unit === "ton" ? 1 : 2

              if (unit || current || runningTotal) {
                return `Totaal ${formatNumber(
                  unitConvert(current, unit),
                  { round }
                )} ${unit} (${formatNumber(
                  unitConvert(runningTotal, unit),
                  { round }
                )} ${unit} cumulatief)`
              }
            }
          }
        },
        scales: {
          xAxes: [
            {
              display: axisLabels.x,
              gridLines: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0
              }
            }
          ],
          yAxes: [
            {
              display: axisLabels.y,
              stacked,
              gridLines: {
                display: true,
                drawBorder: false,
                drawOnChartArea: false
              },
              beginAtZero: true,
              ticks: {
                mirror: true,
                min: 0,
                max: this.ymax,
                maxTicksLimit: 1,
                padding: -5,
                callback: (value, index, values) => {
                  if (!this.ticks) return null
                  if (value === 0) return null
                  return this.unit
                    ? `${formatNumber(unitConvert(value, this.unit), { round: this.unit === "ton" ? 1 : 2 })} ${
                        this.unit
                      }`
                    : value
                }
              }
            },
            {
              id: "kpiline",
              display: false,
              stacked: false,
              type: "linear",
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              },
              beginAtZero: true,
              ticks: {
                min: 0,
                max: this.ymax
              }
            }
          ]
        },
        elements: {
          line: {
            fill: stacked,
            tension: 0,
            borderWidth: stacked ? 0 : 7
          },
          point: {
            radius: 0
          }
        }
      }
    }
  },

  props: ["chartdata", "options", "stacked", "legend", "ymax", "unit", "ticks", "showAxisLabels"],

  mounted() {
    this.render()
  },

  methods: {
    unitConvert,
    formatNumber,

    render() {
      this.renderChart(this.chartdata, {
        ...this.chartOptions,
        ...this.options
      })
    }
  },

  watch: {
    chartdata() {
      this.render()
    },
    options() {
      this.render()
    },
    stacked() {
      this.render()
    },
    legend() {
      this.render()
    },
    ymax() {
      this.render()
    },
    unit() {
      this.render()
    },
    ticks() {
      this.render()
    }
  }
}
</script>
