<template>
  <div>
    <p class="loading-message" v-if="gettingCityList">
      Steden aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th />
          <th>Naam</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(city, index) in cities" :key="index">
          <td>
            <SafeImage :src="city.image" width="40px" height="40px" />
          </td>
          <td>{{ city.name }}</td>
          <td class="ta-r actions">
            <a v-if="userIsAdmin">
              <router-link :to="{ path: `/admin/cities/update/${city.slug}` }">
                Wijzig
              </router-link>
            </a>
            <a v-if="userIsGod" v-on:click="deleteCity(city)">Verwijder</a>
            <a
              v-else-if="userIsAdmin"
              class="muted"
              v-tooltip="
                'Alleen Christian van Ommeren kan organisaties verwijderen'
              "
              >Verwijder</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingCity"
    >
      <h3 slot="header">Voeg een stad toe</h3>
      <div
        slot="body"
        :class="gettingCityList || savingCity ? 'loading-form' : ''"
      >
        <div class="field">
          <label>Stad naam</label>
          <input type="text" placeholder="Vul stad naam in..." v-model="name" />

          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam van de stad in
          </p>
        </div>
        <div class="field">
          <label>Coverfoto</label>
          <UploadFile
            label="Sleep of selecteer een coverfoto..."
            fileTypes="image/jpeg, image/png, image/gif"
            :file="image"
            aspectRatio="2:1"
            type="image"
            crop="1200x600"
            :onUpload="onUpdateImage"
            resource="cities"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"

import { properName } from "../../lib/validators"
import SafeImage from "../../components/SafeImage.vue"
import Modal from "../../components/Modal.vue"
import UploadFile from "../../components/UploadFile.vue"

export default Vue.extend({
  data() {
    return {
      name: null,
      image: null
    }
  },

  validations: {
    name: { required, minLength: minLength(2), properName }
  },

  components: { SafeImage, Modal, UploadFile },

  computed: {
    ...mapGetters([
      "gettingCityList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin",
      "savingCity"
    ]),
    cities() {
      return this.$store.state.cities.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },

    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.slug
    },

    city() {
      if (!this.cities) return
      return this.cities.find(({ slug }) => slug === this.$route.params.slug)
    }
  },

  methods: {
    async save() {
      if (this.savingCity) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.city ? "updateCity" : "addCity"
      const payload = { name: this.name, image: this.image }
      if (this.city) payload.city_id = this.city.city_id

      const error = await this.$store.dispatch(action, payload)
      if (!(error instanceof Error)) this.closeModal()
    },

    closeModal() {
      return this.$router.push("/admin/cities")
    },

    deleteCity({ city_id, name }) {
      if (this.$store.state.usecase.find(({ city }) => city === city_id))
        return alert(
          `Dit zijn de use case in ${name} – link deze aan een andere stad voordat je deze stad verwijderd.`
        )

      if (!confirm(`Weet je zeker dat je ${name} wilt verwijderen?`)) return

      this.$store.dispatch("deleteCity", city_id)
    },

    onUpdateImage(filePath) {
      this.image = filePath
    }
  },

  beforeCreate() {
    this.$store.dispatch("getCities", { queueWork: true }).then(() => {
      if (this.city) {
        this.name = this.city.name
        this.image = this.city.image
      }

      if (this.$v) this.$v.$reset()
    })

    this.$store.dispatch("getUsecases")
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null
      this.image = null

      if (this.$v) this.$v.$reset()

      return next()
    }

    const city = this.cities.find(({ slug }) => slug === to.params.slug)

    if (city) {
      this.name = city.name
      this.image = city.image

      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>

<style lang="scss" scoped></style>
