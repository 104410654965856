<template>
  <p class="description" v-html="explanationText"></p>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "ExplanationText",

  props: {
    kpis: Array,
    appendix: String,
    messageText: String,
    hasAmbitions: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    explanationText() {
      if (this.messageText) return this.messageText
      if (!this.kpis || this.kpis.length < 1)
        return `Er verschijnt hier een samenvatting van onderstaande cijfers als er KPI's voor deze use case in de admin gedefinieerd zijn.`

      const good = []
      const bad = []
      let message = ""
      let isGoodFirst = null

      for (const kpi of this.kpis) {
        const compareWith = this.hasAmbitions
          ? parseInt(kpi.ambition, 10)
          : parseInt(kpi.reference, 10)

        if (parseInt(kpi.actual, 10) <= compareWith) {
          if (isGoodFirst === null) isGoodFirst = true
          good.push(kpi.label)
        } else {
          if (isGoodFirst === null) isGoodFirst = false
          bad.push(kpi.label)
        }
      }

      if (good.length)
        message += `De actuele waardes van ${good.join(
          " en "
        )} liggen op dit moment lager dan ${
          this.hasAmbitions ? "gepland" : "het referentiescenario"
        }. `

      if (bad.length)
        message += `De actuele waardes van ${bad.join(
          " en "
        )} liggen op dit moment hoger dan ${
          this.hasAmbitions ? "gepland" : "het referentiescenario"
        }. `

      if (this.appendix) message += this.appendix

      return message
    }
  }
})
</script>
