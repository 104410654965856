import ajax from "../lib/ajax"

export default {
  async getCities({ commit, dispatch, state }) {
    const isRefresh = state.cities.length > 0
    if (!isRefresh) dispatch("currentAction", "getCities")
    try {
      const cities = await ajax("cities", { authToken: state.authToken })
      if (!cities.error) commit("getCities", cities)
      else throw new Error(cities.error)
    } catch (error) {
      if (isRefresh) commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getCities")
    }
  },

  async addCity({ commit, dispatch, state }, { name, image } = {}) {
    dispatch("currentAction", "addCity")
    try {
      const city = await ajax(`cities`, {
        authToken: state.authToken,
        method: "POST",
        body: { name, image }
      })
      commit("addCity", city)
      return city
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addCity")
    }
  },

  async updateCity({ commit, dispatch, state }, { city_id, name, image } = {}) {
    dispatch("currentAction", "updateCity")
    try {
      const city = await ajax(`cities/${city_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: { name, image }
      })
      commit("updateCity", city)
      return city
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateCity")
    }
  },

  async deleteCity({ commit, dispatch, state }, cityId) {
    dispatch("currentAction", "deleteCity")
    try {
      await ajax(`cities/${cityId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteCity", cityId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteCity")
    }
  },

  async getCityDataset(
    { dispatch, state, commit },
    { cityId, year, projectId }
  ) {
    if (!cityId || !year) return
    dispatch("currentAction", "getCityDataset")
    try {
      const startOfYear = `${year}-01-01`
      const endOfYear = `${year + 1}-01-01`

      const query = [`start_date=${startOfYear}`, `end_date=${endOfYear}`]

      if (projectId) query.push(`project=${projectId}`)

      const endpoint = `cities/${cityId}/data?${query.join("&")}`
      return await ajax(endpoint, { authToken: state.authToken })
    } catch (error) {
      commit("saveError", error.message)
      return []
    } finally {
      dispatch("completeAction", "getCityDataset")
    }
  },

  async getCityTraffic(
    { commit, dispatch, state },
    { city_id, start_date, end_date, project_id } = {}
  ) {
    if (!city_id || !start_date || !end_date) return
    dispatch("currentAction", "getCityTraffic")
    try {
      const query = []

      if (start_date && end_date) {
        const start = new Date(start_date)
        const end = new Date(end_date)
        const now = new Date()

        query.push(`start_date=${start.toISOString()}`)
        query.push(
          `end_date=${end > now ? now.toISOString() : end.toISOString()}`
        )
      }

      if (project_id) query.push(`project=${project_id}`)

      const endpoint = `cities/${city_id}/traffic-by-segment?${query.join("&")}`
      return await ajax(endpoint, { authToken: state.authToken })
    } catch (error) {
      commit("saveError", error.message)
      return []
    } finally {
      dispatch("completeAction", "getCityTraffic")
    }
  },

  async getCityKPIData(
    { commit, dispatch, state },
    { cityId, year, projectId } = {}
  ) {
    if (!cityId || !year) return
    dispatch("currentAction", "getCityKPIData")
    try {
      const query = projectId ? `project=${projectId}` : ""
      const endpoint = `cities/${cityId}/kpis/${year}?${query}`
      const results = await ajax(endpoint, { authToken: state.authToken })

      return { ...results, city_id: cityId, year }
    } catch (error) {
      // Do not log this error because it will show as a banner
      return {}
    } finally {
      dispatch("completeAction", "getCityKPIData")
    }
  }
}
