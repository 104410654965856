/*
  Actions in VueX are almost the same as they are in regular Flux/Redux. The big difference in Vue
  is that they can be async out of the box, and can be chained!

  In Redux, you'd need to use something like thunks to get the same effect.
*/

import auth from "./auth"
import base from "./base"
import cities from "./cities"
import journeys from "./journeys"
import kpis from "./kpis"
import measurements from "./measurements"
import orgs from "./orgs"
import projects from "./projects"
import usecases from "./usecases"
import pages from "./pages"
import users from "./users"
import session from "./session"
import vehicles from "./vehicles"
import segments from "./segments"

export default {
  ...auth,
  ...base,
  ...cities,
  ...journeys,
  ...kpis,
  ...measurements,
  ...orgs,
  ...projects,
  ...usecases,
  ...pages,
  ...users,
  ...session,
  ...vehicles,
  ...segments
}
