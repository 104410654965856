export default {
  getCities(state, cities = []) {
    if (!state.authToken) return

    state.cities = cities
  },

  addCity(state, city) {
    state.cities.push(city)
  },

  updateCity(state, updatedCity) {
    state.cities = state.cities.map(city =>
      Number(city.city_id) === Number(updatedCity.city_id)
        ? { ...city, ...updatedCity }
        : city
    )
  },

  deleteCity(state, deletedCityId) {
    state.cities = state.cities.filter(
      ({ city_id }) => deletedCityId !== city_id
    )
  }
}
