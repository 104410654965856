import { isObject } from "../lib/helpers"

export default {
  getProject(state, projectData) {
    if (!state.authToken) return

    const loadedProjects = state.projects.map(({ project_id }) => project_id)
    const newProjects = []

    if (Array.isArray(projectData)) {
      for (const newProject of projectData) {
        if (loadedProjects.indexOf(newProject.project_id) === -1)
          newProjects.push(newProject)
      }
    } else if (isObject(projectData)) {
      if (loadedProjects.indexOf(projectData.project_id) === -1)
        newProjects.push(projectData)
    }

    state.projects = state.projects.concat(newProjects)
  },

  addProject(state, project) {
    state.projects.push(project)
  },

  updateProject(state, updatedProject) {
    state.projects = state.projects.map(project =>
      Number(project.project_id) === Number(updatedProject.project_id)
        ? { ...project, ...updatedProject }
        : project
    )
  },

  deleteProject(state, deletedProjectId) {
    state.projects = state.projects.filter(
      ({ project_id }) => deletedProjectId !== project_id
    )
  }
}
