<template>
  <main :class="isStatsPage ? 'stats-pages' : ''">
    <navbar></navbar>
    <router-view v-if="api.up === true" class="view"></router-view>
    <section v-else-if="api.up === null" class="main">
      <h1>TNO Monitoringstool<br />stadslogistiek</h1>
      <p>API aan het laden...</p>
    </section>
    <section v-else class="main">
      <h1>TNO Monitoringstool<br />stadslogistiek</h1>
      <p>API is niet bereikbaar</p>
    </section>
    <footerbar class="footer"></footerbar>
  </main>
</template>

<script>
import { PUBLIC_ROUTES } from "./config/env"
import Vue from "vue"
import { mapState } from "vuex"
import Navbar from "./components/Navbar.vue"
import Footerbar from "./components/Footer.vue"

export default Vue.extend({
  components: { Navbar, Footerbar },

  data() {
    return {}
  },

  computed: {
    ...mapState(["api"]),
    isStatsPage() {
      return ["stad", "usecase"].indexOf(this.$route.name.toLowerCase()) > -1
    }
  },

  async beforeCreate() {
    this.$store.dispatch("initSession")

    try {
      await this.$store.dispatch("profile")
      this.$store.commit("updateAPIStatus", "up")
    } catch (error) {
      switch (error.message) {
        case "network_request_failed":
          this.$store.commit("updateAPIStatus", "down")
          break
        case "unauthorised":
        case "invalid_token":
          this.$store.commit("resetAuthToken")
          if (PUBLIC_ROUTES.indexOf(this.$route.name) === -1)
            this.$router.push({
              name: "Inloggen",
              query: { redirect_to: this.$route.fullPath }
            })
        // no break
        default:
          this.$store.commit("updateAPIStatus", "up")
      }
    }
  }
})
</script>

<style lang="scss">
html,
body {
  height: 100%;
}
.view > h1 {
  margin-top: 2rem;
}
.view {
  p,
  li,
  input,
  a,
  label {
    line-height: 150%;
  }
}
</style>

<style lang="scss" scoped>
main,
section {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: auto;
}
.view {
  margin: 1rem auto 4rem;
  width: $full-width;
  max-width: calc(100% - 2rem);
}
</style>
