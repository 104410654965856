import ajax from "../lib/ajax"

export default {
  async getProjects(
    { commit, state, dispatch },
    { projectIds, queueWork = false } = {}
  ) {
    const isRefresh = state.cities.length > 0
    if (queueWork && !isRefresh) dispatch("currentAction", "getProjects")

    try {
      let endpoint = "projects"

      // We want a set of projects by ID
      if (Array.isArray(projectIds) && projectIds.length > 0)
        endpoint += `?project_id=${projectIds.join(",")}`
      // We want a single project
      else if (["string", "number"].indexOf(typeof projectIds) > -1)
        endpoint += `/${projectIds}`

      const projectData = await ajax(endpoint, { authToken: state.authToken })
      if (!projectData.error) commit("getProject", projectData)
      else throw new Error(projectData.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (queueWork && !isRefresh) dispatch("completeAction", "getProjects")
    }
  },

  async addProject({ commit, dispatch, state }, payload) {
    dispatch("currentAction", "addProject")

    try {
      const project = await ajax(`projects`, {
        authToken: state.authToken,
        method: "POST",
        body: payload
      })
      commit("addProject", project)
      return project
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addProject")
    }
  },

  async updateProject({ commit, dispatch, state }, payload) {
    dispatch("currentAction", "updateProject")
    try {
      const project = await ajax(`projects/${payload.project_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: payload
      })
      commit("updateProject", project)
      return project
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateProject")
    }
  },

  async deleteProject({ commit, dispatch, state }, projectId) {
    dispatch("currentAction", "deleteProject")

    try {
      await ajax(`projects/${projectId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteProject", projectId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteProject")
    }
  }
}
