<template>
  <div>
    <p class="loading-message" v-if="gettingUserList">
      Gebruikers aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th>Naam</th>
          <th>Organisatie</th>
          <th>E-mail</th>
          <th>Rol</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <td>
            {{ user.name }}
          </td>
          <td>
            <span v-show="!gettingOrgList && userOrg(user)">
              {{ userOrg(user).name }}
            </span>
          </td>
          <td>
            <a :href="'mailto:' + user.email">{{ user.email }}</a>
          </td>
          <td>
            <span
              class="scope"
              v-if="user.scopes && user.scopes.indexOf('uploader') > -1"
              >Uploader</span
            >
            <span
              class="scope"
              v-if="user.scopes && user.scopes.indexOf('admin') > -1"
              >Beheerder</span
            >
            <span
              class="scope"
              v-if="user.scopes && user.scopes.indexOf('god') > -1"
              >God</span
            >
          </td>
          <td class="ta-r actions">
            <router-link
              v-if="userIsAdmin"
              :to="{ path: `/admin/users/update/${user.slug}` }"
            >
              Wijzig
            </router-link>
            <a
              v-if="
                !userIsGod && user.scopes && user.scopes.indexOf('god') > -1
              "
              class="muted"
              v-tooltip="'Je kan god niet verwijderen, dat kan hij alleen zelf'"
            >
              Verwijder
            </a>
            <a
              v-else-if="
                (userIsAdmin && !user.scopes) ||
                  (user.scopes && user.scopes.indexOf('god') === -1) ||
                  userIsGod
              "
              v-on:click="deleteUser(user)"
            >
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingUser"
    >
      <h3 slot="header">Voeg een gebruiker toe</h3>
      <div
        slot="body"
        :class="gettingUserList || savingUser ? 'loading-form' : ''"
      >
        <div class="field">
          <label>Naam</label>
          <input type="text" placeholder="Vul naam in..." v-model="name" />
          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam in
          </p>
        </div>
        <div class="field">
          <label>E-mailadres</label>
          <input type="text" placeholder="Vul email in..." v-model="email" />
          <p class="error-message" v-if="$v.$dirty && $v.email.$error">
            Vul een (geldig) e-mailadres in
          </p>
        </div>
        <div class="field">
          <label>Organisatie</label>
          <select v-model="org">
            <option disabled selected="selected">Kies organisatie...</option>
            <option v-for="org in orgs" :value="org.organisation_id">{{
              org.name
            }}</option>
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.org.$error">
            Kies een organisatie
          </p>
        </div>
        <div class="field">
          <label>
            <input type="checkbox" v-model="isUploader" />
            Uploader
          </label>
        </div>
        <div class="field">
          <label>
            <input type="checkbox" v-model="isAdmin" />
            Beheerder
          </label>
        </div>
        <p v-if="creating">
          Er wordt automatisch een email verstuurd naar {{ userName }} om een
          wachtwoord aan te maken.
        </p>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapState } from "vuex"
import { required, minLength, numeric, email } from "vuelidate/lib/validators"

import { properName } from "../../lib/validators"
import Modal from "../../components/Modal.vue"

export default Vue.extend({
  data() {
    return {
      name: null,
      email: null,
      org: null,
      isUploader: false,
      isAdmin: false
    }
  },

  validations: {
    name: { required, minLength: minLength(2), properName },
    email: { required, email },
    org: { required, numeric }
  },

  components: { Modal },

  computed: {
    ...mapGetters([
      "gettingUserList",
      "gettingOrgList",
      "userHasScopeOver",
      "savingUser",
      "userIsAdmin",
      "userIsGod"
    ]),
    ...mapState(["users", "orgs"]),

    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.slug
    },

    showModal() {
      return this.$route.path.endsWith("/create")
    },
    userName() {
      return this.email && this.email.includes("@") && this.email.includes(".")
        ? this.email
        : "de gebruiker"
    },

    user() {
      if (!this.users) return
      return this.users.find(({ slug }) => slug === this.$route.params.slug)
    },

    users() {
      return this.$store.state.users.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsers", { queueWork: true }).then(() => {
      if (this.user) {
        this.name = this.user.name
        this.email = this.user.email
        this.org = this.user.organisation
        this.isUploader =
          this.user.scopes && this.user.scopes.includes("uploader")
        this.isAdmin = this.user.scopes && this.user.scopes.includes("admin")

        if (this.$v) this.$v.$reset()
      }
    })

    if (this.$route.path.endsWith("/create")) this.$store.dispatch("getOrgs")
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null
      this.email = null
      this.org = null
      this.isUploader = false
      this.isAdmin = false

      if (this.$v) this.$v.$reset()

      return next()
    }

    const user = this.users.find(({ slug }) => slug === to.params.slug)

    if (user) {
      this.name = user.name
      this.email = user.email
      this.org = user.organisation
      this.isUploader = user.scopes && user.scopes.includes("uploader")
      this.isAdmin = user.scopes && user.scopes.includes("admin")

      if (this.$v) this.$v.$reset()
    }

    next()
  },

  watch: {
    showModal(show) {
      if (show) this.$store.dispatch("getOrgs")
    }
  },

  methods: {
    async save() {
      if (this.savingUser) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.user ? "updateUser" : "addUser"

      const scopes = []
      if (this.isUploader) scopes.push("uploader")
      if (this.isAdmin) scopes.push("admin")

      const payload = {
        name: this.name,
        email: this.email,
        organisation: this.org,
        scopes
      }

      if (this.user) payload.user_id = this.user.user_id

      const error = await this.$store.dispatch(action, payload)
      if (error instanceof Error)
        this.$el.querySelector(".modal-container").scrollTo(0, 0)
      else this.closeModal()
    },

    deleteUser({ user_id, name }) {
      if (!confirm(`Are you sure you want to delete ${name}?`)) return
      this.$store.dispatch("deleteUser", user_id)
    },

    userOrg({ organisation }) {
      if (!organisation) return
      return this.orgs.find(
        ({ organisation_id }) => organisation_id === organisation
      )
    },

    closeModal() {
      return this.$router.push("/admin/users")
    }
  }
})
</script>

<style lang="scss" scoped>
.scope {
  color: white;
  background: $tno-blue;
  padding: 0.4em 0.3em 0.3em 0.4em;
  border-radius: 0.5em;
  font-size: 0.7em;
  position: relative;
  top: 2px;
}
</style>
