<template>
  <p class="error" v-if="error">{{ translate(error) }} ({{ countDown }})</p>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "ErrorBox",

  data() {
    return {
      countDown: 5
    }
  },

  watch: {
    error() {
      if (!this.error) return
      setTimeout(() => (this.countDown = 4), 1000)
      setTimeout(() => (this.countDown = 3), 2000)
      setTimeout(() => (this.countDown = 2), 3000)
      setTimeout(() => (this.countDown = 1), 4000)
      setTimeout(() => this.$store.commit("resetError"), 5000)
      setTimeout(() => (this.countDown = 5), 5001)
    }
  },

  computed: {
    error() {
      const error = this.$store.state.error
      const authToken = this.$store.state.authToken
      const user = Object.keys(this.$store.state.user).length
      if (error === "unauthorised" && (!user || !authToken)) return
      return error
    }
  },

  methods: {
    translate(message) {
      if (message.includes("network_request_failed"))
        return "Kan geen verbinding maken met de server"
      if (message.includes("unauthorised"))
        return "Je hebt geen rechten om deze actie uit te voeren"
      return message
    }
  }
})
</script>
