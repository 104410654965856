export default {
  gettingUserList(state) {
    return state.currentWork.indexOf("getUsers") > -1
  },

  userIsAdmin(state, getters) {
    if (getters.userIsGod) return true
    return (
      state.user && state.user.scopes && state.user.scopes.indexOf("admin") > -1
    )
  },

  userIsUploader(state, getters) {
    if (getters.userIsGod) return true
    return (
      state.user &&
      state.user.scopes &&
      state.user.scopes.indexOf("uploader") > -1
    )
  },

  userIsGod(state) {
    return (
      state.user && state.user.scopes && state.user.scopes.indexOf("god") > -1
    )
  },

  userHasScopeOver: (state, getters) => ({
    scope: soughtScope,
    organisation,
    usecase,
    user
  } = {}) => {
    if (!state.user || !state.user.scopes) return false
    if (getters.userIsGod) return true

    const { scopes: userScopes, organisation: userOrgId } = state.user

    if (userScopes.indexOf(soughtScope) === -1) return false

    if (organisation) return userOrgId === organisation.organisation_id
    else if (user) return userOrgId === user.organisation
    else if (usecase && usecase.organisations)
      return usecase.organisations.indexOf(userOrgId) > -1

    return false
  },

  savingUser(state) {
    return (
      state.currentWork.indexOf("addUser") > -1 ||
      state.currentWork.indexOf("updateUser") > -1
    )
  }
}
