<template>
  <div>
    <gmap-map
      id="map"
      ref="mapRef"
      :center="{ lng: 4.8504, lat: 52.3763 }"
      :options="mapOptions"
    >
      <gmap-circle
        v-for="(pin, index) in plotPoints"
        :key="'b' + index"
        :center="pin.center"
        :visible="true"
        :options="circleOptions"
      ></gmap-circle>
      <gmap-info-window
        v-for="(pin, index) in plotPoints"
        :key="'c' + index"
        :position="pin.center"
        :opened="true"
      >
        {{ pin.name }}
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps"

import googleMapStyles from "../config/google-map-styles"
import { reduceToIndex } from "../lib/helpers"
import { LARGEST_MAP_BLOB_SIZE } from "../config/env"

export default {
  name: "Map",

  data() {
    return {
      mapOptions: {
        zoomControl: true,
        scrollwheel: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: googleMapStyles
      },
      circleOptions: {
        strokeWeight: 0,
        fillOpacity: 0.7,
        fillColor: "#538cbd",
        radius: LARGEST_MAP_BLOB_SIZE
      }
    }
  },

  props: ["usecases", "data", "kpis", "measurements", "activeStat", "year"],

  watch: {
    usecases() {
      this.initMap()
    },
    data() {
      this.initMap()
    },
    kpis() {
      this.initMap()
    },
    measurements() {
      this.initMap()
    },
    activeStat() {
      this.initMap()
    }
  },

  computed: {
    google: gmapApi,

    dataLoaded() {
      if (!this.usecases || this.usecases.length < 1) return false
      if (!this.data) return false
      if (!this.kpis || this.kpis.length < 1) return false
      if (!this.measurements || this.measurements.length < 1) return false

      return true
    },

    plotPoints() {
      if (!this.dataLoaded) return []

      return (
        this.usecases
          // If a usecase has no coords, don't map it!
          .filter(({ latitude, longitude }) => latitude && longitude)
          // Prepare for consumption
          .map(({ latitude, longitude, name }) => {
            return {
              name,
              center: { lat: latitude, lng: longitude }
            }
          })
      )
    }
  },

  methods: {
    initMap() {
      if (!this.dataLoaded) return

      // At this point, the child GmapMap has been mounted, but
      // its map has not been initialized.
      // Therefore we need to write mapRef.$mapPromise.then(() => ...)
      this.$refs.mapRef.$mapPromise.then(map => {
        const bounds = new google.maps.LatLngBounds()

        for (const { center, plan, realised } of this.plotPoints) {
          const circle = new google.maps.Circle({
            ...this.circleOptions,
            center
          })

          // Center map around 1 or more circles
          bounds.union(circle.getBounds())
          map.setCenter(bounds.getCenter())

          // When there is only one circle, don't use fitBounds because it will zoom in big time
          if (this.plotPoints.length === 1) {
            map.setZoom(14)
          } else {
            map.fitBounds(bounds, { bottom: 0, left: 0, right: 0, top: 0 })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 400px;
}
</style>

<style lang="scss">
.vue-map-container .gm-ui-hover-effect {
  display: none !important;
}
</style>
