<script>
import Chart from "chart.js"
import { Bar } from "vue-chartjs"

import { formatNumber, unitConvert } from "../lib/units"

Chart.defaults.global.defaultFontColor = "#8a8a8a"
Chart.defaults.global.defaultFontFamily = "Helvetica, Arial, sans-serif"

export default {
  name: "BarChart",

  extends: Bar,

  data() {
    const unit = this.unit
    const labelStringY = this.labelStringY

    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: {
          enabled: true,

          callbacks: {
            label: (tooltipItem, { labels, datasets, unit }) => {
              const dataset = datasets[tooltipItem.datasetIndex]
              const round = unit === "co2" ? 1 : 2
              let label = labels[tooltipItem.index]

              if (label) label += ": "

              const actual = unitConvert(dataset.data[tooltipItem.index], unit)
              label +=
                typeof actual === "number" ? formatNumber(actual, { round }) : actual

              return `${label} ${unit}`
            }
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: !!labelStringY,
                labelString: labelStringY
              },
              ticks: {
                beginAtZero: true,
                userCallback: function(label) {
                  const round = unit === "co2" ? 1 : 2

                  return typeof label === "number"
                    ? formatNumber(unitConvert(label, unit), { round })
                    : label
                }
              }
            }
          ],
          xAxes: [
            {
              display: false,
              type: "category",
              ticks: {
                maxRotation: 90
              }
            }
          ]
        }
      }
    }
  },

  props: ["chartdata", "options", "unit", "labelStringY"],

  mounted() {
    this.render()
  },

  watch: {
    chartdata() {
      this.render()
    },
    options() {
      this.render()
    },
    unit() {
      this.render()
    },
    labelStringY() {
      this.render()
    }
  },

  methods: {
    render() {
      this.renderChart(this.chartdata, {
        ...this.chartOptions,
        ...this.options
      })
    }
  }
}
</script>
