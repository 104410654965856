export default {
  getMeasurements(state, measurements = []) {
    if (!state.authToken) return

    state.measurements = measurements
  },

  addMeasurement(state, measure) {
    state.measurements.push(measure)
  },

  updateMeasurement(state, updatedMeasure) {
    state.measurements = state.measurements.map(measure =>
      Number(measure.measurement_id) === Number(updatedMeasure.measurement_id)
        ? { ...measure, ...updatedMeasure }
        : measure
    )
  },

  deleteMeasurement(state, deletedMeasureId) {
    state.measurements = state.measurements.filter(
      ({ measurement_id }) => deletedMeasureId !== measurement_id
    )
  }
}
