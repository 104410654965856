<template>
  <figure :style="styles" :class="styleClasses"></figure>
</template>

<script>
import Vue from "vue"
import { API_URI } from "../config/env"

export default Vue.extend({
  name: "SafeImage",

  computed: {
    styleClasses() {
      const classes = ["image"]
      if (this.round) classes.push("round")

      return classes
    },

    styles() {
      const styles = [`width: ${this.width};`, `height: ${this.height};`]

      if (this.src) styles.push(`background-image: url('${this.imageUri}');`)

      return styles.join(" ")
    },

    imageUri() {
      if (this.src.indexOf("http") > -1) return this.src
      return `${API_URI}/files/${encodeURIComponent(this.src)}?auth_token=${
        this.$store.state.authToken
      }`
    }
  },

  props: {
    round: {
      type: Boolean,
      default: false
    },
    width: {
      default: 100
    },
    height: {
      default: 100
    },
    src: {
      type: String
    },
    alt: {
      type: String
    }
  }
})
</script>

<style lang="scss" scoped>
figure {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $grey-lighter;

  &.round {
    border-radius: 1000px;
  }
}
</style>
