<template>
  <section :class="loading ? 'loading-chart' : ''">
    <ExplanationText
      messageText="Tot slot een overzicht van het totale transport en emissies:"
    ></ExplanationText>

    <nav-tabs>
      <ul>
        <li>
          <a
            :class="isActiveTab('inner') ? 'is-active' : ''"
            @click="currentTab = 'inner'"
            >Totaal binnen de stad</a
          >
        </li>
        <li>
          <a
            :class="isActiveTab('outer') ? 'is-active' : ''"
            @click="currentTab = 'outer'"
            >Totaal buiten de stad</a
          >
        </li>
        <li>
          <a
            :class="isActiveTab('total') ? 'is-active' : ''"
            @click="currentTab = 'total'"
            >Totaal</a
          >
        </li>
      </ul>
    </nav-tabs>

    <div class="two-columns responsive">
      <div>
        <div class="data-header">
          <h3>Transport</h3>
          <p>&nbsp;</p>
        </div>
        <progress-bar
          :options="
            getSaving({
              slug: 'rides',
              title: 'Aantal ritdelen',
              inlineTitle: 'Het aantal ritdelen'
            })
          "
        ></progress-bar>
        <progress-bar
          :options="
            getSaving({
              slug: 'distance',
              unit: 'km',
              title: 'Afstand',
              inlineTitle: 'De afstand'
            })
          "
        ></progress-bar>
      </div>
      <div>
        <div class="data-header">
          <h3>Emissies</h3>
          <p>&nbsp;</p>
        </div>
        <progress-bar
          :options="
            getSaving({ slug: 'co2', title: 'CO<sub>2</sub>', unit: 'ton' })
          "
        ></progress-bar>
        <progress-bar
          :options="
            getSaving({ slug: 'pm10', title: 'PM<sub>10</sub>', unit: 'kg' })
          "
        ></progress-bar>
        <progress-bar
          :options="
            getSaving({ slug: 'nox', title: 'NO<sub>x</sub>', unit: 'kg' })
          "
        ></progress-bar>
      </div>
    </div>
    <ul class="legend horizontal-list">
      <li>Referentie</li>
      <li>Realisatie</li>
      <li>Meer dan referentiescenario</li>
    </ul>

    <p class="description ta-r pr-0">
      <router-link class="muted" to="/page/tno-berekeningen"
        >Berekeningen</router-link
      >
    </p>
  </section>
</template>

<script>
import Vue from "vue"
import NavTabs from "./NavTabs.vue"
import ProgressBar from "./ProgressBar.vue"
import ExplanationText from "./ExplanationText.vue"
import { getGoalForWeeks, startIndex, endIndex } from "../lib/calculators"
import { selectWeeksFromData, weeksBetween } from "../lib/helpers"
import { MEASUREMENT_MAPPINGS } from "../config/env"

export default Vue.extend({
  name: "ConclusionStats",

  components: { NavTabs, ProgressBar, ExplanationText },

  data() {
    return {
      currentTab: "inner"
    }
  },

  props: [
    "loading",
    "data",
    "year",
    "kpis",
    "weekStart",
    "weekEnd",
    "hasAmbitions",
    "city"
  ],

  computed: {
    startIndex,
    endIndex,
    chartLoaded() {
      return (
        !!this.data &&
        !!this.kpis &&
        Object.keys(this.kpis).length > 0 &&
        !this.loading
      )
    },
    nonAccuData() {
      if (this.weekStart && this.weekEnd)
        return selectWeeksFromData({
          data: this.data,
          weekStart: this.weekStart,
          weekEnd: this.weekEnd,
          accumulative: false
        })
      return this.data
    }
  },

  methods: {
    getGoalForWeeks,

    isActiveTab(name) {
      return this.currentTab === name
    },
    realisedTotal(regex) {
      let realised = 0
      for (const key in this.nonAccuData) {
        if (
          Object.prototype.hasOwnProperty.call(this.nonAccuData, key) &&
          new RegExp(regex, "i").test(key)
        ) {
          realised += this.nonAccuData[key].reduce(
            (prev, next) => prev + (next || 0),
            0
          )
        }
      }
      return realised
    },
    getPlan({ metric }) {
      const prop = Object.keys(MEASUREMENT_MAPPINGS).find(
        key => MEASUREMENT_MAPPINGS[key] === metric
      )

      if (!prop) return

      const kpi = this.kpis[prop]

      if (!kpi) return

      return this.currentTab === "total"
        ? kpi.inner_city_reference + kpi.outer_city_reference
        : kpi[`${this.currentTab}_city_reference`]
    },
    getSaving({ slug, unit, title, inlineTitle }) {
      const where =
        this.currentTab === "total" ? "(inner|outer)" : this.currentTab
      if (slug === "rides")
        return {
          where: this.currentTab,
          title,
          inlineTitle,
          unit,
          realised: this.realisedTotal(`^drives_${where}`),
          plan: this.getPlan({ metric: "rides" })
        }
      if (slug === "distance")
        return {
          where: this.currentTab,
          title,
          inlineTitle,
          unit,
          realised: this.realisedTotal(`^kms_driven(.*)${where}`),
          plan: this.getPlan({ metric: "kms-road" })
        }
      else
        return {
          where: this.currentTab,
          title,
          inlineTitle,
          unit,
          realised: this.realisedTotal(`^${slug}_(.*)${where}`),
          plan: this.getPlan({ metric: slug })
        }
    }
  }
})
</script>

<style lang="scss" scoped>
.legend {
  li:nth-of-type(1)::before {
    background-color: #d7e7f2;
  }
  li:nth-of-type(2)::before {
    background-color: #538dbd;
  }
  li:nth-of-type(3)::before {
    background-color: #c90968;
  }
}
</style>
