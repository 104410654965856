<template>
  <div>
    <p v-if="loading">Laden...</p>
    <ul>
      <li
        v-for="segment of segments"
        :key="segment.name"
        :class="`emission-${emission}`"
      >
        <span class="bar" :style="`width: ${barWidth(segment.amount)}%`"></span>
        <span class="label"
          >{{ segment.name }} ({{ percentage(segment.amount) }}%)</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { EMISSIONS_SLUGS } from "../config/env"

export default {
  name: "SegmentsChart",

  data() {
    return {
      animation: false
    }
  },

  created() {
    setTimeout(() => {
      this.animation = true
    }, 100)
  },

  props: ["chartdata", "options", "emission", "loading"],

  methods: {
    percentage(amount) {
      const percentage = (100 * amount) / this.total
      const rounded = Math.round(percentage)
      return percentage < 1
        ? percentage < 0.1
          ? `< 0,1`
          : `< 1`
        : `~${rounded}`
    },
    barWidth(amount) {
      if (!this.highest || !this.animation) return 0
      return Math.round((amount * 100) / this.highest)
    }
  },

  computed: {
    highest() {
      const highest = [...this.segments]
        .sort(({ amount: left }, { amount: right }) => (left < right ? 1 : -1))
        .shift()

      return highest && highest.amount ? highest.amount : null
    },

    total() {
      return this.segments.reduce(
        (total, { amount = 0 } = {}) => total + amount,
        0
      )
    },

    segments() {
      const data = this.chartdata ? [...this.chartdata] : []

      return data
        .sort(({ name: left }, { name: right }) => (left > right ? 1 : -1))
        .sort(({ name: left }, { name: right }) => (left === "Overig" ? 1 : -1))
        .filter(
          segment =>
            // Check if any of the emmisions for this segment has a value higher than 0
            !!Object.keys(segment).find(
              key =>
                segment[key] > 0 &&
                new RegExp(`(${EMISSIONS_SLUGS.join("|")})`, "g").test(key)
            )
        )
        .map(segment => {
          const amount = Object.keys(segment).reduce((total, key) => {
            if (key.includes(this.emission)) total += segment[key]
            return total
          }, 0)

          return {
            amount,
            name: segment.name
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  height: 40px;
  display: block;
  position: relative;

  &.emission-co2 {
    .bar {
      background-color: #fdc108;
    }
  }
  &.emission-pm10 {
    .bar {
      background-color: #c10363;
    }
  }
  &.emission-nox {
    .bar {
      background-color: #53bd8b;
    }
  }

  .bar {
    z-index: 0;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0%;
    min-width: 1%;
    transition: width 500ms ease-in;
  }
  .label {
    z-index: 1;
    position: absolute;
    border-radius: 1000px;
    font-size: 12px;
    top: 0.5rem;
    left: 0.5rem;
    bottom: 0.5rem;
    padding: 0 0.5rem;
    box-shadow: 1px 1px 3px grey;
    color: white;
    background-color: #404040;
  }
}
li + li {
  margin-top: 3px;
}
</style>
