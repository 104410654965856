<template>
  <AdminLayout>
    <router-view></router-view>
  </AdminLayout>
</template>

<script>
import Vue from "vue"
import AdminLayout from "../../components/AdminLayout.vue"

export default Vue.extend({
  data() {
    return {}
  },

  components: { AdminLayout },

  // In case of inter-admin navigation ends up at just /admin
  beforeRouteUpdate(to, from, next) {
    return next(to.path === "/admin" ? { path: "/admin/users" } : undefined)
  }
})
</script>

<style lang="scss" scoped></style>
