export default {
  gettingSegmentList(state) {
    return state.currentWork.indexOf("getSegments") > -1
  },

  savingSegment(state) {
    return (
      state.currentWork.indexOf("addSegment") > -1 ||
      state.currentWork.indexOf("updateSegment") > -1
    )
  }
}
