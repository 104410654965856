<template>
  <section :class="chartLoaded ? '' : 'loading-chart'">
    <ExplanationText
      :kpis="explanationKpis"
      :hasAmbitions="hasAmbitions"
    ></ExplanationText>

    <div class="three-columns responsive">
      <div>
        <div class="data-header">
          <h3 v-tooltip="'Koolstofdioxide'">CO<sub>2</sub></h3>
          <p><strong>Binnen en buiten</strong> de stad</p>
        </div>

        <div class="two-columns no-gab">
          <div>
            <p class="mb-3px" v-if="hasAmbitions">Ambitie</p>
            <p class="mb-3px" v-else>Referentie</p>
            <h4>
              <span v-tooltip="ambitionText" v-if="goalTotals.co2.hasGoal">
                {{
                  formatNumber(
                    unitConvert(
                      this.showProratedKPIs
                        ? goalTotals.co2.prorated
                        : goalTotals.co2.kpi,
                      "ton"
                    ),
                    { round: 1 }
                  )
                }}
                ton
                <br />
                <span
                  class="total-goal-text"
                  v-if="
                    showProratedKPIs &&
                      Math.round(goalTotals.co2.kpi) !==
                        Math.round(goalTotals.co2.prorated)
                  "
                  >({{
                    formatNumber(unitConvert(goalTotals.co2.kpi, "ton"), { round: 1 })
                  }}
                  ton totaal)</span
                >
              </span>
              <span v-else>n.v.t.</span>
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">Gerealiseerd</p>
            <h4>{{ formatNumber(unitConvert(totals.co2, "ton"), { round: 1 }) }} ton</h4>
          </div>
        </div>
      </div>

      <div>
        <div class="data-header">
          <h3 v-tooltip="'Fijnstof'">PM<sub>10</sub></h3>
          <p><strong>Binnen en buiten</strong> de stad</p>
        </div>

        <div class="two-columns no-gab">
          <div>
            <p class="mb-3px" v-if="hasAmbitions">Ambitie</p>
            <p class="mb-3px" v-else>Referentie</p>
            <h4>
              <span v-tooltip="ambitionText" v-if="goalTotals.pm10.hasGoal">
                {{
                  formatNumber(
                    unitConvert(
                      this.showProratedKPIs
                        ? goalTotals.pm10.prorated
                        : goalTotals.pm10.kpi,
                      "kg"
                    )
                  )
                }}
                kg
                <br />
                <span
                  class="total-goal-text"
                  v-if="
                    showProratedKPIs &&
                      Math.round(goalTotals.pm10.kpi) !==
                        Math.round(goalTotals.pm10.prorated)
                  "
                  >({{
                    formatNumber(unitConvert(goalTotals.pm10.kpi, "kg"))
                  }}
                  kg totaal)</span
                >
              </span>
              <span v-else>n.v.t.</span>
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">Gerealiseerd</p>
            <h4>{{ formatNumber(unitConvert(totals.pm10, "kg")) }} kg</h4>
          </div>
        </div>
      </div>

      <div>
        <div
          class="data-header"
          v-tooltip="'Eén rit bestaat uit een heen en een terug rit'"
        >
          <h3>Ritten</h3>
          <p>Alleen <strong>binnen</strong> de stad</p>
        </div>

        <div class="two-columns no-gab">
          <div>
            <p class="mb-3px" v-if="hasAmbitions">Ambitie</p>
            <p class="mb-3px" v-else>Referentie</p>
            <h4 v-if="hasAmbitions">
              <span v-tooltip="ambitionText" v-if="goalTotals.rides.hasGoal">
                {{
                  Math.round(
                    this.showProratedKPIs
                      ? goalTotals.rides.prorated
                      : goalTotals.rides.kpi
                  )
                }}
                <br />
                <span
                  class="total-goal-text"
                  v-if="
                    showProratedKPIs &&
                      Math.round(goalTotals.rides.kpi) !==
                        Math.round(goalTotals.rides.prorated)
                  "
                  >({{ Math.round(goalTotals.rides.kpi) }} totaal)</span
                >
              </span>
              <span v-else>n.v.t.</span>
            </h4>
            <h4 v-else>
              <span v-tooltip="ambitionText" v-if="goalTotals.rides.hasGoal">
                {{
                  Math.round(
                    this.showProratedKPIs
                      ? goalTotals.rides.prorated
                      : goalTotals.rides.kpi
                  )
                }}
                <br />
                <span
                  class="total-goal-text"
                  v-if="
                    showProratedKPIs &&
                      Math.round(goalTotals.rides.kpi) !==
                        Math.round(goalTotals.rides.prorated)
                  "
                  >({{ Math.round(goalTotals.rides.kpi) }} totaal)</span
                >
              </span>
              <span v-else>n.v.t.</span>
            </h4>
          </div>
          <div class="ta-r">
            <p class="mb-3px">Gerealiseerd</p>
            <h4>{{ totals.rides }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div v-if="usecase && weekStart === weekEnd" class="mt-2">
      <p :chartdata="chartdata">
        De grafiek wordt alleen getoond bij meerdere weken
      </p>
    </div>
    <div v-else>
      <chart
        class="chart"
        v-if="chartLoaded"
        :ymax="yLimit"
        :chartdata="chartdata"
        :height="500"
        :options="options"
        showAxisLabels="x"
      ></chart>
      <p class="description ta-r pr-0">
        <router-link class="muted" to="/page/tno-berekeningen"
          >Berekeningen</router-link
        >
      </p>
    </div>
  </section>
</template>

<script>
import Vue from "vue"

import Chart from "./LineChart.vue"
import ExplanationText from "./ExplanationText.vue"
import { unitConvert, formatNumber } from "../lib/units"
import { selectWeeksFromData, hasAGoal, reduceToIndex } from "../lib/helpers"
import {
  linearGrowthByPercent,
  startIndex,
  endIndex
} from "../lib/calculators"
import { RIDE_TYPES, ENGINE_TYPES } from "../config/env"

export default Vue.extend({
  name: "ProgressStats",

  components: { Chart, ExplanationText },

  data() {
    return {
      options: {}
    }
  },

  props: [
    "data",
    "usecase",
    "year",
    "kpis",
    "weekStart",
    "weekEnd",
    "loading",
    "hasAmbitions",
    "preciseWeeks",
    "city"
  ],

  methods: {
    unitConvert,
    formatNumber
  },

  computed: {
    /*
      This should be true when:
        1. In usecase view, the project's end_date has been reached
        2. In city view, when the year we are viewing is before this year
    */
    caseIsConcluded() {
      if (!this.usecase && !this.year) return false

      return this.usecase
        ? new Date() > new Date(this.usecase.end_date)
        : new Date().getUTCFullYear() > this.year
    },

    /*
      This should be true when:
        1. caseIsConcluded is false
        2. In usecase view, the selected end week is < the total end week
    */
    showProratedKPIs() {
      if (!this.caseIsConcluded) return true

      return this.usecase
        ? this.data.weeks.length > this.weekEnd - this.weekStart
        : new Date().getUTCFullYear() === this.year
    },

    ambitionText() {
      const goalType = this.hasAmbitions ? "Ambitie" : "Referentie"

      return this.year
        ? `${goalType} voor ${this.year}`
        : `${goalType} voor week ${Math.ceil(
            this.weekStart
          )} tot en met ${Math.ceil(this.weekEnd)}`
    },

    accuData() {
      if (this.weekStart && this.weekEnd)
        return selectWeeksFromData({
          data: this.data,
          weekStart: this.weekStart,
          weekEnd: this.weekEnd,
          accumulative: true
        })
      return this.data
    },

    nonAccuData() {
      if (this.weekStart && this.weekEnd)
        return selectWeeksFromData({
          data: this.data,
          weekStart: this.weekStart,
          weekEnd: this.weekEnd,
          accumulative: false
        })
      return this.data
    },

    weeks() {
      if (this.accuData && this.accuData.weeks) return this.accuData.weeks
    },

    explanationKpis() {
      if (!this.chartLoaded) return []

      return [
        {
          label: "CO<sub>2</sub>",
          actual: this.totals.co2,

          // Prorated here is already based on hasAmbitions
          // so we can use them for both because we also check
          // for hasAmbitions in the ExplanationText component
          reference: this.goalTotals.co2.prorated,
          ambition: this.goalTotals.co2.prorated
        },
        {
          label: "PM<sub>10</sub>",
          actual: this.totals.pm10,
          reference: this.goalTotals.pm10.prorated,
          ambition: this.goalTotals.pm10.prorated
        },
        {
          label: "Ritten",
          actual: this.totals.rides,
          reference: this.goalTotals.rides.prorated,
          ambition: this.goalTotals.rides.prorated
        }
      ]
    },

    chartLoaded() {
      return (
        !!this.weeks &&
        (!this.hasAmbitions ||
          (!!this.kpis && Object.keys(this.kpis).length > 0)) &&
        (!!this.usecase || !!this.weekEnd) &&
        !this.loading
      )
    },

    goalline() {
      return linearGrowthByPercent({
        weekStart: this.weekStart,
        weekEnd: this.weekEnd,
        slices: this.endIndex - this.startIndex + 1,
        total: 100
      })
    },

    chartWeekLabels() {
      if (!this.chartLoaded) return []

      const weeks = Array.from(Array(this.endIndex - this.startIndex + 1)).map(
        (_, index) => this.startIndex + index + 1
      )

      return weeks.map((week, index) => {
        if (index === 0) return `Week ${week}`
        return week
      })
    },

    chartDatasets() {
      if (!this.chartLoaded) return []

      const datasets = [
        {
          label: this.hasAmbitions ? "Ambitie" : "Referentie",
          key: this.hasAmbitions ? "goal" : "reference",
          borderColor: "#000000",
          borderWidth: 3,
          data: this.goalline.map(({ y }) => ({ y, actual: "100%" })),
          borderDash: [3, 5]
        }
      ]

      const now = new Date()
      const co2Set = []
      const pm10Set = []
      const ridesSet = []
      const cumTotals = {
        co2: 0,
        pm10: 0,
        rides: 0
      }

      for (const [index, date] of this.accuData.weeks.entries()) {
        if (new Date(date) > now) continue

        if (this.goalTotals.co2.hasGoal) {
          const weeklyCO2 =
            this.accuData.co2_inner_city_drives[index] +
            this.accuData.co2_outer_city_drives[index] +
            this.accuData.co2_inner_city_sails[index] +
            this.accuData.co2_outer_city_sails[index]

          cumTotals.co2 += weeklyCO2

          const divisor = this.caseIsConcluded
            ? this.goalTotals.co2.kpi
            : this.goalTotals.co2.prorated

          let weeklyCO2Percent = (weeklyCO2 / divisor) * 100

          if (co2Set[index - 1]) weeklyCO2Percent += co2Set[index - 1].y

          co2Set.push({
            y: weeklyCO2Percent,
            actual: `${formatNumber(
              unitConvert(weeklyCO2, "ton"),
              { round: 1 }
            )} ton (${formatNumber(
              unitConvert(cumTotals.co2, "ton"),
              { round: 1 }
            )} ton cumulatief)`
          })
        }

        if (this.goalTotals.pm10.hasGoal) {
          const weeklyPM10 =
            this.accuData.pm10_inner_city_drives[index] +
            this.accuData.pm10_outer_city_drives[index] +
            this.accuData.pm10_inner_city_sails[index] +
            this.accuData.pm10_outer_city_sails[index]

          cumTotals.pm10 += weeklyPM10

          const divisor = this.caseIsConcluded
            ? this.goalTotals.pm10.kpi
            : this.goalTotals.pm10.prorated

          let weeklyPM10Percent = (weeklyPM10 / divisor) * 100

          if (pm10Set[index - 1]) weeklyPM10Percent += pm10Set[index - 1].y

          pm10Set.push({
            y: weeklyPM10Percent,
            actual: `${formatNumber(
              unitConvert(weeklyPM10, "kg")
            )} kg (${formatNumber(
              unitConvert(cumTotals.pm10, "kg")
            )} kg cumulatief)`
          })
        }

        if (this.goalTotals.rides.hasGoal) {
          const weeklyRides = RIDE_TYPES.reduce(
            (total, type) =>
              this.accuData[type] ? total + this.accuData[type][index] : total,
            0
          )

          cumTotals.rides += weeklyRides

          const divisor = this.caseIsConcluded
            ? this.goalTotals.rides.kpi
            : this.goalTotals.rides.prorated

          let weeklyRidePercent = (weeklyRides / divisor) * 100

          if (ridesSet[index - 1]) weeklyRidePercent += ridesSet[index - 1].y

          ridesSet.push({
            y: weeklyRidePercent,
            actual: `${weeklyRides} (${cumTotals.rides} cumulatief)`
          })
        }
      }

      if (this.goalTotals.co2.hasGoal) {
        datasets.push({
          label: "CO2",
          key: "co2",
          borderColor: "#FDC108",
          data: co2Set
        })

        if (this.hasAmbitions) {
          const referenceTotal =
            this.kpis['co2-in-grams'].inner_city_reference +
            this.kpis['co2-in-grams'].outer_city_reference

          const divisor = this.caseIsConcluded
            ? this.goalTotals.co2.kpi
            : this.goalTotals.co2.prorated

          datasets.push({
            label: "CO2 referentie",
            borderColor: "rgba(253,193,8,50)",
            borderWidth: 3,
            data: linearGrowthByPercent({
              weekStart: this.weekStart,
              weekEnd: this.weekEnd,
              slices: this.data.weeks.length,
              total: (referenceTotal / divisor) * 100
            }).map(({ y }, index) => ({
              y,
              actual: `${formatNumber(
                unitConvert(
                  (referenceTotal / this.data.weeks.length) * (index + 1),
                  "ton"
                ),
                { round: 1 }
              )} ton cumulatief`
            })),
            borderDash: [3, 5]
          })
        }
      }

      if (this.goalTotals.pm10.hasGoal) {
        datasets.push({
          label: "PM10",
          key: "pm10",
          borderColor: "#C10363",
          data: pm10Set
        })

        if (this.hasAmbitions) {
          const referenceTotal =
            this.kpis['pm10-in-grams'].inner_city_reference +
            this.kpis['pm10-in-grams'].outer_city_reference

          const divisor = this.caseIsConcluded
            ? this.goalTotals.pm10.kpi
            : this.goalTotals.pm10.prorated

          datasets.push({
            label: "PM10 referentie",
            borderColor: "rgba(193,3,99,50)",
            borderWidth: 3,
            data: linearGrowthByPercent({
              weekStart: this.weekStart,
              weekEnd: this.weekEnd,
              slices: this.data.weeks.length,
              total: (referenceTotal / divisor) * 100
            }).map(({ y }, index) => ({
              y,
              actual: `${formatNumber(
                unitConvert(
                  (referenceTotal / this.data.weeks.length) * (index + 1),
                  "kg"
                )
              )} kg cumulatief`
            })),
            borderDash: [3, 5]
          })
        }
      }

      if (this.goalTotals.rides.hasGoal) {
        datasets.push({
          label: "Ritten over de weg",
          key: "rides",
          borderColor: "#538CBE",
          data: ridesSet
        })

        if (this.hasAmbitions) {
          const referenceTotal = this.kpis['rides-on-the-road']
            .inner_city_reference

          const divisor = this.caseIsConcluded
            ? this.goalTotals.rides.kpi
            : this.goalTotals.rides.prorated

          datasets.push({
            label: "Ritten referentie",
            borderColor: "rgba(83,140,190,50)",
            borderWidth: 3,
            data: linearGrowthByPercent({
              weekStart: this.weekStart,
              weekEnd: this.weekEnd,
              slices: this.data.weeks.length,
              total: (referenceTotal / divisor) * 100,
              float: false
            }).map(({ y }, index) => ({
              y,
              actual: `${Math.round(
                (referenceTotal / this.data.weeks.length) * (index + 1)
              )} rit./vaart. cumulatief`
            })),
            borderDash: [3, 5]
          })
        }
      }

      return datasets
    },

    totals() {
      if (!this.chartLoaded) return { co2: 0, pm10: 0, rides: 0 }

      return {
        co2: reduceToIndex(
          this.endIndex,
          [
            this.accuData.co2_inner_city_drives,
            this.accuData.co2_outer_city_drives,
            this.accuData.co2_inner_city_sails,
            this.accuData.co2_outer_city_sails
          ],
          this.startIndex
        ),
        pm10: reduceToIndex(
          this.endIndex,
          [
            this.accuData.pm10_inner_city_drives,
            this.accuData.pm10_outer_city_drives,
            this.accuData.pm10_inner_city_sails,
            this.accuData.pm10_outer_city_sails
          ],
          this.startIndex
        ),
        rides: reduceToIndex(
          this.endIndex,
          RIDE_TYPES.map(field => this.accuData[field]),
          this.startIndex
        )
      }
    },

    chartdata() {
      if (!this.chartLoaded) return { labels: [], datasets: [] }

      return {
        labels: this.chartWeekLabels,
        datasets: this.chartDatasets
      }
    },

    goalTotals() {
      const goalTotals = {
        co2: {
          kpi: 0,
          prorated: 0,
          hasGoal: false
        },
        pm10: {
          kpi: 0,
          prorated: 0,
          hasGoal: false
        },
        rides: {
          kpi: 0,
          prorated: 0,
          hasGoal: false
        }
      }

      if (!this.chartLoaded || !this.kpis) return goalTotals

      const co2 = this.kpis["co2-in-grams"]
      const pm10 = this.kpis["pm10-in-grams"]
      const rides = this.kpis["rides-on-the-road"]
      const goalType = this.hasAmbitions ? "ambition" : "reference"

      if (hasAGoal(co2, goalType)) {
        const innerProrated = co2[`inner_city_${goalType}`]
        const outerProrated = co2[`outer_city_${goalType}`]
        const prorated = innerProrated + outerProrated

        goalTotals.co2.prorated = Number.isNaN(prorated) ? 0 : prorated

        const innerTotal = co2[`total_inner_city_${goalType}`]
        const outerTotal = co2[`total_outer_city_${goalType}`]
        const total = innerProrated + outerProrated

        goalTotals.co2.kpi = Number.isNaN(total) ? 0 : total

        goalTotals.co2.hasGoal = true
      }

      if (hasAGoal(pm10, goalType)) {
        const innerProrated = pm10[`inner_city_${goalType}`]
        const outerProrated = pm10[`outer_city_${goalType}`]
        const prorated = innerProrated + outerProrated

        goalTotals.pm10.prorated = Number.isNaN(prorated) ? 0 : prorated

        const innerTotal = pm10[`total_inner_city_${goalType}`]
        const outerTotal = pm10[`total_outer_city_${goalType}`]
        const total = innerProrated + outerProrated

        goalTotals.pm10.kpi = Number.isNaN(total) ? 0 : total

        goalTotals.pm10.hasGoal = true
      }

      if (rides && rides[`inner_city_${goalType}`]) {
        const prorated = rides[`inner_city_${goalType}`]

        goalTotals.rides.prorated = Number.isNaN(prorated) ? 0 : prorated

        const total = rides[`total_inner_city_${goalType}`]

        goalTotals.rides.kpi = Number.isNaN(total) ? 0 : total

        goalTotals.rides.hasGoal = true
      }

      return goalTotals
    },

    yLimit() {
      if (!this.chartLoaded) return

      const max = Math.max(
        ...this.chartdata.datasets.map(
          ({ data }) => data.length && data[data.length - 1].y
        )
      )

      return max > 0 && typeof max === "number" ? max : undefined
    },

    startIndex,
    endIndex
  }
})
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 3rem;
}

.total-goal-text {
  font-size: 15px;
  font-weight: normal;
  color: #8a8a8a;
}
</style>
