import ajax from "../lib/ajax"

export default {
  async authenticate({ commit, dispatch }, { email, password } = {}) {
    dispatch("currentAction", "authenticating")
    try {
      const login = await ajax("auth", {
        method: "POST",
        body: { email, password }
      })
      if (login.error) throw new Error(login.error)
      commit("authenticate", login.access_token)
      await dispatch("profile")
      return login
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "authenticating")
    }
  },

  async profile({ state, dispatch, commit }) {
    dispatch("currentAction", "getProfile")
    try {
      await dispatch("getUser")
      await dispatch("getOrgs", state.user.organisation)
    } catch (error) {
      commit("saveError", error.message)
      throw error
    } finally {
      dispatch("completeAction", "getProfile")
    }
  },

  async logout({ commit }) {
    await commit("logout")
    await commit("updateAPIStatus", "up")
  },

  async getUser({ commit, state, dispatch }) {
    dispatch("currentAction", "getUser")
    try {
      const request = await ajax("auth", { authToken: state.authToken })
      if (!request.error) commit("getUser", request)
      else throw new Error(request.error)
    } catch (error) {
      commit("saveError", error.message)
      throw error
    } finally {
      dispatch("completeAction", "getUser")
    }
  },

  async sendPasswordReset({ commit, dispatch }, email) {
    dispatch("currentAction", "sendPasswordReset")
    try {
      await ajax("auth/password/forgot", {
        method: "POST",
        body: { email, origin: document.location.origin }
      })
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "sendPasswordReset")
    }
  },

  async resetPasswordViaToken({ commit, dispatch }, { token, password }) {
    dispatch("currentAction", "resetPasswordViaToken")
    try {
      await ajax("auth/password/reset", {
        method: "POST",
        body: { token, password }
      })
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "resetPasswordViaToken")
    }
  }
}
