import ajax from "../lib/ajax"

export default {
  async getSegments({ commit, dispatch, state }) {
    const isRefresh = state.segments.length > 0
    if (!isRefresh) dispatch("currentAction", "getSegments")
    try {
      const segments = await ajax("segments", { authToken: state.authToken })
      if (!segments.error) commit("getSegments", segments)
      else throw new Error(segments.error)
    } catch (error) {
      if (isRefresh) commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getSegments")
    }
  },

  async addSegment({ commit, dispatch, state }, { name } = {}) {
    dispatch("currentAction", "addSegment")
    try {
      const segment = await ajax(`segments`, {
        authToken: state.authToken,
        method: "POST",
        body: { name }
      })
      commit("addSegment", segment)
      return segment
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addSegment")
    }
  },

  async updateSegment({ commit, dispatch, state }, { segment_id, name } = {}) {
    dispatch("currentAction", "updateSegment")
    try {
      const segment = await ajax(`segments/${segment_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: { name }
      })
      commit("updateSegment", segment)
      return segment
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateSegment")
    }
  },

  async deleteSegment({ commit, dispatch, state }, segmentId) {
    dispatch("currentAction", "deleteSegment")
    try {
      await ajax(`segments/${segmentId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteSegment", segmentId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteSegment")
    }
  }
}
