import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from "./store"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior({ hash }, from, savedPosition) {
    if (savedPosition) return savedPosition
    else if (hash) return { selector: hash }
    else return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const isAdmin = store.getters.userIsAdmin
  const isUploader = store.getters.userIsUploader
  const scopeRequirements = []

  for (const route of to.matched) {
    if (!route.meta || !route.meta.mustHaveScope) continue

    for (const scope of route.meta.mustHaveScope) {
      if (scopeRequirements.indexOf(scope) === -1) scopeRequirements.push(scope)
    }
  }

  // Route does not have any scope requirements
  if (scopeRequirements.length < 1) return next()
  // The route requires admin scope + user is an admin
  else if (scopeRequirements.indexOf("admin") > -1 && isAdmin) return next()
  // The route requires uploader scope + user is admin or uploader
  else if (
    scopeRequirements.indexOf("uploader") > -1 &&
    (isAdmin || isUploader)
  )
    return next()
  // The route's scope requirements are sadly unmet
  else next({ path: "/", replace: true })
})

export default router
