<template>
  <div :class="percentage > 100 ? 'progress-negative' : 'progress-positive'">
    <div class="split">
      <div>
        <p v-html="options.title"></p>
        <h4 v-if="typeof options.realised === 'number'">
          {{ format(options.realised, options.unit) }} {{ options.unit }}
        </h4>
        <h4 v-else>? {{ options.unit }}</h4>
      </div>
      <div>
        <p>Referentie</p>
        <h4 v-if="typeof options.plan === 'number'">
          {{ format(options.plan, options.unit) }} {{ options.unit }}
        </h4>
        <h4 v-else>? {{ options.unit }}</h4>
      </div>
    </div>
    <div :class="`progress ${percentage === -1 ? 'progress--empty' : ''}`">
      <div class="overflow" v-if="percentage > 100"></div>
      <div class="planned" v-if="percentage < 100"></div>
      <div
        :class="percentage < 100 ? 'realised' : 'planned'"
        :style="
          `width: ${percentage > 100 ? (100 / percentage) * 100 : percentage}%`
        "
      ></div>
    </div>
    <div v-if="percentage === -1" class="percentage percentage--empty">
      <p
        v-html="`Er is geen KPI ingevuld voor '${options.title}' in de admin`"
      ></p>
    </div>
    <div
      v-else
      :class="`percentage ${percentage === 0 ? 'percentage--empty' : ''}`"
    >
      <p :style="styleLabel(percentage)">
        <span v-if="percentage === 0">&nbsp;</span>
        <span
          v-else
          v-tooltip="
            `${
              options.inlineTitle ? options.inlineTitle : `De ${options.title}`
            } (${format(options.realised, options.unit)}${
              options.unit ? ` ${options.unit}` : ''
            }) is ${percentage}% van de ${where} (${format(
              options.plan,
              options.unit
            )}${options.unit ? ` ${options.unit}` : ''})`
          "
          >{{ percentage }}%</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { gram2ton, gram2kg, formatNumber } from "../lib/units"

export default {
  name: "ProgressBar",

  props: ["options"],

  computed: {
    where() {
      if (this.options.where === "total") return "totale referentie"
      if (this.options.where === "inner") return "referentie binnen de stad"
      if (this.options.where === "outer") return "referentie buiten de stad"
      return "referentie"
    },
    percentage() {
      const { plan, realised } = this.options
      if (typeof plan !== "number") return -1
      if (typeof realised !== "number") return 0
      return Math.round((realised * 100) / plan)
    }
  },

  methods: {
    formatNumber,
    gram2ton,
    gram2kg,

    format(number, unit) {
      if (unit === "ton") return formatNumber(gram2ton(number), { round: 1 })
      if (unit === "kg") return formatNumber(gram2kg(number))
      return formatNumber(Math.round(number))
    },

    styleLabel(percentage) {
      if (percentage > 5 && percentage < 95)
        return `transform: translateX(-50%); margin-left: ${percentage}%`
      if (percentage <= 5) return `transform: translateX(0%); margin-left: 0%`
      if (percentage >= 95)
        return `transform: translateX(-100%); margin-left: 100%`
    }
  }
}
</script>

<style lang="scss" scoped>
$speed: 500ms;
h4 {
  margin: 0 0 0.5rem;
}
p {
  margin-bottom: 0.3rem;
}
.progress {
  position: relative;
  height: 10px;
  margin-bottom: 0.5rem;
}
.progress > div {
  height: 10px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
}
.progress {
  .planned {
    background-color: #d7e7f2;
    width: 100%;
  }
  .realised {
    background-color: #538dbd;
    transition: width $speed ease-in-out;
  }
  .overflow {
    background-color: #c90968;
  }
  &--empty .planned {
    background-color: $grey-lighter;
  }
}
.percentage > p {
  padding: 3px 8px;
  border-radius: 100px;
  display: inline-block;
  color: white;
  margin: 0;
  min-width: 50px;
  text-align: center;
  background-color: #538dbd;
  transform: translateX(0%);
  transition: transform $speed ease-in-out, margin-left $speed ease-in-out;
  margin-left: 0%;
  .progress-negative & {
    background-color: #c90968;
  }
}
.percentage--empty p {
  background-color: transparent;
  color: $grey;
  padding-left: 0;
}
.overflow {
  width: 100%;
}
.split > div:last-of-type {
  text-align: right;
}
.progress-positive .realised {
  color: #538dbd;
}
.progress-negative .realised {
  color: #c90968;
}
</style>
