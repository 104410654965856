export default {
  getUsers(state, users = []) {
    if (!state.authToken) return

    state.users = users
  },

  deleteUser(state, deletedUserId) {
    state.users = state.users.filter(({ user_id }) => deletedUserId !== user_id)
  },

  addUser(state, user) {
    state.users.push(user)
  },

  updateUser(state, updatedUser) {
    state.users = state.users.map(user =>
      Number(user.user_id) === Number(updatedUser.user_id)
        ? { ...user, ...updatedUser }
        : user
    )
  }
}
