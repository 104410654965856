<template>
  <section>
    <h1>Admin</h1>
    <ErrorBox></ErrorBox>
    <AdminTabs></AdminTabs>
    <slot></slot>
  </section>
</template>

<script>
import Vue from "vue"
import AdminTabs from "./AdminTabs.vue"
import ErrorBox from "./ErrorBox.vue"

export default Vue.extend({
  name: "AdminLayout",

  data() {
    return {}
  },

  components: { AdminTabs, ErrorBox },

  beforeCreate() {
    if (!this.$store.getters.isAuthenticated) this.$router.push("/login")
  },

  beforeUpdate() {
    if (!this.$store.getters.isAuthenticated) this.$router.push("/login")
  }
})
</script>

<style lang="scss" scoped></style>
