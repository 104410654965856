import ajax from "../lib/ajax"

export default {
  async getOrgs(
    { commit, state, dispatch },
    { orgIds, queueWork = false } = {}
  ) {
    const isRefresh = state.cities.length > 0
    if (queueWork && !isRefresh) dispatch("currentAction", "getOrgs")

    try {
      let endpoint = "organisations"

      // We want a set of organisations by ID
      if (Array.isArray(orgIds) && orgIds.length > 0)
        endpoint += `?organisation_id=${orgIds.join(",")}`
      // We want a single org
      else if (["string", "number"].indexOf(typeof orgIds) > -1)
        endpoint += `/${orgIds}`

      const orgData = await ajax(endpoint, { authToken: state.authToken })
      if (!orgData.error) commit("getOrg", orgData)
      else throw new Error(orgData.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (queueWork && !isRefresh) dispatch("completeAction", "getOrgs")
    }
  },

  async addOrg({ commit, dispatch, state }, { name } = {}) {
    dispatch("currentAction", "addOrg")

    try {
      const organisation = await ajax(`organisations`, {
        authToken: state.authToken,
        method: "POST",
        body: { name }
      })
      commit("addOrg", organisation)
      return organisation
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addOrg")
    }
  },

  async updateOrg({ commit, dispatch, state }, { organisation_id, name } = {}) {
    dispatch("currentAction", "updateOrg")

    try {
      const organisation = await ajax(`organisations/${organisation_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: { name }
      })
      commit("updateOrg", organisation)
      return organisation
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateOrg")
    }
  },

  async deleteOrg({ commit, dispatch, state }, orgId) {
    dispatch("currentAction", "deleteOrg")

    try {
      await ajax(`organisations/${orgId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteOrg", orgId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteOrg")
    }
  }
}
