<template>
  <aside>
    <div>
      <div class="current-project" v-if="yearly">
        <p class="m-0" v-if="projectValue">
          <span v-if="projectValue && projectValue.name"
            >Project: <strong>{{ projectValue.name }}</strong></span
          >
          <span v-else-if="projectValue === 'all'">Alle projecten</span>
          <span v-if="projects && projects.length > 1"
            >(<a @click="toggleProjectPicker">aanpassen</a>)</span
          >
        </p>

        <div class="picker" v-if="projectPickerOpen">
          <ul>
            <li>
              <a @click="changeProject('all')">Alle projecten</a>
            </li>
            <li v-for="(project, index) of projects" :key="'project.' + index">
              <a @click="changeProject(project.project_id)">{{
                project.name
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="current-date" v-else>
        <p class="m-0">
          Week

          <select class="styled" v-model="weekSelectorStart">
            <option
              v-for="(week, index) of weeks"
              :key="index"
              :selected="weekSelectorStart === index + 1"
            >
              {{ week }}
            </option>
          </select>

          tot en met

          <select class="styled" v-model="weekSelectorEnd">
            <option
              v-for="(week, index) of weeks"
              :key="index"
              :selected="weekSelectorEnd === index + 1"
            >
              {{ week }}
            </option>
          </select>

          <a @click="updateWeek" v-if="dirty">Aanpassen</a>

          <span class="error ml-1" v-if="weekError">{{ weekError }}</span>
        </p>
      </div>

      <div class="picker-container" v-if="yearly">
        <p class="m-0">
          Jaar: <strong>{{ displayValue }}</strong> (<a
            @click="toggleYearPicker"
            v-if="yearly"
            >aanpassen</a
          >)
        </p>

        <div class="picker" v-if="yearPickerOpen">
          <ul>
            <li v-for="(year, index) of years" :key="index">
              <a @click="changeYear(year)">{{ year }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import Vue from "vue"

export default Vue.extend({
  name: "ChartPickerBar",

  data() {
    return {
      currentDateSelected: null,
      currentProjectSelected: null,

      yearPickerOpen: false,
      weekPickerOpen: false,
      projectPickerOpen: false,

      weekSelectorStart: this.weekStart,
      weekSelectorEnd: this.weekEnd,

      weekError: ""
    }
  },

  created() {
    if (this.yearSetInUrl) this.changeYear(this.dateValue, false)
    else if (this.lastJourneyYear) this.changeYear(this.lastJourneyYear, false)
  },

  computed: {
    weekly() {
      return this.mode === "week"
    },
    yearly() {
      return this.mode === "year"
    },

    dirty() {
      // Numbers in a select option will become text so we don't use !== here
      return (
        this.weekStart != this.weekSelectorStart ||
        this.weekEnd != this.weekSelectorEnd
      )
    },

    displayValue() {
      if (this.currentDateSelected) return this.currentDateSelected
      return "xxxx"
    }
  },

  watch: {
    dateValue(newValue) {
      this.currentDateSelected = Number(newValue)
    },
    weekSelectorStart() {
      this.weekError = ""
    },
    weekSelectorEnd() {
      this.weekError = ""
    },
    weekEnd() {
      this.weekSelectorEnd = this.weekEnd
    },
    weekStart() {
      this.weekSelectorStart = this.weekStart
    }
  },

  props: [
    "mode",
    "weekStart",
    "weekEnd",
    "weeks",
    "years",
    "dateValue",
    "onUpdate",
    "projects",
    "projectValue",
    "onUpdateProject",
    "lastJourneyYear",
    "yearSetInUrl"
  ],

  methods: {
    toggleYearPicker() {
      this.yearPickerOpen = !this.yearPickerOpen
    },

    toggleWeekPicker() {
      this.weekPickerOpen = !this.weekPickerOpen
    },

    toggleProjectPicker() {
      this.projectPickerOpen = !this.projectPickerOpen
    },

    changeYear(year, toggle = true) {
      this.currentDateSelected = year
      if (toggle) this.toggleYearPicker()
      this.onUpdateValue(this.currentDateSelected)
    },

    changeWeek(week) {
      this.currentDateSelected = week
      this.toggleWeekPicker()
      this.onUpdateValue(this.currentDateSelected)
    },

    changeProject(projectId) {
      this.toggleProjectPicker()
      this.onUpdateProject(projectId)
    },

    onUpdateValue(value) {
      if (this.onUpdate) this.onUpdate(value)
    },

    onUpdateProjectValue(project_id) {
      if (this.onUpdateProject) this.onUpdateProject(project_id)
    },

    updateWeek() {
      // Numbers in a select option will become text, we need numbers here
      const start = parseInt(this.weekSelectorStart, 10)
      const end = parseInt(this.weekSelectorEnd, 10)

      this.weekError = ""
      if (start > end)
        return window.setTimeout(
          () => (this.weekError = "Start van periode is later dan einde"),
          200
        )
      else this.$emit("updateWeeks", start, end)
    }
  }
})
</script>

<style lang="scss" scoped>
aside {
  width: 100%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  background: white;
  padding: 15px 1rem;
  user-select: none;

  & > div {
    width: $full-width;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .picker {
    position: absolute;
    background: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    width: 150px;

    ul {
      list-style-type: none;
      width: 100%;
      overflow-x: scroll;
      max-height: 150px;

      li {
        background: white;

        &:hover {
          background: #f7f8f9;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          text-decoration: none;
          padding: 6px 4px;
        }
      }
    }
  }
}
</style>
