/*
  In VueX there are no reducers (unlike Redux) – state is modified via mutations.

  Each mutation represents a write to the application's state. The mutation method called
  depends on the action which created it – an action that does this:

  commit('cocks', true)

  Will attempt to run a mutation called `cocks`
*/

import api from "./api"
import auth from "./auth"
import base from "./base"
import cities from "./cities"
import errors from "./errors"
import kpis from "./kpis"
import measurements from "./measurements"
import orgs from "./orgs"
import projects from "./projects"
import pages from "./pages"
import usecases from "./usecases"
import session from "./session"
import users from "./users"
import vehicles from "./vehicles"
import navigation from "./navigation"
import segments from "./segments"

export default {
  ...api,
  ...auth,
  ...base,
  ...cities,
  ...errors,
  ...kpis,
  ...measurements,
  ...orgs,
  ...projects,
  ...pages,
  ...usecases,
  ...session,
  ...users,
  ...vehicles,
  ...navigation,
  ...segments
}
