import defaultState from "../store/default-state"

export default {
  authenticate(state, token) {
    state.authToken = token
  },

  /*
    Why not a simple

      state = defaultState

    Because state is not a simple object, and cannot be overwritten. It has getters and setters
    for each of its keys though.
  */
  logout(state) {
    Object.keys(defaultState).map(key => (state[key] = defaultState[key]))
  },

  getUser(state, user) {
    state.user = user
  },

  resetAuthToken(state) {
    state.authToken = null
  }
}
