<template>
  <div>
    <h1>Data</h1>
    <data-tabs></data-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue"
import DataTabs from "../../components/DataTabs.vue"

export default Vue.extend({
  components: { DataTabs }
})
</script>
