<template>
  <div>
    <p class="loading-message" v-if="gettingProjectList">
      Project aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th>Naam</th>
          <th>Ambitie</th>
          <th>Kaart</th>
          <th>Barcharts</th>
          <th>Stadstoegang</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="project in projects" :key="project.project_id">
          <td>{{ project.name }}</td>
          <td>{{ project.use_ambitions ? "Ja" : "Nee" }}</td>
          <td>{{ project.show_map ? "Ja" : "Nee" }}</td>
          <td>{{ project.use_barchart ? "Ja" : "Nee" }}</td>
          <td>
            <span
              v-for="(org, index) in projectOrgs(project)"
              :key="org.organisation_id"
              >{{ org.name
              }}<span v-if="index < projectOrgs(project).length - 1"
                >,
              </span></span
            >
          </td>
          <td class="ta-r actions">
            <router-link
              v-if="userIsAdmin"
              :to="{ path: `/admin/projects/update/${project.slug}` }"
              class="show-on-hover"
            >
              Wijzig
            </router-link>
            <a v-if="userIsAdmin" v-on:click="deleteProject(project)">
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { formatDateNicely } from "../../../lib/helpers.js"

export default Vue.extend({
  name: "AdminProjectsIndex",

  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      "gettingProjectList",
      "gettingOrgList",
      "gettingUsecaseList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin"
    ]),
    projects() {
      return this.$store.state.projects
    },
    usecases() {
      return this.$store.state.usecases
    },
    orgs() {
      return this.$store.state.orgs
    }
  },

  methods: {
    formatDateNicely,

    projectUsecases({ usecases }) {
      if (!usecases) return
      return this.usecases.filter(
        ({ project_id }) => (project_id = this.project_id)
      )
    },

    projectOrgs({ cityview_allowed_for } = {}) {
      if (!cityview_allowed_for) return
      return this.orgs.filter(
        ({ organisation_id }) =>
          cityview_allowed_for.indexOf(organisation_id) > -1
      )
    },

    deleteProject({ project_id, name }) {
      if (
        this.$store.state.usecases.find(
          ({ project }) => project && project === project_id
        )
      )
        return alert(
          `Er zijn use cases gelinkt aan ${name} – link deze aan een ander project voordat je dit project kan verwijderen.`
        )

      if (!confirm(`Weet je zeker dat je ${name} wilt verwijderen?`)) return

      this.$store.dispatch("deleteProject", project_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getProjects")
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getUsecases")
  }
})
</script>

<style lang="scss">
.v-select {
  position: relative;
  top: -2px;

  .vs__dropdown-toggle {
    border: 0;
    min-width: 200px;
    border-bottom: 1px solid black;
    border-radius: 0;
    padding-bottom: 0;

    & .vs__search {
      border-bottom: none !important;

      &::placeholder {
        color: black !important;
      }
    }
  }
}
</style>
