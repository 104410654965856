const { NODE_ENV } = process.env

const ENV_CONFIG = {
  production: {
    API_URI: "https://api.stadslogistiekdashboard.nl"
  },

  staging: {
    API_URI: "https://tno-bouw-dev-api.herokuapp.com"
  },

  test: {
    API_URI: "https://tno-bouw-test-api.herokuapp.com"
  },

  localhost: {
    API_URI: "http://localhost:4000"
  }
}

if (!ENV_CONFIG[NODE_ENV]) {
  throw new Error(
    `NODE_ENV '${NODE_ENV}' does not have a configuration set. Please see 'src/config/env.js'.`
  )
} else if (NODE_ENV !== "production") {
  console.info(`Running frontend with NODE_ENV=${NODE_ENV}.`)
}

/*
  Why like this and not simply:

    export const ENV_CONFIG[NODE_ENV]

  Because then it cannot be de-structured when imported!
*/
export const API_URI = ENV_CONFIG[NODE_ENV].API_URI

export const ENV_NAME = NODE_ENV

export const PUBLIC_ROUTES = [
  "Inloggen",
  "Herstel Wachtwoord",
  "Wachtwoord Vergeten"
]

export const CSVU_DELIMITERS = [
  { value: ";", label: "Puntkomma (;)" },
  { value: ",", label: "Komma (,)" }
]

export const ROUND_NUMBERS_FROM = 100

export const ENGINE_TYPES = [
  "human",
  "electric",
  "biodiesel",
  "diesel",
  "benzine"
]

export const POLLUTING_ENGINE_TYPES = ["biodiesel", "diesel", "benzine"]

export const VEHICLE_TYPES = ["water", "road"]

export const EMISSIONS_SLUGS = ["co2", "pm10", "nox"]

export const MEASUREMENT_MAPPINGS = {
  "co2-in-grams": "co2",
  "pm10-in-grams": "pm10",
  "rides-on-the-road": "rides",
  "sails-on-the-water": "sails",
  "nox-in-grams": "nox",
  "kms-on-the-road": "kms-road",
  "kms-on-the-water": "kms-water"
}

export const RIDE_TYPES = ENGINE_TYPES.map(
  fuelType => `drives_inner_city_${fuelType}`
)

export const LARGEST_MAP_BLOB_SIZE = 400

/*
  This is 1/7 in decimal form – which is the equivalent of a single day in week notation.
  A year is 52.142857142857146 long!
*/
export const DAY_OF_WEEK_LENGTH = 0.142857142857146
