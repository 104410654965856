import ajax from "../lib/ajax"

export default {
  async getUsers({ commit, dispatch, state }) {
    const isRefresh = state.users.length > 0
    if (!isRefresh) dispatch("currentAction", "getUsers")

    try {
      const users = await ajax("users", { authToken: state.authToken })
      if (!users.error) commit("getUsers", users)
      else throw new Error(users.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getUsers")
    }
  },

  async addUser(
    { commit, dispatch, state },
    { name, email, organisation, scopes } = {}
  ) {
    dispatch("currentAction", "addUser")
    commit("saveError", null)

    const body = {
      name,
      email,
      organisation,
      origin: document.location.origin,
      scopes
    }

    try {
      const user = await ajax(`users`, {
        authToken: state.authToken,
        method: "POST",
        body
      })
      commit("addUser", user)
      return user
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addUser")
    }
  },

  async updateUser(
    { commit, dispatch, state },
    { user_id, name, email, organisation, scopes } = {}
  ) {
    dispatch("currentAction", "updateUser")
    commit("saveError", null)

    const body = {
      name,
      email,
      organisation,
      origin: document.location.origin,
      scopes
    }

    try {
      const user = await ajax(`users/${user_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body
      })
      commit("updateUser", user)
      return user
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateUser")
    }
  },

  async deleteUser({ commit, dispatch, state }, userId) {
    dispatch("currentAction", "deleteUser")

    try {
      await ajax(`users/${userId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteUser", userId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteUser")
    }
  }
}
