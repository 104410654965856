<template>
  <section>
    <h1>TNO Monitoringstool<br />stadslogistiek</h1>
    <ErrorBox></ErrorBox>

    <p class="big">
      Het stadslogistiek dashboard is een initiatief van TNO en partners om de
      logistieke prestaties rondom use cases te monitoren.
    </p>

    <h2 class="alt mt-4 mb-2">Inloggen</h2>

    <p
      class="error mt-0 mb-2"
      :style="
        hadError ? (error ? 'opacity: 1' : 'opacity: 0') : 'display: none'
      "
    >
      {{ error }}&nbsp;
    </p>

    <div class="from-horizontal">
      <div class="field">
        <label>E-mailadres</label>
        <input
          type="email"
          placeholder="Vul e-mail in..."
          v-model="email"
          v-on:keyup.enter="login"
          v-on:keyup="error = ''"
        />
        <p class="error-message" v-if="$v.$dirty && $v.email.$error">
          Vul een geldig e-mailadres is
        </p>
      </div>

      <div class="field">
        <label>Wachtwoord</label>
        <input
          type="password"
          placeholder="Vul wachtwoord in..."
          v-model="password"
          v-on:keyup.enter="login"
          v-on:keyup="error = ''"
        />

        <p class="error-message" v-if="$v.$dirty && $v.password.$error">
          Vul je wachtwoord in
        </p>

        <p>
          <router-link to="/password/forgot" class="muted"
            >Wachtwoord vergeten?</router-link
          >
        </p>
      </div>

      <div class="field field--button">
        <button v-on:click="login" :disabled="loggingIn">Inloggen</button>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import {
  required,
  minLength,
  numeric,
  email,
  alphaNum
} from "vuelidate/lib/validators"
import ErrorBox from "../../components/ErrorBox"

export default Vue.extend({
  data() {
    return {
      hadError: false,
      error: "",
      email: null,
      password: null
    }
  },

  components: { ErrorBox },

  validations: {
    email: { required, email },
    password: { required }
  },

  computed: {
    ...mapGetters(["isAuthenticated", "loggingIn"])
  },

  beforeCreate() {
    if (this.$store.getters.isAuthenticated) this.$router.push("/")
  },

  methods: {
    login() {
      this.error = ""
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$store.commit("resetError")

      this.$store
        .dispatch("authenticate", {
          email: this.email,
          password: this.password
        })
        .then(({ message } = {}) => {
          if (["invalid_credentials", "unauthorised"].indexOf(message) === -1)
            return this.$router.push("/")
          this.password = ""
          this.hadError = true
          this.error = "Verkeerd e-mailadres of wachtwoord"
        })
        .catch(error => {
          this.password = ""
          this.hadError = true
          this.error = error.message
        })
    }
  }
})
</script>

<style lang="scss" scoped>
.error {
  max-width: 100%;
  width: 755px;
}
</style>
