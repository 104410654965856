<template>
  <nav>
    <slot></slot>
  </nav>
</template>

<script>
import Vue from "vue"
import NavTabs from "./NavTabs.vue"

export default Vue.extend({
  name: "NavTabs",

  components: { NavTabs }
})
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  li {
    display: inline-block;
    margin-right: 1.5rem;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: $medium-width) {
      margin-left: 0;

      @media (max-width: $medium-width) {
        margin: 0 0.5rem 0.5rem 0;
        display: block;
      }
    }
  }
  a {
    font-size: 20px;
    font-weight: bold;
    color: $text-color;
    text-decoration: none;
    border-bottom: 1px solid $muted;
    color: $muted;
    padding-bottom: 4px;

    &.is-active {
      border-bottom: 2px solid $text-color;
      color: $text-color;
    }
  }

  &.keep-horizontal li {
    @media (max-width: $medium-width) {
      display: inline-block;
    }
  }
}
nav {
  display: flex;
  align-items: center;
  margin: 2rem 0;

  & > div:last-of-type {
    margin-left: auto;
  }
}
</style>
