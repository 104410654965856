<template>
  <router-view></router-view>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  name: "AdminProjects",

  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      "gettingUsecaseList",
      "gettingOrgList",
      "gettingProjectList"
    ]),
    usecases() {
      return this.$store.state.usecases
    },
    orgs() {
      return this.$store.state.orgs
    },
    projects() {
      return this.$store.state.projects
    }
  },

  methods: {
    projectUsecases({ usecases }) {
      if (!usecases) return
      return this.usecases.filter(({ project }) => project === project_id)
    },

    usecaseOrgs({ organisations }) {
      if (!organisations) return
      return this.orgs.filter(
        ({ organisation_id }) => organisations.indexOf(organisation_id) > -1
      )
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getProjects")
  }
})
</script>

<style lang="scss" scoped></style>
