<template>
  <section>
    <h1>TNO Monitoringstool<br />stadslogistiek</h1>
    <ErrorBox></ErrorBox>
    <p class="big">Je wachtwoord vergeten? Hier is de oplossing.</p>

    <h2 class="alt mt-4 mb-3">Herstel wachtwoord</h2>

    <p v-if="complete">
      Je wachtwoord is hersteld! Ga naar
      <router-link to="/login">login</router-link> on in te loggen!
    </p>
    <p v-if="!token">
      In de link zou een token moeten staan, deze zien we niet. Neem contact op
      met <a href="mailto:christian.vanommeren@tno.nl">Christian</a> (TNO) voor
      hulp.
    </p>
    <div v-else :class="error ? 'error' : ''">
      <p class="error-message">{{ error }}</p>

      <div class="field">
        <label>Nieuw wachtwoord</label>
        <input
          type="password"
          placeholder="wachtwoord"
          v-model="password"
          v-on:keyup.enter="resetPassword"
        />
      </div>

      <div class="field">
        <label>Herhaal nieuw wachtwoord</label>
        <input
          type="password"
          placeholder="bevestig wachtwoord"
          v-model="passwordConfirm"
          v-on:keyup.enter="resetPassword"
        />
      </div>

      <div class="field field--button">
        <button v-on:click="resetPassword" :disabled="resetPasswordRequest">
          Herstel mijn wachtwoord
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { isEmpty } from "../../lib/helpers"
import ErrorBox from "../../components/ErrorBox"

export default Vue.extend({
  data() {
    return {
      password: null,
      passwordConfirm: null,
      complete: false,
      error: null
    }
  },

  components: { ErrorBox },

  computed: {
    token() {
      return this.$route.query.token
    },
    ...mapGetters(["resetPasswordRequest"])
  },

  methods: {
    resetPassword() {
      if (isEmpty(this.password) || isEmpty(this.passwordConfirm)) {
        return (this.error = "Vul wel alle velden in")
      } else if (this.password !== this.passwordConfirm) {
        return (this.error =
          "De nieuwe wachtwoorden zijn niet aan elkaar gelijk, probeer opnieuw")
      } else this.error = null

      const payload = {
        password: this.password,
        token: this.token
      }

      this.$store
        .dispatch("resetPasswordViaToken", payload)
        .then(() => (this.complete = true))
    }
  }
})
</script>

<style lang="scss" scoped></style>
