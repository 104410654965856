export default {
  saveError(state, error) {
    const message = error && error.message ? error.message : error
    switch (message) {
      case "misc_error":
        return (state.error = "Er gaat iets mis, bel Chris")
      case "invalid_request":
        return (state.error = "Formulier niet volledig ingevuld")
      default:
        state.error = message
    }
  },

  resetError(state) {
    state.error = ""
  }
}
