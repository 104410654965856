<template>
  <div>
    <p class="loading-message" v-if="gettingKPIList">KPI aan het laden...</p>
    <table v-else>
      <thead>
        <tr>
          <th>Use case</th>
          <th>KPI</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(kpi, index) in kpis" :key="index">
          <td>
            <span v-show="!gettingUsecaseList && kpiUsecase(kpi)">{{
              kpiUsecase(kpi).name
            }}</span>
          </td>
          <td>
            <span v-show="!gettingMeasurementList && kpiMeasurement(kpi)">
              <span v-if="kpiMeasurement(kpi).slug === 'co2-in-grams'"
                >CO<sub>2</sub> in tons</span
              >
              <span v-else v-html="kpiMeasurement(kpi).subName"></span>
            </span>
          </td>
          <td class="ta-r actions">
            <a v-if="userIsAdmin">
              <router-link
                :to="{ path: `/admin/kpis/update/${kpi.kpi_id}` }"
                class="show-on-hover"
              >
                Wijzig
              </router-link>
            </a>
            <a v-if="userIsAdmin" v-on:click="deleteKPI(kpi)">
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingKPI"
    >
      <h3 slot="header">Voeg een KPI toe</h3>
      <div
        slot="body"
        :class="gettingKPIList || savingKPI ? 'loading-form' : ''"
      >
        <div class="field">
          <label>Naam</label>
          <select v-model="measurement">
            <option disabled selected="selected">Kies KPI naam...</option>
            <option
              v-for="(measurement, index) in measurements"
              :key="index"
              :value="measurement.measurement_id"
              >{{ measurement.name }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.measurement.$error">
            Vul een (geldige) KPI naam in
          </p>
        </div>
        <div class="field">
          <label>Use case</label>
          <select v-model="usecase">
            <option disabled selected="selected">Kies use cases...</option>
            <option
              v-for="(usecase, index) in usecases"
              :key="index"
              :value="usecase.usecase_id"
              >{{ usecase.name }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.usecase.$error">
            Selecteer een use case
          </p>
        </div>
        <div class="field" v-if="useAmbitions">
          <label>Ambitie</label>
          <div class="shared">
            <div>
              <input
                type="checkbox"
                v-model="inner_city_ambition_active"
                @change="check($event, 'inner_city_ambition')"
              />
              <input
                type="text"
                v-model.number="inner_city_ambition"
                @input="inner_city_ambition_active = inner_city_ambition !== ''"
              />
              <label>Binnen de stad</label>
              <p
                class="error-message"
                v-if="$v.$dirty && $v.inner_city_ambition.$error"
              >
                Vul een (geldige) ambitie in
              </p>
            </div>
            <div>
              <input
                type="checkbox"
                v-model="outer_city_ambition_active"
                @change="check($event, 'outer_city_ambition')"
              />
              <input
                type="text"
                v-model.number="outer_city_ambition"
                @input="outer_city_ambition_active = outer_city_ambition !== ''"
              />
              <label>Buiten de stad</label>
              <p
                class="error-message"
                v-if="$v.$dirty && $v.outer_city_ambition.$error"
              >
                Vul een (geldige) ambitie in
              </p>
            </div>
          </div>
        </div>
        <div class="field">
          <label>Referentie</label>
          <div class="shared">
            <div>
              <input
                type="checkbox"
                v-model="inner_city_reference_active"
                @change="check($event, 'inner_city_reference')"
              />
              <input
                type="text"
                v-model.number="inner_city_reference"
                @input="
                  inner_city_reference_active = inner_city_reference !== ''
                "
              />
              <label>Binnen de stad</label>
              <p
                class="error-message"
                v-if="$v.$dirty && $v.inner_city_reference.$error"
              >
                Vul een (geldige) referentie in
              </p>
            </div>
            <div>
              <input
                type="checkbox"
                v-model="outer_city_reference_active"
                @change="check($event, 'outer_city_reference')"
              />
              <input
                type="text"
                v-model.number="outer_city_reference"
                @input="
                  outer_city_reference_active = outer_city_reference !== ''
                "
              />
              <label>Buiten de stad</label>
              <p
                class="error-message"
                v-if="$v.$dirty && $v.outer_city_reference.$error"
              >
                Vul een (geldige) referentie in
              </p>
            </div>
          </div>
        </div>
        <div v-if="!useAmbitions">
          <p class="mt-1 mb-1">
            Ambities zijn uitgeschakeld voor {{ project.name }}.
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required, numeric, decimal, minValue } from "vuelidate/lib/validators"
import { kpiValue } from "../../lib/validators"
import { generateSub } from "../../lib/helpers"

import SafeImage from "../../components/SafeImage.vue"
import Modal from "../../components/Modal.vue"

export default Vue.extend({
  data() {
    return {
      measurement: null,
      in_tonnes: false,
      usecase: null,
      useAmbitions: true,
      inner_city_ambition: "",
      outer_city_ambition: "",
      inner_city_reference: "",
      outer_city_reference: "",
      inner_city_ambition_active: false,
      outer_city_ambition_active: false,
      inner_city_reference_active: false,
      outer_city_reference_active: false
    }
  },

  validations: {
    measurement: { required, numeric, minValue: minValue(1) },
    usecase: { required, numeric, minValue: minValue(1) },
    inner_city_ambition: { kpiValue },
    outer_city_ambition: { kpiValue },
    inner_city_reference: { kpiValue },
    outer_city_reference: { kpiValue }
  },

  components: { SafeImage, Modal },

  watch: {
    usecase() {
      this.useAmbitions = true
      if (!this.project) return
      this.useAmbitions = this.project.use_ambitions
    }
  },

  computed: {
    ...mapGetters([
      "gettingMeasurementList",
      "gettingUsecaseList",
      "gettingKPIList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin",
      "savingKPI",
      "savingMeasurement"
    ]),
    project() {
      if (!this.usecase || !this.usecases.length || !this.projects.length)
        return

      const usecase = this.usecases.find(
        ({ usecase_id }) => usecase_id === this.usecase
      )
      if (!usecase) return

      return this.projects.find(
        ({ project_id }) => project_id === usecase.project
      )
    },
    measurements() {
      return this.$store.state.measurements
    },
    kpis() {
      return this.$store.state.kpis.sort((a, b) => {
        if (a.usecase === b.usecase) return a.measurement - b.measurement
        return a.usecase > b.usecase ? -1 : 1
      })
    },
    usecases() {
      return this.$store.state.usecases
    },
    projects() {
      return this.$store.state.projects
    },
    kpi() {
      if (!this.kpis || !this.$route.params.id) return
      const kpi = this.kpis.find(
        ({ kpi_id }) => kpi_id === Number(this.$route.params.id)
      )
      if (kpi && !(this.creating || this.updating)) {
        this.setKpiToData(kpi)
      }
      return kpi
    },
    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.id
    },

    isCreatingMeasurement() {
      return this.$store.state.currentWork.includes("addMeasurement")
    }
  },

  methods: {
    generateSub,

    isNumber(value) {
      return typeof value === "number"
    },

    getValue(property) {
      return this.isNumber(property)
        ? property / (this.in_tonnes ? 1000000 : 1)
        : null
    },

    setValue(property) {
      return this.isNumber(property)
        ? property * (this.in_tonnes ? 1000000 : 1)
        : null
    },

    setKpiToData(kpi) {
      const { isNumber, getValue } = this

      this.in_tonnes = this.kpiMeasurement(kpi).slug === "co2-in-grams"
      this.measurement = kpi.measurement

      this.inner_city_ambition = getValue(kpi.inner_city_ambition)
      this.outer_city_ambition = getValue(kpi.outer_city_ambition)
      this.inner_city_reference = getValue(kpi.inner_city_reference)
      this.outer_city_reference = getValue(kpi.outer_city_reference)

      this.usecase = kpi.usecase

      this.inner_city_ambition_active = isNumber(kpi.inner_city_ambition)
      this.outer_city_ambition_active = isNumber(kpi.outer_city_ambition)
      this.inner_city_reference_active = isNumber(kpi.inner_city_reference)
      this.outer_city_reference_active = isNumber(kpi.outer_city_reference)

      if (this.$v) this.$v.$reset()
    },

    check(event, prop) {
      if (!this[`${prop}_active`]) this[prop] = ""
      else this[prop] = 0
    },

    async save() {
      const { setValue } = this

      if (this.savingKPI) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      this.in_tonnes =
        this.kpiMeasurement({ measurement: this.measurement }).slug ===
        "co2-in-grams"

      const action = this.kpi ? "updateKPI" : "addKPI"
      const payload = {
        measurement: this.measurement,
        usecase: this.usecase,
        inner_city_ambition: this.useAmbitions
          ? setValue(this.inner_city_ambition)
          : null,
        outer_city_ambition: this.useAmbitions
          ? setValue(this.outer_city_ambition)
          : null,
        inner_city_reference: setValue(this.inner_city_reference),
        outer_city_reference: setValue(this.outer_city_reference)
      }

      if (this.kpi) payload.kpi_id = this.kpi.kpi_id

      const error = await this.$store.dispatch(action, payload)
      if (!(error instanceof Error)) this.closeModal()
    },

    closeModal() {
      return this.$router.push("/admin/kpis")
    },

    kpiUsecase({ usecase } = {}) {
      if (!usecase) return {}
      return (
        this.usecases.find(({ usecase_id }) => usecase === usecase_id) || {}
      )
    },

    kpiMeasurement({ measurement } = {}) {
      if (!measurement) return {}
      const found =
        this.measurements.find(
          ({ measurement_id }) => measurement === measurement_id
        ) || {}
      if (found) {
        found.name = found.slug === "co2-in-grams" ? "CO2 in tons" : found.name
        found.subName = generateSub(found.name)
      }
      return found
    },

    deleteKPI({ kpi_id }) {
      if (!confirm(`Weet je zeker dat je deze KPI wilt verwijderen?`)) return
      this.$store.dispatch("deleteKPI", kpi_id)
    },

    updateMeasurement(id) {
      this.measurement = id
    },

    createMeasurement(name) {
      if (this.savingMeasurement) return

      this.$store.dispatch("addMeasurement", { name }).then(newMeasurement => {
        this.measurement = newMeasurement.measurement_id
      })
    }
  },

  async beforeCreate() {
    await Promise.all([
      this.$store.dispatch("getProjects"),
      this.$store.dispatch("getUsecases"),
      this.$store.dispatch("getMeasurements")
    ])

    this.$store.dispatch("getKPIs", { queueWork: true }).then(() => {
      if (this.kpi) this.setKpiToData(this.kpi)
    })
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.id) {
      this.measurement = 0
      this.in_tonnes = false
      this.inner_city_ambition = ""
      this.outer_city_ambition = ""
      this.inner_city_reference = ""
      this.outer_city_reference = ""
      this.usecase = 0

      if (this.$v) this.$v.$reset()

      return next()
    }

    const kpi = this.kpis.find(({ kpi_id }) => kpi_id === Number(to.params.id))
    if (kpi) this.setKpiToData(kpi)

    next()
  }
})
</script>

<style lang="scss">
.autocomplete__box {
  border: none !important;
}
.autocomplete__icon {
  display: none;
}
</style>
