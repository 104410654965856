<template>
  <div>
    <h3 slot="header">
      {{
        this.$route.params.slug ? "Use case wijzigen" : "Voeg een use case toe"
      }}
    </h3>
    <div
      slot="body"
      :class="gettingUsecaseList || savingUsecase ? 'loading-form' : ''"
    >
      <div class="from-horizontal mt-2">
        <div class="field">
          <label>Use case naam</label>
          <input
            type="text"
            placeholder="Vul use case naam in..."
            v-model="name"
          />
          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam in
          </p>
        </div>

        <div class="field">
          <label>Project</label>
          <select v-model="project">
            <option disabled selected="selected">Kies project...</option>
            <option
              v-for="project in projects"
              :value="project.project_id"
              :key="project.slug"
              >{{ project.name }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.project.$error">
            Selecteer een project
          </p>
        </div>

        <div class="field">
          <label>Organisatie</label>
          <v-select
            :options="orgs"
            :value="selectedOrgs"
            @input="updateOrg"
            label="name"
            multiple
            placeholder="Kies organisatie..."
          ></v-select>

          <p class="error-message" v-if="$v.$dirty && $v.org.$error">
            Selecteer op zijn minst één organisatie
          </p>
        </div>

        <div class="field">
          <label>Stad</label>
          <select v-model="city">
            <option disabled selected="selected">Kies stad...</option>
            <option
              v-for="(city, index) in cities"
              :value="city.city_id"
              :key="index"
              >{{ city.name }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.city.$error">
            Selecteer een stad
          </p>
        </div>
      </div>

      <div class="from-horizontal mt-2">
        <div class="field">
          <label>Begindatum</label>
          <flat-pickr
            v-model="start_date"
            :config="datepickrConfig"
            class="flatpickr"
            placeholder="Select date"
            style="width: 135px;"
            name="start_date"
          >
          </flat-pickr>

          <p class="error-message" v-if="$v.$dirty && $v.start_date.$error">
            Selecteer een startdatum
          </p>
        </div>

        <div class="field">
          <label>Einddatum</label>
          <flat-pickr
            v-model="end_date"
            :config="{ ...datepickrConfig, minDate: this.start_date }"
            class="flatpickr"
            placeholder="Select date"
            style="width: 135px;"
            name="end_date"
          >
          </flat-pickr>

          <p class="error-message" v-if="$v.$dirty && $v.end_date.$error">
            Selecteer een einddatum
          </p>
        </div>
      </div>

      <div class="readable-width mt-2">
        <div class="field">
          <label>GPS coördinaten</label>
          <div class="shared">
            <div>
              <input type="text" v-model.number="latitude" />
              <label>Breedtegraad</label>
            </div>
            <div>
              <input type="text" v-model.number="longitude" />
              <label>Lengtegraad</label>
            </div>
          </div>
        </div>
        <div class="field mt-2">
          <label>Beschrijving</label>
          <textarea
            placeholder="Vul beschrijving in..."
            v-model="description"
            rows="3"
          />
        </div>

        <div class="field">
          <label>Coverfoto</label>
          <UploadFile
            label="Sleep of selecteer een coverfoto..."
            fileTypes="image/jpeg, image/png, image/gif"
            :file="photo"
            aspectRatio="2:1"
            type="image"
            crop="1200x600"
            :onUpload="onUpdateFileUpload('photo')"
            resource="usecases"
          />
        </div>

        <div class="field">
          <label>Extra bestanden</label>
          <UploadFile
            label="Sleep of selecteer extra bestanden of afbeeldingen..."
            :file="documentation"
            maxDimensions="1200"
            aspectRatio="1:1"
            type="file"
            crop="1200x600"
            :onUpload="onUpdateFileUpload('documentation')"
            resource="usecases"
          />
        </div>
      </div>
      <button @click="save" :disabled="savingUsecase">Opslaan</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import flatPickr from "vue-flatpickr-component"
import vSelect from "vue-select"
import {
  required,
  numeric,
  minLength,
  minValue
} from "vuelidate/lib/validators"

import { properName, isDate } from "../../../lib/validators"
import { API_URI } from "../../../config/env"
import UploadFile from "../../../components/UploadFile.vue"

import "flatpickr/dist/flatpickr.css"
import "vue-select/dist/vue-select.css"

import { formatDateNicely } from "../../../lib/helpers.js"

export default Vue.extend({
  data() {
    return {
      name: null,
      org: [],
      city: null,
      project: null,
      description: null,
      start_date: null,
      end_date: null,
      photo: null,
      documentation: null,
      latitude: null,
      longitude: null,

      datepickrConfig: {}
    }
  },

  validations: {
    name: { required, properName, minLength: minLength(2) },
    city: { required, numeric, minValue: minValue(0) },
    project: { required, numeric, minValue: minValue(0) },
    start_date: { required, isDate },
    end_date: { required, isDate },
    org: { minLength: 1, required, $each: { required, minValue: minValue(0) } }
  },

  computed: {
    ...mapGetters([
      "gettingUsecaseList",
      "gettingOrgList",
      "gettingCityList",
      "savingUsecase"
    ]),
    usecases() {
      return this.$store.state.usecases
    },
    orgs() {
      return this.$store.state.orgs
    },
    cities() {
      return this.$store.state.cities
    },
    projects() {
      return this.$store.state.projects
    },
    usecase() {
      if (!this.usecases) return
      return this.usecases.find(({ slug }) => slug === this.$route.params.slug)
    },
    slug() {
      return this.$route.params.slug
    },
    selectedOrgs() {
      if (!this.org || this.org.length < 1) return []
      return this.orgs.filter(
        ({ organisation_id }) => this.org.indexOf(organisation_id) > -1
      )
    },
    photoFile() {
      if (!this.photo) return
      return [
        {
          source: this.photo,
          options: { type: "local" }
        }
      ]
    }
  },

  components: { flatPickr, UploadFile, vSelect },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getCities")
  },

  methods: {
    onUpdateFileUpload(field) {
      return filePath => (this[field] = filePath)
    },

    async save() {
      if (this.savingUsecase) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.usecase ? "updateUsecase" : "addUsecase"
      const payload = {
        name: this.name,
        description: this.description,
        organisations: this.org,
        start_date: this.start_date,
        end_date: this.end_date,
        photo: this.photo,
        city: this.city,
        project: this.project,
        documentation: this.documentation,
        longitude: this.longitude,
        latitude: this.latitude
      }
      if (this.usecase) payload.usecase_id = this.usecase.usecase_id

      await this.$store.dispatch(action, payload)
      this.$router.push("/admin/usecases")
    },

    updateOrg(newValue) {
      this.org = newValue.map(({ organisation_id }) => organisation_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getCities")
    this.$store.dispatch("getProjects")

    this.$store.dispatch("getUsecases", { queueWork: true }).then(() => {
      if (this.usecase) {
        this.name = this.usecase.name
        this.org = this.usecase.organisations
        this.city = this.usecase.city
        this.project = this.usecase.project
        this.photo = this.usecase.photo
        this.start_date = new Date(this.usecase.start_date)
        this.end_date = new Date(this.usecase.end_date)
        this.description = this.usecase.description
        this.documentation = this.usecase.documentation
        this.longitude = this.usecase.longitude
        this.latitude = this.usecase.latitude
      }

      if (this.$v) this.$v.$reset()
    })
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null
      this.org = null
      this.city = null
      this.project = null
      this.start_date = null
      this.end_date = null
      this.description = null
      this.photo = null
      this.documentation = null
      this.longitude = null
      this.latitude = null

      if (this.$v) this.$v.$reset()

      return next()
    }

    const usecase = this.usecases.find(({ slug }) => slug === to.params.slug)

    if (usecase) {
      this.name = usecase.name
      this.org = usecase.organisations
      this.city = usecase.city
      this.project = usecase.project
      this.start_date = new Date(usecase.start_date)
      this.end_date = new Date(usecase.end_date)
      this.description = usecase.description
      this.photo = usecase.photo
      this.documentation = usecase.documentation
      this.longitude = usecase.longitude
      this.latitude = usecase.latitude

      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>
