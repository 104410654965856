export default {
  userOrg({ orgs, user }) {
    return user && orgs.length > 0
      ? orgs.find(
          ({ organisation_id }) => organisation_id === user.organisation
        )
      : null
  },

  gettingOrgList(state) {
    return state.currentWork.indexOf("getOrgs") > -1
  },

  savingOrg(state) {
    return (
      state.currentWork.indexOf("addOrg") > -1 ||
      state.currentWork.indexOf("updateOrg") > -1
    )
  }
}
