export default {
  gettingMeasurementList(state) {
    return state.currentWork.indexOf("getMeasurements") > -1
  },

  savingMeasurement(state) {
    return (
      state.currentWork.indexOf("addMeasurement") > -1 ||
      state.currentWork.indexOf("updateMeasurement") > -1
    )
  }
}
