import { isObject } from "../lib/helpers"

export default {
  getOrg(state, orgData) {
    if (!state.authToken) return

    const loadedOrgs = state.orgs.map(({ organisation_id }) => organisation_id)
    const newOrgs = []

    if (Array.isArray(orgData)) {
      for (const newOrg of orgData) {
        if (loadedOrgs.indexOf(newOrg.organisation_id) === -1)
          newOrgs.push(newOrg)
      }
    } else if (isObject(orgData)) {
      if (loadedOrgs.indexOf(orgData.organisation_id) === -1)
        newOrgs.push(orgData)
    }

    state.orgs = state.orgs.concat(newOrgs)
  },

  addOrg(state, org) {
    state.orgs.push(org)
  },

  updateOrg(state, updatedOrg) {
    state.orgs = state.orgs.map(org =>
      Number(org.organisation_id) === Number(updatedOrg.organisation_id)
        ? { ...org, ...updatedOrg }
        : org
    )
  },

  deleteOrg(state, deletedOrgId) {
    state.orgs = state.orgs.filter(
      ({ organisation_id }) => deletedOrgId !== organisation_id
    )
  }
}
