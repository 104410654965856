<template>
  <div>
    <p class="loading-message" v-if="gettingUsecaseList">
      Use case aan het laden...
    </p>

    <div v-else>
      <p v-show="showUploadCompleteMessage" class="notification success">
        Your CSV has been successfully uploaded!
      </p>
      <div
        v-for="usecase of usecases"
        :key="usecase.slug"
        class="upload-data-box"
      >
        <div>
          <h2>{{ usecase.name }}</h2>
          <p class="mb-0" v-if="usecase.last_journey_date">
            De laatste data voor {{ usecase.name }} is van
            {{ usecase.last_journey_date }}.
          </p>
          <p class="mb-0" v-else>
            Er is nog geen data voor {{ usecase.name }}. Klik op
            <router-link :to="{ path: `/data/import/${usecase.slug}` }"
              >upload data</router-link
            >
            om data te uploaden.
          </p>
        </div>

        <div class="actions">
          <p>
            <router-link :to="{ path: `/data/import/${usecase.slug}` }"
              >Upload data</router-link
            >
          </p>
          <p><small>Vervangt huidige data</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  data() {
    return {}
  },

  computed: {
    ...mapGetters(["gettingUsecaseList", "currentUser"]),

    showUploadCompleteMessage() {
      return (
        this.$route.query &&
        [true, "true"].indexOf(this.$route.query.upload) > -1
      )
    },

    usecases() {
      return this.$store.state.usecases.filter(
        ({ organisations }) =>
          organisations &&
          organisations.indexOf(this.currentUser.organisation) > -1
      )
    },

    usecase() {
      if (!this.usecases) return
      return this.usecases.find(({ slug }) => slug === this.$route.params.slug)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
  }
})
</script>

<style lang="scss" scoped>
.upload-data-box {
  border: 1px solid $muted;
  padding: 2em;
  display: flex;
  margin-bottom: 2em;
  align-items: "flex-end";

  .actions {
    flex: 0 0 auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
    }
    small {
      font-size: 12px;
    }
  }

  h2 {
    color: $tno-blue;
    font-size: 1.1em;
  }
}
</style>
