<template>
  <div v-if="hasData">
    <div id="container">
      <!-- Leverancier -->
      <div>
        <div class="left">
          <flow-chart-labels
            :journeys="journeys"
            trajectory="supplier-regiohub"
            :trajectories="[
              'supplier-regiohub',
              'supplier-cityhub',
              'supplier-destination'
            ]"
            :first="true"
          ></flow-chart-labels>
          <flow-chart-stripes
            :journeys="journeys"
            :vertical="[
              'supplier-cityhub',
              'supplier-regiohub',
              'supplier-destination'
            ]"
            position="left"
          />
        </div>
      </div>

      <!-- Regiohub -->
      <div style="height: 100px;" v-if="showHub('regio')">
        <div class="left">
          <flow-chart-corner
            :journeys="journeys"
            :vertical="['supplier-cityhub', 'supplier-destination']"
            :horizontal="['supplier-regiohub']"
            position="left"
          ></flow-chart-corner>
        </div>
        <div class="middle">
          <div class="hub">
            <img src="/images/hub.svg" alt="Hub" />
            (regio)hub
          </div>
          <flow-chart-stripes
            :journeys="journeys"
            :horizontal="['supplier-regiohub']"
            position="middle-left"
          ></flow-chart-stripes>
          <flow-chart-stripes
            :journeys="journeys"
            :horizontal="['regiohub-destination']"
            position="middle-right"
          ></flow-chart-stripes>
        </div>
        <div class="right">
          <flow-chart-corner
            :journeys="journeys"
            :horizontal="['regiohub-destination']"
            position="right"
            :straight="false"
          ></flow-chart-corner>
        </div>
      </div>

      <!-- Between regiohub and cityhub -->
      <div style="min-height: 5rem;">
        <div class="left">
          <flow-chart-labels
            :style="
              showHub('regio') && !showHub('city')
                ? 'padding-top: 3rem;'
                : 'padding-top: 2rem; padding-bottom: 2rem;'
            "
            :journeys="journeys"
            trajectory="supplier-cityhub"
            :trajectories="['supplier-cityhub', 'supplier-destination']"
          ></flow-chart-labels>
          <flow-chart-stripes
            :journeys="journeys"
            :vertical="['supplier-cityhub', 'supplier-destination']"
            position="left"
          ></flow-chart-stripes>
        </div>
        <div class="middle"></div>
        <div class="right">
          <flow-chart-labels
            :style="
              showHub('regio') && !showHub('city')
                ? 'padding-top: 3rem;'
                : 'padding-top: 2rem; padding-bottom: 2rem;'
            "
            :journeys="journeys"
            :trajectories="['regiohub-destination']"
            :right="true"
          ></flow-chart-labels>
          <flow-chart-stripes
            :journeys="journeys"
            :vertical="['regiohub-destination']"
            position="right"
          ></flow-chart-stripes>
        </div>
      </div>

      <!-- Cityhub -->
      <div style="height: 100px;" v-if="showHub('city')">
        <div class="left">
          <flow-chart-corner
            :journeys="journeys"
            :vertical="['supplier-destination']"
            :horizontal="['supplier-cityhub']"
            position="left"
          ></flow-chart-corner>
        </div>
        <div class="middle">
          <div class="hub">
            <img src="/images/hub.svg" alt="Hub" />
            cityhub
          </div>
          <flow-chart-stripes
            :journeys="journeys"
            :horizontal="['supplier-cityhub']"
            position="middle-left"
          ></flow-chart-stripes>
          <flow-chart-stripes
            :journeys="journeys"
            :horizontal="['cityhub-destination']"
            position="middle-right"
          ></flow-chart-stripes>
        </div>
        <div class="right">
          <flow-chart-corner
            :journeys="journeys"
            :vertical="['regiohub-destination']"
            :horizontal="['cityhub-destination']"
            position="right"
          ></flow-chart-corner>
        </div>
      </div>

      <!-- Between cityhub and destination -->
      <div>
        <div class="left">
          <flow-chart-labels
            :journeys="journeys"
            :trajectories="['supplier-destination']"
          ></flow-chart-labels>
          <flow-chart-stripes
            :journeys="journeys"
            :vertical="['supplier-destination']"
            position="left"
          ></flow-chart-stripes>
        </div>
        <div class="middle"></div>
        <div class="right">
          <flow-chart-labels
            :journeys="journeys"
            trajectory="cityhub-destination"
            :trajectories="['regiohub-destination', 'cityhub-destination']"
            :right="true"
          ></flow-chart-labels>
          <flow-chart-stripes
            :journeys="journeys"
            :vertical="['regiohub-destination', 'cityhub-destination']"
            position="right"
          ></flow-chart-stripes>
        </div>
      </div>

      <!-- Destination -->
      <div style="height: 100px;">
        <div class="left">
          <flow-chart-corner
            :journeys="journeys"
            :horizontal="['supplier-destination']"
            position="left"
            :straight="false"
          ></flow-chart-corner>
        </div>
        <div class="middle">
          <flow-chart-stripes
            :journeys="journeys"
            :horizontal="['supplier-destination']"
            position="middle"
          ></flow-chart-stripes>
        </div>
        <div class="right">
          <div class="hub hub-destination">
            <span>{{ destination }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="mt-0">Er is geen rit data om de verkeersstromen te laten zien.</p>
  </div>
</template>

<script>
import { getStat } from "../lib/helpers"
import FlowChartLabels from "./FlowChartLabels.vue"
import FlowChartCorner from "./FlowChartCorner.vue"
import FlowChartStripes from "./FlowChartStripes.vue"

export default {
  name: "FlowChart",

  components: {
    FlowChartLabels,
    FlowChartCorner,
    FlowChartStripes
  },

  data() {
    return {}
  },

  props: ["journeys", "usecase", "city"],

  computed: {
    destination() {
      if (this.usecase && this.usecase.name) return this.usecase.name
      if (this.city && this.city.name) return this.city.name
      return "..."
    },

    hasData() {
      if (!this.journeys || this.journeys.length === 0) return false
      const found = this.journeys.find(({ count }) => count > 0)
      return !!found
    }
  },

  methods: {
    getStat(...props) {
      return getStat(this.journeys, ...props)
    },

    showHub(type) {
      return (
        this.journeys.filter(({ trajectory, count }) => {
          return trajectory.includes(type) && count > 0
        }).length > 0
      )
    }
  }
}
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  padding: 0.5rem;
  font-size: 0;
  line-height: 0;

  // Rows
  & > div {
    width: 100%;
    display: flex;
    position: relative;
  }
  // Tiles with svgs of stripes and corners
  & > div > div {
    flex: 0 0 100px;
    display: flex;
    position: relative;
  }
  .middle {
    flex: 1 0 auto;
    svg {
      width: 100%;
    }
  }
  .right {
    justify-content: flex-end;
    &.hub {
      right: -0.5rem;
      left: unset;
      transform: none;
    }
  }
  .label-hub,
  .hub {
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    color: black;
    width: 110px;
    img {
      margin-bottom: 0.5rem;
      max-height: 50px;
    }
  }
  .label-hub {
    margin-bottom: 2rem;
    height: 50px;
  }
  .hub {
    height: 100px;
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    width: 120px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: $medium-width) {
      width: 100px;
    }
    &.hub-destination {
      width: 120px;
    }
  }
}

ul.labels-list li,
.label-hub,
.hub {
  font-size: 15px;
  line-height: 15px;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
