<template>
  <section :class="loading || loadingTraffic ? 'loading-chart' : ''">
    <ExplanationText
      :kpis="explanationKpis"
      :hasAmbitions="hasAmbitions"
      appendix="Dit resulteert in onderstaande cijfers."
    ></ExplanationText>

    <div class="split">
      <nav-tabs>
        <ul>
          <li>
            <a
              :class="isActiveTab('inner') ? 'is-active' : ''"
              @click="currentTab = 'inner'"
            >
              Totaal binnen de stad
            </a>
          </li>
          <li>
            <a
              :class="isActiveTab('outer') ? 'is-active' : ''"
              @click="currentTab = 'outer'"
            >
              Totaal buiten de stad
            </a>
          </li>
          <li>
            <a
              :class="isActiveTab('total') ? 'is-active' : ''"
              @click="currentTab = 'total'"
            >
              Totaal
            </a>
          </li>
        </ul>
      </nav-tabs>
    </div>

    <div class="headings two-columns responsive">
      <div>
        <div class="data-header mt-2">
          <h3>Weg</h3>
          <p>Transport</p>
        </div>

        <div class="numbers-container">
          <div class="left">
            <p>Gerealiseerd</p>
            <h4>
              {{ getStat({ slug: "drives", type: "realised" }) }} rit{{
                getStat({ slug: "drives", type: "realised", format: false }) !==
                1
                  ? "delen"
                  : "deel"
              }}
            </h4>
          </div>

          <div class="right">
            <div class="row">
              <div
                v-if="
                  getStat({
                    slug: 'rides',
                    type: 'referenced',
                    boolean: true
                  }) && !hideKPIData
                "
              >
                <p>Referentie</p>
                <h4>
                  {{ getStat({ slug: "rides", type: "referenced", round: 0 }) }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  getStat({
                    slug: 'drives',
                    type: 'realised',
                    boolean: true
                  }) &&
                    getStat({
                      slug: 'rides',
                      type: 'referenced',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'drives',
                      type: 'realised'
                    })}) - Referentie (${getStat({
                      slug: 'rides',
                      type: 'referenced'
                    })}) =
              ${minus(
                getStat({ slug: 'drives', type: 'realised', format: false }),
                getStat({ slug: 'rides', type: 'referenced', format: false }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'drives',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'rides',
                          type: 'referenced',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "drives",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "rides",
                        type: "referenced",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'rides',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>Ambitie</p>
                <h4>
                  {{ getStat({ slug: "rides", type: "planned", round: 0 }) }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'drives',
                      type: 'realised',
                      boolean: true
                    }) &&
                    getStat({
                      slug: 'rides',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-if="
                    minus(
                      getStat({
                        slug: 'drives',
                        type: 'realised',
                        format: false
                      }),
                      getStat({ slug: 'rides', type: 'planned', format: false })
                    ) !== '?'
                  "
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'drives',
                      type: 'realised'
                    })}) - Ambitie (${getStat({
                      slug: 'rides',
                      type: 'planned'
                    })}) =
              ${minus(
                getStat({ slug: 'drives', type: 'realised', format: false }),
                getStat({ slug: 'rides', type: 'planned', format: false })
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'drives',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'rides',
                          type: 'planned',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "drives",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "rides",
                        type: "planned",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="numbers-container">
          <div class="left">
            <p>Gerealiseerd</p>
            <h4>
              {{ getStat({ slug: "kms-road", type: "realised", round: 0 }) }} km
            </h4>
          </div>

          <div class="right">
            <div class="row">
              <div
                v-if="
                  getStat({
                    slug: 'kms-road',
                    type: 'referenced',
                    boolean: true
                  }) && !hideKPIData
                "
              >
                <p>Referentie</p>
                <h4>
                  {{
                    getStat({ slug: "kms-road", type: "referenced", round: 0 })
                  }}
                </h4>
              </div>
              <div v-else></div>
              <div
                class="ta-r"
                v-if="
                  getStat({
                    slug: 'kms-road',
                    type: 'realised',
                    boolean: true
                  }) &&
                    getStat({
                      slug: 'kms-road',
                      type: 'referenced',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'kms-road',
                      type: 'realised'
                    })}) - Referentie (${getStat({
                      slug: 'kms-road',
                      type: 'referenced'
                    })}) =
              ${minus(
                getStat({ slug: 'kms-road', type: 'realised', format: false }),
                getStat({
                  slug: 'kms-road',
                  type: 'referenced',
                  format: false
                }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'kms-road',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'kms-road',
                          type: 'referenced',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "kms-road",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "kms-road",
                        type: "referenced",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>

            <div class="row">
              <div
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'kms-road',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>Ambitie</p>
                <h4>
                  {{ getStat({ slug: "kms-road", type: "planned", round: 0 }) }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'kms-road',
                      type: 'planned',
                      boolean: true
                    }) &&
                    getStat({
                      slug: 'kms-road',
                      type: 'realised',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-if="
                    minus(
                      getStat({
                        slug: 'kms-road',
                        type: 'realised',
                        format: false
                      }),
                      getStat({
                        slug: 'kms-road',
                        type: 'planned',
                        format: false
                      })
                    ) !== '?'
                  "
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'kms-road',
                      type: 'realised'
                    })}) - Ambitie (${getStat({
                      slug: 'kms-road',
                      type: 'planned'
                    })}) =
              ${minus(
                getStat({ slug: 'kms-road', type: 'realised', format: false }),
                getStat({ slug: 'kms-road', type: 'planned', format: false }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'kms-road',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'kms-road',
                          type: 'planned',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "kms-road",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "kms-road",
                        type: "planned",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          getStat({ slug: 'sails', type: 'realised', boolean: true }) ||
            getStat({ slug: 'kms-water', type: 'realised', boolean: true }) ||
            getStat({ slug: 'sails', type: 'planned', boolean: true }) ||
            getStat({ slug: 'kms-water', type: 'planned', boolean: true })
        "
      >
        <div class="data-header mt-2">
          <h3>Water</h3>
          <p>Transport</p>
        </div>

        <div class="numbers-container">
          <div class="left">
            <p>Gerealiseerd</p>
            <h4>
              {{ getStat({ slug: "sails", type: "realised" }) }} vaart{{
                getStat({
                  slug: "sails",
                  type: "realised",
                  format: false
                }) !== 1
                  ? "delen"
                  : "deel"
              }}
            </h4>
          </div>

          <div class="right">
            <div class="row">
              <div
                v-if="
                  getStat({
                    slug: 'sails',
                    type: 'referenced',
                    boolean: true
                  }) && !hideKPIData
                "
              >
                <p>Referentie</p>
                <h4>
                  {{ getStat({ slug: "sails", type: "referenced", round: 0 }) }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  getStat({
                    slug: 'sails',
                    type: 'realised',
                    boolean: true
                  }) &&
                    getStat({
                      slug: 'sails',
                      type: 'referenced',
                      boolean: true
                    })
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'sails',
                      type: 'realised'
                    })}) - Referentie (${getStat({
                      slug: 'sails',
                      type: 'referenced'
                    })}) =
              ${minus(
                getStat({ slug: 'sails', type: 'realised', format: false }),
                getStat({ slug: 'sails', type: 'referenced', format: false }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'sails',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'sails',
                          type: 'referenced',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "sails",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "sails",
                        type: "referenced",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'sails',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>Ambitie</p>
                <h4>
                  {{ getStat({ slug: "sails", type: "planned", round: 0 }) }}
                </h4>
              </div>
              <div v-else></div>
              <div
                class="ta-r"
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'sails',
                      type: 'realised',
                      boolean: true
                    }) &&
                    getStat({
                      slug: 'sails',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-if="
                    minus(
                      getStat({
                        slug: 'sails',
                        type: 'realised',
                        format: false
                      }),
                      getStat({ slug: 'sails', type: 'planned', format: false })
                    ) !== '?'
                  "
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'sails',
                      type: 'realised'
                    })}) - Ambitie (${getStat({
                      slug: 'sails',
                      type: 'planned'
                    })}) =
              ${minus(
                getStat({ slug: 'sails', type: 'realised', format: false }),
                getStat({ slug: 'sails', type: 'planned', format: false }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'sails',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'sails',
                          type: 'planned',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "sails",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "sails",
                        type: "planned",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
              <div v-else>
                <p>&nbsp;</p>
                <h4 class="">&nbsp;</h4>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="numbers-container">
          <div class="left">
            <div>
              <p>Gerealiseerd</p>
              <h4>
                {{
                  formatNumber(
                    getStat({
                      slug: "kms-water",
                      type: "realised",
                      format: false
                    }),
                    { round: 0 }
                  )
                }}
                km
              </h4>
            </div>
          </div>
          <div class="right">
            <div class="row">
              <div
                v-if="
                  getStat({
                    slug: 'kms-water',
                    type: 'referenced',
                    boolean: true
                  }) && !hideKPIData
                "
              >
                <p>Referentie</p>
                <h4>
                  {{
                    getStat({ slug: "kms-water", type: "referenced", round: 0 })
                  }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  getStat({
                    slug: 'kms-water',
                    type: 'realised',
                    boolean: true
                  }) &&
                    getStat({
                      slug: 'kms-water',
                      type: 'referenced',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'kms-water',
                      type: 'realised'
                    })}) - Referentie (${getStat({
                      slug: 'kms-water',
                      type: 'referenced'
                    })}) =
              ${minus(
                getStat({ slug: 'kms-water', type: 'realised', format: false }),
                getStat({
                  slug: 'kms-water',
                  type: 'referenced',
                  format: false
                }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'kms-water',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'kms-water',
                          type: 'referenced',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "kms-water",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "kms-water",
                        type: "referenced",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div
                v-if="
                  hasAmbitions &&
                    getStat({
                      slug: 'kms-water',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>Ambitie</p>
                <h4>
                  {{
                    getStat({ slug: "kms-water", type: "planned", round: 0 })
                  }}
                </h4>
              </div>
              <div
                class="ta-r"
                v-if="
                  getStat({
                    slug: 'kms-water',
                    type: 'realised',
                    boolean: true
                  }) &&
                    getStat({
                      slug: 'kms-water',
                      type: 'planned',
                      boolean: true
                    }) &&
                    !hideKPIData
                "
              >
                <p>&nbsp;</p>
                <h4
                  v-if="
                    minus(
                      getStat({
                        slug: 'kms-water',
                        type: 'realised',
                        format: false
                      }),
                      getStat({
                        slug: 'kms-water',
                        type: 'planned',
                        format: false
                      })
                    ) !== '?'
                  "
                  v-tooltip="
                    `Gerealiseerd (${getStat({
                      slug: 'kms-water',
                      type: 'realised'
                    })}) - Ambitie (${getStat({
                      slug: 'kms-water',
                      type: 'planned'
                    })}) =
              ${minus(
                getStat({ slug: 'kms-water', type: 'realised', format: false }),
                getStat({ slug: 'kms-water', type: 'planned', format: false }),
                { round: 2 }
              )}`
                  "
                  :class="
                    className(
                      getStat({
                        slug: 'kms-water',
                        type: 'realised',
                        format: false
                      }) -
                        getStat({
                          slug: 'kms-water',
                          type: 'planned',
                          format: false
                        })
                    )
                  "
                >
                  {{
                    minus(
                      getStat({
                        slug: "kms-water",
                        type: "realised",
                        format: false
                      }),
                      getStat({
                        slug: "kms-water",
                        type: "planned",
                        format: false
                      }),
                      { round: 0 }
                    )
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2nd navbar to toggle between visualisations -->
    <div class="split mt-4">
      <nav-tabs>
        <ul>
          <li>
            <a
              :class="isActiveVisual('flow') ? 'is-active' : ''"
              @click="currentVisual = 'flow'"
              >Verkeersstromen</a
            >
          </li>
          <li>
            <a
              :class="isActiveVisual('time') ? 'is-active' : ''"
              @click="currentVisual = 'time'"
              >Tijd</a
            >
          </li>
        </ul>
      </nav-tabs>
    </div>

    <!-- real visuals -->
    <div v-if="currentVisual === 'time'">
      <div class="two-columns responsive">
        <div>
          <div class="data-header">
            <h3
              v-tooltip="
                'Totale afstand (alle brandstoffen en binnen/buiten de stad)'
              "
            >
              Kilometers
            </h3>
            <p>Totale afstand</p>
          </div>
          <line-chart
            class="chart mt-1"
            :chartdata="kmStackChartData"
            :height="400"
            :legend="false"
            :stacked="true"
            showAxisLabels="x"
            :unit="'km'"
            :ymax="yLimit('kms')"
            v-if="hasChartData(kmStackChartData)"
          ></line-chart>
          <p v-else>Geen data.</p>
        </div>
        <div>
          <div class="data-header">
            <h3
              v-tooltip="
                'Totaal aantal rit- en vaartdelen (alle brandstoffen en binnen/buiten de stad)'
              "
            >
              Rit-/vaartdelen
            </h3>
            <p>Totaal aantal</p>
          </div>
          <line-chart
            class="chart mt-1"
            :chartdata="journeyStackChartData"
            :height="400"
            :legend="false"
            :stacked="true"
            showAxisLabels="x"
            :unit="'rit-/vaartdelen'"
            :ymax="yLimit('journeys')"
            v-if="hasChartData(journeyStackChartData)"
          ></line-chart>
          <p v-else>Geen data.</p>
        </div>
      </div>

      <ul class="mt-2 legend horizontal-list">
        <li>Vaartdelen binnen de stad</li>
        <li>Ritdelen binnen de stad</li>
        <li>Vaartdelen buiten de stad</li>
        <li>Ritdelen buiten de stad</li>
      </ul>
    </div>
    <div v-else>
      <div>
        <flow-chart
          class="chart"
          :journeys="journeys"
          :usecase="usecase"
          :city="city"
        ></flow-chart>
      </div>
    </div>

    <p class="description ta-r pr-0">
      <router-link class="muted" to="/page/tno-berekeningen"
        >Berekeningen</router-link
      >
    </p>
  </section>
</template>

<script>
import Vue from "vue"
import FlowChart from "./FlowChart.vue"
import LineChart from "./LineChart.vue"
import ExplanationText from "./ExplanationText.vue"
import NavTabs from "./NavTabs.vue"
import {
  ENGINE_TYPES,
  POLLUTING_ENGINE_TYPES,
  MEASUREMENT_MAPPINGS
} from "../config/env"
import { JOURNEYS_PLACEHOLDERS } from "../lib/placeholders"

import {
  datasetCanStack,
  hasChartData,
  mergeDatasets,
  produceStackDataset,
  reduceToIndex
} from "../lib/helpers"

import {
  endIndex,
  getGoalForWeeks,
  linearGrowthByPercent,
  startIndex
} from "../lib/calculators"

import { unitConvert, formatNumber } from "../lib/units"

export default Vue.extend({
  name: "TrafficStats",

  components: { FlowChart, NavTabs, LineChart, ExplanationText },

  data() {
    return {
      currentTab: "inner",
      currentVisual: "flow",
      showDetails: false,
      loadingTraffic: true,
      journeys: [],
      backgroundColors: ["#538CBD", "#093F5F", "#cf2576", "#73A00E"]
    }
  },

  props: [
    "loading",
    "week",
    "weekStart",
    "weekEnd",
    "data",
    "year",
    "kpis",
    "usecase",
    "city",
    "hasAmbitions",
    "project",
    "preciseWeeks",
    "hideKPIData"
  ],

  created() {
    this.getTraffic()
  },

  watch: {
    weekStart() {
      this.getTraffic()
    },
    weekEnd() {
      this.getTraffic()
    },
    year() {
      this.getTraffic()
    },
    city() {
      this.getTraffic()
    },
    usecase() {
      this.getTraffic()
    },
    project() {
      this.getTraffic()
    }
  },

  computed: {
    startIndex,
    endIndex,

    chartLoaded() {
      return (
        !!this.data &&
        !!this.kpis &&
        Object.keys(this.kpis).length > 0 &&
        !this.loading
      )
    },

    statsType() {
      if (this.week && this.usecase) return "usecase"
      if (this.year && this.city) return "city"
      return null
    },

    label() {
      if (this.currentTab === "inner") return "binnen de stad"
      if (this.currentTab === "outer") return "buiten de stad"
      if (this.currentTab === "total") return "totaal"
      return ""
    },

    showWaterSection() {
      return true
    },

    explanationKpis() {
      return [
        {
          label: `ritdelen (${this.label})`,
          actual: this.getStat({
            slug: "drives",
            type: "realised",
            format: false
          }),
          reference: this.getStat({
            slug: "rides",
            type: "referenced",
            format: false
          }),
          ambition: this.getStat({
            slug: "rides",
            type: "planned",
            format: false
          })
        },
        {
          label: `kilometers (${this.label})`,
          actual: this.getStat({
            slug: "kms-road",
            type: "realised",
            format: false
          }),
          reference: this.getStat({
            slug: "kms-road",
            type: "referenced",
            format: false
          }),
          ambition: this.getStat({
            slug: "kms-road",
            type: "planned",
            format: false
          })
        }
      ]
    },

    stackedLabels() {
      if (!this.chartLoaded) return []

      return Array.from(Array(this.endIndex + 1)).map((_, index) =>
        index === 0 ? `Week ${index + 1}` : index + 1
      )
    },

    kmStackChartData() {
      return {
        labels: this.stackedLabels,
        datasets: this.stackChart("kms")
      }
    },

    journeyStackChartData() {
      return {
        labels: this.stackedLabels,
        datasets: this.stackChart("journeys")
      }
    },

    goalTotals() {
      const totals = { kms: null, journeys: null }
      if (!this.chartLoaded) return totals

      const kmOnTheRoad = this.kpis["kms-on-the-road"]
        ? this.kpis["kms-on-the-road"].outer_city_ambition +
          this.kpis["kms-on-the-road"].inner_city_ambition
        : 0
      const kmOnTheWater = this.kpis["kms-on-the-water"]
        ? this.kpis["kms-on-the-water"].outer_city_ambition +
          this.kpis["kms-on-the-water"].inner_city_ambition
        : 0

      totals.kms = kmOnTheRoad + kmOnTheWater

      const ridesOnTheRoad = this.kpis["rides-on-the-road"]
        ? this.kpis["rides-on-the-road"].outer_city_ambition +
          this.kpis["rides-on-the-road"].inner_city_ambition
        : 0
      const sailsOnTheWater = this.kpis["sails-on-the-water"]
        ? this.kpis["sails-on-the-water"].outer_city_ambition +
          this.kpis["sails-on-the-water"].inner_city_ambition
        : 0

      totals.journeys = ridesOnTheRoad + sailsOnTheWater

      return totals
    },

    referenceTotals() {
      const totals = { kms: null, journeys: null }
      if (!this.chartLoaded) return totals
      totals.kms =
        this.getKPIFor({
          metric: "kms-road",
          where: "total",
          type: "reference"
        }) +
        this.getKPIFor({
          metric: "kms-water",
          where: "total",
          type: "reference"
        })
      totals.journeys =
        this.getKPIFor({
          metric: "rides",
          where: "total",
          type: "reference"
        }) +
        this.getKPIFor({
          metric: "sails",
          where: "total",
          type: "reference"
        })
      return totals
    },

    actualTotals() {
      const totals = { kms: null, journeys: null }
      if (!this.chartLoaded) return totals

      totals.kms = reduceToIndex(
        this.endIndex,
        [
          this.data.kms_driven_in_inner_city,
          this.data.kms_driven_in_outer_city,
          this.data.kms_sailed_in_inner_city,
          this.data.kms_sailed_in_outer_city
        ],
        this.startIndex
      )

      totals.journeys = reduceToIndex(
        this.endIndex,
        [
          mergeDatasets(
            this.data.drives_inner_city_biodiesel,
            this.data.drives_inner_city_diesel,
            this.data.drives_inner_city_electric,
            this.data.drives_inner_city_benzine
          ),
          mergeDatasets(
            this.data.drives_outer_city_biodiesel,
            this.data.drives_outer_city_diesel,
            this.data.drives_outer_city_electric,
            this.data.drives_outer_city_benzine
          ),
          mergeDatasets(
            this.data.sails_inner_city_biodiesel,
            this.data.sails_inner_city_diesel,
            this.data.sails_inner_city_electric,
            this.data.sails_inner_city_benzine
          ),
          mergeDatasets(
            this.data.sails_outer_city_biodiesel,
            this.data.sails_outer_city_diesel,
            this.data.sails_outer_city_electric,
            this.data.sails_outer_city_benzine
          )
        ],
        this.startIndex
      )

      return totals
    }
  },

  methods: {
    formatNumber,
    unitConvert,
    getGoalForWeeks,
    produceStackDataset,
    hasChartData,

    minus(first, second, { round } = {}) {
      if (first === "?" || second === "?") return "?"
      return this.formatNumber(first - second, { round })
    },

    getMeasurementKey(slug) {
      return Object.keys(MEASUREMENT_MAPPINGS).find(
        key => MEASUREMENT_MAPPINGS[key] === slug
      )
    },

    getKPIFor({ metric, where = "total", type = "ambition" }) {
      if (!this.chartLoaded) return 0
      const stat = this.getMeasurementKey(metric)

      if (!this.kpis[stat]) return 0

      const inner = this.kpis[stat][`inner_city_${type}`]
      const outer = this.kpis[stat][`outer_city_${type}`]

      if (where === "total")
        return Number.isNaN(inner + outer) ? 0 : inner + outer
      if (where === "inner") return inner
      if (where === "outer") return outer
    },

    getSegments(options) {
      const type =
        options.type === "sail"
          ? "sails"
          : options.type === "ride"
          ? "drives"
          : options.type === "bike"
          ? "drives"
          : options.type

      const trajectory = options.trajectory.replace(/-/g, "_")
      const human = options.human || options.type === "bike"

      const queries = human
        ? [`${type}_human_${trajectory}`]
        : options.electric
        ? [`${type}_electric_${trajectory}`]
        : POLLUTING_ENGINE_TYPES.map(
            engineType => `${type}_${engineType}_${trajectory}`
          )
      return options.data
        .map(segment => {
          return {
            name: segment.name,
            count: queries.reduce((total, query) => {
              return total + (segment[query] || 0)
            }, 0)
          }
        })
        .sort((left, right) =>
          typeof left === "string" &&
          typeof right === "string" &&
          left.toLowerCase() < right.toLowerCase()
            ? -1
            : 1
        )
    },

    getTotal(options) {
      return this.getSegments(options).reduce(
        (total, { count = 0 } = {}) => total + count,
        0
      )
    },

    processData(data) {
      this.journeys = JOURNEYS_PLACEHOLDERS.map(
        ({ type, trajectory, electric }) => {
          const segments = this.getSegments({
            data,
            type,
            trajectory,
            electric
          })
          const count = this.getTotal({
            data,
            type,
            trajectory,
            electric
          })
          return {
            type,
            trajectory,
            electric,
            segments,
            count
          }
        }
      )
    },

    stackChart(measure) {
      if (!this.chartLoaded) return []
      if (!this.data || Object.keys(this.data).length < 1) return []

      const datasets = []

      const unit = measure === "kms" ? "km" : "rit./vaart."

      const slices = this.endIndex - this.startIndex + 1

      if (this.hasAmbitions && !this.hideKPIData) {
        datasets.push({
          label: "Ambitie",
          borderColor: "#000000",
          borderWidth: 3,
          data: linearGrowthByPercent({
            slices,
            total: this.goalTotals[measure]
          }).map(({ y }) => ({ y, actual: "100%" })),
          borderDash: [3, 5],
          yAxisID: "kpiline",
          fill: false
        })
      }

      if (!this.hideKPIData)
        datasets.push({
          label: "Referentie",
          borderColor: "red",
          borderWidth: 3,
          data: linearGrowthByPercent({
            slices,
            total: this.referenceTotals[measure]
          }).map(({ y }) => ({
            y,
            actual: `${this.formatNumber(
              this.unitConvert(y, unit)
            )} ${unit} cumulatief`
          })),
          borderDash: [5, 3],
          yAxisID: "kpiline",
          fill: false
        })

      if (measure === "kms") {
        if (datasetCanStack(this.data.kms_sailed_in_inner_city))
          datasets.push({
            backgroundColor: this.backgroundColors[0],
            label: "Vaartdelen binnen stad",
            data: this.produceStackDataset(
              this.data.kms_sailed_in_inner_city,
              unit,
              this.startIndex,
              this.endIndex
            )
          })

        if (datasetCanStack(this.data.kms_driven_in_inner_city))
          datasets.push({
            backgroundColor: this.backgroundColors[1],
            label: "Ritdelen binnen de stad",
            data: this.produceStackDataset(
              this.data.kms_driven_in_inner_city,
              unit,
              this.startIndex,
              this.endIndex
            )
          })

        if (datasetCanStack(this.data.kms_sailed_in_outer_city))
          datasets.push({
            backgroundColor: this.backgroundColors[2],
            label: "Vaartdelen buiten stad",
            data: this.produceStackDataset(
              this.data.kms_sailed_in_outer_city,
              unit,
              this.startIndex,
              this.endIndex
            )
          })

        if (datasetCanStack(this.data.kms_driven_in_outer_city))
          datasets.push({
            backgroundColor: this.backgroundColors[3],
            label: "Ritdelen buiten stad",
            data: this.produceStackDataset(
              this.data.kms_driven_in_outer_city,
              unit,
              this.startIndex,
              this.endIndex
            )
          })
      } else {
        const drives_inner = mergeDatasets(
          ...ENGINE_TYPES.map(
            type => this.data[`drives_inner_city_${type}`]
          ).filter(set => !!set)
        )
        const drives_outer = mergeDatasets(
          ...ENGINE_TYPES.map(
            type => this.data[`drives_outer_city_${type}`]
          ).filter(set => !!set)
        )
        const sails_inner = mergeDatasets(
          ...ENGINE_TYPES.map(
            type => this.data[`sails_inner_city_${type}`]
          ).filter(set => !!set)
        )
        const sails_outer = mergeDatasets(
          ...ENGINE_TYPES.map(
            type => this.data[`sails_outer_city_${type}`]
          ).filter(set => !!set)
        )

        if (datasetCanStack(sails_inner))
          datasets.push({
            backgroundColor: this.backgroundColors[0],
            label: "Vaartdelen binnen stad",
            data: this.produceStackDataset(
              sails_inner,
              unit,
              this.startIndex,
              this.endIndex
            )
          })
        if (datasetCanStack(drives_inner))
          datasets.push({
            backgroundColor: this.backgroundColors[1],
            label: "Ritdelen binnen de stad",
            data: this.produceStackDataset(
              drives_inner,
              unit,
              this.startIndex,
              this.endIndex
            )
          })
        if (datasetCanStack(sails_outer))
          datasets.push({
            backgroundColor: this.backgroundColors[2],
            label: "Vaartdelen buiten stad",
            data: this.produceStackDataset(
              sails_outer,
              unit,
              this.startIndex,
              this.endIndex
            )
          })
        if (datasetCanStack(drives_outer))
          datasets.push({
            backgroundColor: this.backgroundColors[3],
            label: "Ritdelen buiten stad",
            data: this.produceStackDataset(
              drives_outer,
              unit,
              this.startIndex,
              this.endIndex
            )
          })
      }

      return datasets
    },

    async getTraffic() {
      if (
        !this.statsType ||
        (!this.usecase && !this.city) ||
        (this.city && !this.year)
      ) {
        return
      }

      // Clear array here because it will otherwise stay filled when changing year
      // https://ci.tno.nl/gitlab/STL/construction/monitoring-tool/issues/112
      this.journeys = []

      this.loadingTraffic = true
      switch (this.statsType) {
        case "usecase": {
          const data = await this.$store.dispatch(
            "getUsecaseTraffic",
            this.usecase
          )
          if (data && data.length) this.processData(data)
          break
        }
        case "city": {
          const trafficDataParams = {
            city_id: this.city.city_id,
            start_date: `${this.year}-01-01`,
            end_date: `${this.year}-12-31`
          }

          if (this.project !== "all")
            trafficDataParams.project_id = this.project.project_id

          const data = await this.$store.dispatch(
            "getCityTraffic",
            trafficDataParams
          )
          if (data && data.length) this.processData(data)
        }
      }
      this.loadingTraffic = false
    },

    isActiveTab(name) {
      return this.currentTab === name
    },

    isActiveVisual(name) {
      return this.currentVisual === name
    },

    toggleDetails() {
      this.showDetails = !this.showDetails
    },

    className(number) {
      if (number > 0) return "positive"
      if (number < 0) return "negative"
      return ""
    },

    getStat({
      slug,
      where: customWhere,
      type,
      format = true,
      round = 2,
      boolean = false
    }) {
      if (!this.endIndex || !this.currentTab) return boolean ? false : 0
      const selectedWhere = customWhere ? customWhere : this.currentTab
      const wheres =
        selectedWhere === "total" ? ["inner", "outer"] : [selectedWhere]

      // If type is realised get data from this.data
      if (type === "realised") {
        if (slug === "kms-road") slug = "kms_driven_in"
        if (slug === "kms-water") slug = "kms_sailed_in"

        // Append fuel types to fields when it's not kms
        const fields = []

        wheres.forEach(where => {
          const key = `${slug}_${where}_city`
          if (slug.startsWith("kms_")) fields.push(this.data[key])
          for (const engineType of ENGINE_TYPES)
            fields.push(this.data[`${key}_${engineType}`])
        })

        const number = reduceToIndex(this.endIndex, fields, this.startIndex)
        return format ? this.formatNumber(number, { round }) : number

        // If type is planned or referenced get data from this.goals
      } else if (type === "planned" || type === "referenced") {
        const prop = this.getMeasurementKey(slug)

        if (!prop) return boolean ? false : "?"

        let total = 0
        let hasNullValues = false

        wheres.forEach(where => {
          const key = `${where}_city_${
            type === "planned" ? "ambition" : "reference"
          }`
          if (this.kpis[prop] && this.kpis[prop][key]) {
            total += this.kpis[prop][key]
          } else {
            hasNullValues = true
          }
        })

        if (hasNullValues) return boolean ? false : NaN

        if (total)
          return boolean
            ? true
            : format
            ? this.formatNumber(total, { round })
            : total
        return boolean ? false : "?"
      } else {
        return boolean ? false : "?"
      }
    },

    yLimit(gas) {
      if (!this.chartLoaded) return

      return [
        this.actualTotals[gas],
        this.goalTotals[gas],
        this.referenceTotals[gas]
      ].sort((a, b) => b - a)[0]
    }
  }
})
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 0;
}
.numbers-container {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-rows: auto;
  p {
    margin-bottom: 3px;
  }
  .left {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .right {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;

    > div {
      flex: 0 0 50%;
    }

    div:last-of-type {
      text-align: right;
    }
  }
}
</style>
