<template>
  <section :class="chartLoaded ? '' : 'loading-chart'">
    <ExplanationText
      :kpis="explanationKpis"
      :hasAmbitions="hasAmbitions"
      appendix="Hieronder vindt u de verdeling van emissies over typen ritten (en vaarten)."
    ></ExplanationText>

    <MapView
      id="map"
      v-if="activeVisual === 'map'"
      :year="year"
      :data="data"
      :kpis="kpis"
      :measurements="measurements"
      :activeStat="activeStat"
      :usecases="usecases"
    >
    </MapView>
  </section>
</template>

<script>
import Vue from "vue"

import MapView from "./Map.vue"
import NavTabs from "./NavTabs.vue"
import ExplanationText from "./ExplanationText.vue"
import { reduceToIndex } from "../lib/helpers"
import { unitConvert, formatNumber } from "../lib/units"
import { endIndex } from "../lib/calculators"

export default Vue.extend({
  name: "ClimateMapStats",

  components: { MapView, NavTabs, ExplanationText },

  data() {
    return { activeStat: null }
  },

  props: [
    "activeVisual",
    "data",
    "goals",
    "hasAmbitions",
    "kpis",
    "measurements",
    "showMap",
    "usecases",
    "weekEnd",
    "year",
    "hideKPIData"
  ],

  computed: {
    endIndex,

    explanationKpis() {
      if (!this.chartLoaded || !this.chartableData) return []

      return [
        {
          label: "CO<sub>2</sub>",
          actual: this.chartableData.co2 && this.chartableData.co2.ytdValue,
          reference:
            this.chartableData.co2 && this.chartableData.co2.ytdReference,
          ambition: this.chartableData.co2 && this.chartableData.co2.ytdAmbition
        },
        {
          label: "PM<sub>10</sub>",
          actual: this.chartableData.pm10 && this.chartableData.pm10.ytdValue,
          reference:
            this.chartableData.pm10 && this.chartableData.pm10.ytdReference,
          ambition:
            this.chartableData.pm10 && this.chartableData.pm10.ytdAmbition
        },
        {
          label: "NO<sub>x</sub>",
          actual: this.chartableData.nox && this.chartableData.nox.ytdValue,
          reference:
            this.chartableData.nox && this.chartableData.nox.ytdReference,
          ambition: this.chartableData.nox && this.chartableData.nox.ytdAmbition
        }
      ]
    },

    chartLoaded() {
      return !!this.data && !!this.kpis && Object.keys(this.kpis).length > 0
    },

    chartableData() {
      if (!this.chartLoaded) return { co2: {}, pm10: {}, nox: {} }
      const stats = {}

      for (const stat of ["co2", "nox", "pm10"]) {
        const kpi = `${stat}-in-grams`
        if (!this.kpis[kpi]) continue

        // ytd = year-to-date
        stats[stat] = {
          unit: stat === "co2" ? "ton" : "kg",
          ytdValue: reduceToIndex(this.endIndex, [
            this.data[`${stat}_inner_city_drives`],
            this.data[`${stat}_outer_city_drives`],
            this.data[`${stat}_inner_city_sails`],
            this.data[`${stat}_outer_city_sails`]
          ]),
          ytdReference:
            (this.kpis[kpi].inner_city_reference || 0) +
            (this.kpis[kpi].outer_city_reference || 0),
          ytdAmbition:
            (this.kpis[kpi].inner_city_ambition || 0) +
            (this.kpis[kpi].outer_city_ambition || 0)
        }
      }

      return stats
    }
  },

  mounted() {
    if (!this.activeStat) this.activeStat = Object.keys(this.chartableData)[0]
  }
})
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.split.one-two {
  align-items: flex-start;
  flex-wrap: nowrap;
  @media (max-width: $medium-width) {
    flex-wrap: wrap;
  }

  & > div:first-of-type {
    flex: 0 0 300px;
    @media (max-width: $medium-width) {
      flex: 1 1 100%;
    }
  }
  & > div:last-of-type {
    flex: 1 1 auto;
    margin-left: 2rem;
    h4 {
      color: $tno-blue;
      font-size: 20px;
      margin-top: 1.5rem;
    }
    p {
      font-size: 0.9em;
      color: #8a8a8a;
    }
    @media (max-width: $medium-width) {
      flex: 1 1 100%;
      margin-left: 0;
    }
  }
}
#map {
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media (max-width: $medium-width) {
    margin-top: 0;
  }
}
nav {
  margin: 1.5rem 0 2rem;
  li {
    font-size: 25px;
  }
  sup,
  sub {
    font-size: 15px;
    margin-left: 1px;
  }
}
.legend {
  li:nth-of-type(2)::before {
    background-color: #c26493;
  }
  li:nth-of-type(1)::before {
    background-color: #4a4a4f;
  }
  li.co2::before {
    background-color: #fdc108;
  }
  li.pm10::before {
    background-color: #c10363;
  }
  li.nox::before {
    background-color: #53bd8b;
  }
}
</style>
