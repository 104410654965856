export default {
  gettingCityList(state) {
    return state.currentWork.indexOf("getCities") > -1
  },

  gettingCityKPIData(state) {
    return state.currentWork.includes("getCityKPIData")
  },

  savingCity(state) {
    return (
      state.currentWork.indexOf("addCity") > -1 ||
      state.currentWork.indexOf("updateCity") > -1
    )
  }
}
