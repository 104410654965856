import { helpers } from "vuelidate/lib/validators"

export const properName = helpers.regex(
  "properName",
  /^[a-zA-Z0-9-'"&+./\\ ]+$/
)

export const kpiValue = value =>
  value === "" || value === null || (typeof value === "number" && value >= 0)

export const isCSVu = value =>
  value instanceof File &&
  (value.type === "text/csv" || value.name.slice(-4).toLowerCase() === ".csv")

export const isSlug = value => /^[a-z0-9_-]+$/.test(value)

export const oneOf = (set = []) => value => value && set.indexOf(value) > -1

export const decimalComma = number => /^[-]?\d*(,\d+)?$/.test(number)

export const isDate = value =>
  !!value &&
  (value instanceof Date ||
    /^([0-9]{4})(-|\/{1})([0-9]{2})(-|\/{1})([0-9]{2})$/i.test(value))
