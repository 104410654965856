<template>
  <div>
    <p class="loading-message" v-if="gettingVehicleList">
      Voertuigen aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th />
          <th>Naam</th>
          <th>Rates (inner/outer)</th>
          <th>&nbsp;</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vehicle, index) in vehicles" :key="index">
          <td>
            <span v-if="vehicle.type === 'water'">⚓️</span>
            <span v-else-if="vehicle.type === 'road'">🚚</span>
          </td>
          <td>{{ vehicle.name }}</td>
          <td>
            <ul class="rate-list">
              <li>
                CO<sub>2</sub>:
                <code>{{ formatNumber(vehicle.co2_inner_rate) }}</code> /
                <code>{{ formatNumber(vehicle.co2_outer_rate) }}</code>
              </li>
              <li>
                PM<sub>10</sub>:
                <code>{{ formatNumber(vehicle.pm10_inner_rate) }}</code> /
                <code>{{ formatNumber(vehicle.pm10_outer_rate) }}</code>
              </li>
              <li>
                NO<sub>x</sub>:
                <code>{{ formatNumber(vehicle.nox_inner_rate) }}</code> /
                <code>{{ formatNumber(vehicle.nox_outer_rate) }}</code>
              </li>
            </ul>
          </td>
          <td class="ta-r actions">
            <a v-if="userIsAdmin">
              <router-link
                :to="{ path: `/admin/vehicles/update/${vehicle.slug}` }"
              >
                Wijzig
              </router-link>
            </a>
            <a v-if="userIsGod" v-on:click="deleteVehicle(vehicle)">
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingVehicle"
    >
      <h3 slot="header">Voeg vervoersmiddel toe</h3>
      <div
        slot="body"
        :class="gettingVehicleList || savingVehicle ? 'loading-form' : ''"
      >
        <div class="field">
          <label>Vervoersmiddel naam</label>
          <input
            type="text"
            placeholder="Vul vervoersmiddel naam in..."
            v-model="name"
          />

          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) vervoersmiddel naam in
          </p>
        </div>
        <div class="field">
          <label>Motor type</label>
          <select v-model="engine_type">
            <option disabled selected="selected">Kies motor type...</option>
            <option
              v-for="(engine, index) in engineTypes"
              :key="index"
              :value="engine"
              >{{ engine }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.engine_type.$error">
            Selecteer een motor type
          </p>
        </div>
        <div class="field">
          <label>Vervoermiddel type</label>
          <select v-model="type">
            <option disabled selected="selected"
              >Kies vervoersmiddel type...</option
            >
            <option
              v-for="(vehicleType, index) in vehicleTypes"
              :key="index"
              :value="vehicleType"
              >{{ vehicleType }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.type.$error">
            Selecteer een vervoersmiddel type
          </p>
        </div>
        <div class="field">
          <label>CO<sub>2</sub> emissie per km (kommanotatie)</label>
          <div class="shared">
            <div>
              <input type="text" v-model="co2_inner_rate" />
              <label>Binnen de stad</label>
            </div>
            <div>
              <input type="text" v-model="co2_outer_rate" />
              <label>Buiten de stad</label>
            </div>
          </div>
          <p
            class="error-message"
            v-if="
              $v.$dirty &&
                ($v.co2_inner_rate.$error || $v.co2_outer_rate.$error)
            "
          >
            Vul een getal in met een komma voor binnen én buiten de stad
          </p>

          <p></p>
        </div>

        <div class="field">
          <label>NO<sub>x</sub> emissie per km (kommanotatie)</label>
          <div class="shared">
            <div>
              <input type="text" v-model="nox_inner_rate" />
              <label>Binnen de stad</label>
            </div>
            <div>
              <input type="text" v-model="nox_outer_rate" />
              <label>Buiten de stad</label>
            </div>
          </div>
          <p
            class="error-message"
            v-if="
              $v.$dirty &&
                ($v.nox_inner_rate.$error || $v.nox_outer_rate.$error)
            "
          >
            Vul een getal in met een komma voor binnen én buiten de stad
          </p>

          <p></p>
        </div>

        <div class="field">
          <label>PM<sub>10</sub> emissie per km (kommanotatie)</label>
          <div class="shared">
            <div>
              <input type="text" v-model="pm10_inner_rate" />
              <label>Binnen de stad</label>
            </div>
            <div>
              <input type="text" v-model="pm10_outer_rate" />
              <label>Buiten de stad</label>
            </div>
          </div>
          <p
            class="error-message"
            v-if="
              $v.$dirty &&
                ($v.pm10_inner_rate.$error || $v.pm10_outer_rate.$error)
            "
          >
            Vul een getal in met een komma voor binnen én buiten de stad
          </p>

          <p></p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required, decimal, minLength } from "vuelidate/lib/validators"

import { properName, oneOf, decimalComma } from "../../lib/validators"
import { formatNumber } from "../../lib/units"
import Modal from "../../components/Modal.vue"
import { ENGINE_TYPES, VEHICLE_TYPES } from "../../config/env"

export default Vue.extend({
  data() {
    return {
      name: null,
      type: null,
      engine_type: null,
      co2_inner_rate: null,
      co2_outer_rate: null,
      pm10_inner_rate: null,
      pm10_outer_rate: null,
      nox_inner_rate: null,
      nox_outer_rate: null,

      engineTypes: ENGINE_TYPES,
      vehicleTypes: VEHICLE_TYPES
    }
  },

  validations: {
    name: { required, minLength: minLength(2) },
    co2_inner_rate: { required, decimalComma },
    co2_outer_rate: { required, decimalComma },
    pm10_inner_rate: { required, decimalComma },
    pm10_outer_rate: { required, decimalComma },
    nox_inner_rate: { required, decimalComma },
    nox_outer_rate: { required, decimalComma },
    type: { required },
    engine_type: { required }
  },

  components: { Modal },

  computed: {
    ...mapGetters([
      "gettingVehicleList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin",
      "savingVehicle"
    ]),
    vehicles() {
      return this.$store.state.vehicles.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },

    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.slug
    },

    vehicle() {
      if (!this.vehicles) return
      return this.vehicles.find(({ slug }) => slug === this.$route.params.slug)
    }
  },

  methods: {
    commaToDot(comma) {
      return `${comma}`.replace(/[^0-9,]+/g, "").replace(",", ".")
    },
    dotToComma(dot) {
      return `${dot}`.replace(".", ",")
    },

    async save() {
      if (this.savingVehicle) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.vehicle ? "updateVehicle" : "addVehicle"
      const payload = {
        name: this.name,
        type: this.type,
        engine_type: this.engine_type,
        co2_inner_rate: this.commaToDot(this.co2_inner_rate),
        co2_outer_rate: this.commaToDot(this.co2_outer_rate),
        pm10_inner_rate: this.commaToDot(this.pm10_inner_rate),
        pm10_outer_rate: this.commaToDot(this.pm10_outer_rate),
        nox_inner_rate: this.commaToDot(this.nox_inner_rate),
        nox_outer_rate: this.commaToDot(this.nox_outer_rate)
      }
      if (this.vehicle) payload.vehicle_id = this.vehicle.vehicle_id

      const error = await this.$store.dispatch(action, payload)
      if (!(error instanceof Error)) this.closeModal()
    },

    closeModal() {
      return this.$router.push("/admin/vehicles")
    },

    deleteVehicle({ vehicle_id, name }) {
      if (
        !confirm(
          `Weet je zeker dat je ${name} wilt verwijderen? Dit verwijderd ook alle gerelateerde ritten!`
        )
      )
        return

      this.$store.dispatch("deleteVehicle", vehicle_id)
    },

    formatNumber
  },

  beforeCreate() {
    this.$store.dispatch("getVehicles", { queueWork: true }).then(() => {
      if (this.vehicle) {
        this.name = this.vehicle.name
        this.type = this.vehicle.type
        this.engine_type = this.vehicle.engine_type
        this.co2_inner_rate = this.dotToComma(this.vehicle.co2_inner_rate)
        this.co2_outer_rate = this.dotToComma(this.vehicle.co2_outer_rate)
        this.pm10_inner_rate = this.dotToComma(this.vehicle.pm10_inner_rate)
        this.pm10_outer_rate = this.dotToComma(this.vehicle.pm10_outer_rate)
        this.nox_inner_rate = this.dotToComma(this.vehicle.nox_inner_rate)
        this.nox_outer_rate = this.dotToComma(this.vehicle.nox_outer_rate)
      }

      if (this.$v) this.$v.$reset()
    })
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null
      this.type = null
      this.engine_type = null
      this.co2_inner_rate = null
      this.co2_outer_rate = null
      this.pm10_inner_rate = null
      this.pm10_outer_rate = null
      this.nox_inner_rate = null
      this.nox_outer_rate = null

      if (this.$v) this.$v.$reset()

      return next()
    }

    const vehicle = this.vehicles.find(({ slug }) => slug === to.params.slug)

    if (vehicle) {
      this.name = vehicle.name
      this.type = vehicle.type
      this.engine_type = vehicle.engine_type
      this.co2_inner_rate = this.dotToComma(vehicle.co2_inner_rate)
      this.co2_outer_rate = this.dotToComma(vehicle.co2_outer_rate)
      this.pm10_inner_rate = this.dotToComma(vehicle.pm10_inner_rate)
      this.pm10_outer_rate = this.dotToComma(vehicle.pm10_outer_rate)
      this.nox_inner_rate = this.dotToComma(vehicle.nox_inner_rate)
      this.nox_outer_rate = this.dotToComma(vehicle.nox_outer_rate)

      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>

<style lang="scss" scoped>
.rate-list {
  font-size: 0.8em;
  list-style-type: none;

  li {
    margin-bottom: 4px;

    code {
      font-size: 1.1em;
    }
  }
}
</style>
