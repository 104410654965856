<template>
  <div :class="saving || loading ? 'loading-chart' : ''">
    <div class="from-horizontal">
      <div class="field">
        <label>Titel</label>
        <input
          type="text"
          v-model.trim="title"
          placeholder="Bijv. TNO berekeningen"
        />
        <p class="error-message" v-if="$v.$dirty && !$v.title.required">
          Vul een (geldige) titel in
        </p>
      </div>
      <div class="field" v-if="!hideUsecase">
        <label>Use case {{ hideUsecase }}</label>
        <select v-model="usecase">
          <option disabled>Kies een use case...</option>
          <option :value="null">Geen use case, algemene pagina</option>
          <option
            v-for="(usecase, index) in usecases"
            :key="index"
            :value="usecase.usecase_id"
            :selected="usecase === usecase.usecase_id ? 'selected' : ''"
            >{{ usecase.name }}</option
          >
        </select>
        <p class="error-message" v-if="$v.$dirty && $v.usecase.$error">
          Selecteer een use case
        </p>
      </div>
    </div>

    <tiptap v-model="content"></tiptap>

    <button v-if="saving" disabled>Opslaan</button>
    <button v-else @click="save">Opslaan</button>
  </div>
</template>

<script>
import Vue from "vue"
import Tiptap from "../../components/Tiptap.vue"
import { required, minLength, numeric } from "vuelidate/lib/validators"
import { isSlug } from "../../lib/validators"

export default Vue.extend({
  name: "PagesEdit",

  components: { Tiptap },

  data() {
    return {
      page_id: null,
      title: null,
      usecase: null,
      slug: null,
      content: null,
      loading: true,
      saving: false
    }
  },

  validations: {
    title: { required, minLength: minLength(2) },
    usecase: { numeric }
  },

  computed: {
    pages() {
      return this.$store.state.pages
    },
    usecases() {
      return this.$store.state.usecases
    },
    hideUsecases() {
      if (!this.slug) return false
      return this.slug === "tno-berekeningen"
    }
  },

  methods: {
    async save() {
      if (this.saving) return
      this.saving = true

      this.$v.$touch()
      if (this.$v.$invalid) return (this.saving = false)

      const payload = {
        page_id: this.page_id,
        title: this.title,
        content: this.content
      }

      if (!this.hideUsecase) payload.usecase = this.usecase

      const error = await this.$store.dispatch("updatePage", payload)
      this.saving = false
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getPages").then(() => {
      const page = this.pages.find(({ slug }) => slug === this.$route.params.id)
      if (!page) this.$router.push("/admin/pages")

      this.page_id = page.page_id
      this.title = page.title
      this.slug = page.slug
      this.usecase = page.usecase

      this.loading = false

      if (page.content) this.content = page.content
      else
        this.content = `
        <p>
          <b>Dit is een voorbeeld voor een pagina.</b>
        </p>
        <p>Je kan er ook formules in schrijven: <code>5 x 6 = 103 CO2</code></p>
        <ul>
          <li>En lijstjes</li>
          <li>met dingetjes</li>
        </ul>
        <p><img src="https://mijnimpact-adriaan-io.s3.amazonaws.com/1569939941622-walking.gif" alt=""></p>
        Super cool 👏
        <br />
        <em>– Mike, Chris & Adriaan</em>`
    })
  }
})
</script>

<style lang="scss" scoped></style>
