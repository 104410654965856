<script>
import Chart from "chart.js"
import { Doughnut } from "vue-chartjs"

import { unitConvert, formatNumber } from "../lib/units"

Chart.defaults.global.defaultFontColor = "#8a8a8a"
Chart.defaults.global.defaultFontFamily = "Helvetica, Arial, sans-serif"

export default {
  name: "DoughnutChart",

  extends: Doughnut,

  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 92,
        legend: { display: false },
        tooltips: {
          enabled: true,

          callbacks: {
            label: (tooltipItem, { labels, datasets, unit }) => {
              const dataset = datasets[tooltipItem.datasetIndex]
              const round = unit === "ton" ? 1 : 2
              let label = labels[tooltipItem.index]

              if (label) label += ": "

              const actual = formatNumber(
                unitConvert(dataset.data[tooltipItem.index], unit),
                { round }
              )
              label += typeof actual === "number" ? Math.round(actual) : actual

              return `${label} ${unit}`
            }
          }
        }
      }
    }
  },

  props: ["chartdata", "options"],

  mounted() {
    this.renderChart(this.chartdata, { ...this.chartOptions, ...this.options })
  }
}
</script>
