<template>
  <div>
    <p class="loading-message" v-if="gettingSegmentList">
      Logistieke segmenten aan het laden...
    </p>
    <table v-else>
      <thead>
        <tr>
          <th>Naam</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="segment in segments" :key="segment.name">
          <td>{{ segment.name }}</td>
          <td class="ta-r actions">
            <a v-if="userIsAdmin">
              <router-link
                :to="{ path: `/admin/segments/update/${segment.segment_id}` }"
                class="show-on-hover"
              >
                Wijzig
              </router-link>
            </a>
            <a v-if="userIsAdmin" v-on:click="deleteSegment(segment)"
              >Verwijder</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      v-if="creating || updating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="savingSegment"
    >
      <h3 slot="header" v-if="creating">Voeg een logistiek segment toe</h3>
      <h3 slot="header" v-if="updating">Wijzig logistiek segment</h3>
      <div
        slot="body"
        :class="gettingSegmentList || savingSegment ? 'loading-form' : ''"
      >
        <div class="field">
          <label>Naam logistiek segment</label>
          <input type="text" placeholder="Vul een naam in..." v-model="name" />
          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam in
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { required, minLength } from "vuelidate/lib/validators"

import { properName } from "../../lib/validators"
import Modal from "../../components/Modal.vue"

export default Vue.extend({
  name: "AdminSegments",

  data() {
    return {
      name: null
    }
  },

  validations: {
    name: { required, minLength: minLength(2), properName }
  },

  components: { Modal },

  computed: {
    ...mapGetters([
      "gettingSegmentList",
      "userHasScopeOver",
      "userIsGod",
      "userIsAdmin",
      "savingSegment"
    ]),
    segments() {
      return this.$store.state.segments.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },

    creating() {
      return this.$route.path.endsWith("/create")
    },
    updating() {
      return this.$route.params && this.$route.params.id
    },

    segment() {
      if (!this.segments) return
      return this.segments.find(
        ({ segment_id }) => segment_id === parseInt(this.$route.params.id)
      )
    }
  },

  methods: {
    async save() {
      if (this.savingSegment) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.segment ? "updateSegment" : "addSegment"

      const payload = { name: this.name }
      if (this.segment) payload.segment_id = this.segment.segment_id

      const error = await this.$store.dispatch(action, payload)
      if (!(error instanceof Error)) this.closeModal()
    },

    closeModal() {
      return this.$router.push("/admin/segments")
    },

    deleteSegment({ segment_id, name }) {
      if (!confirm(`Weet je zeker dat je ${name} wilt verwijderen?`)) return

      this.$store.dispatch("deleteSegment", segment_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getSegments", { queueWork: true }).then(() => {
      if (this.segment) {
        this.name = this.segment.name
      }
      if (this.$v) this.$v.$reset()
    })
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.id) {
      this.name = null
      if (this.$v) this.$v.$reset()
      return next()
    }

    const segment = this.segments.find(
      ({ segment_id }) => segment_id === parseInt(to.params.id)
    )
    if (segment) {
      this.name = segment.name
      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>

<style lang="scss" scoped></style>
