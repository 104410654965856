import { unitConvert, formatNumber } from "../lib/units"

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isObject = couldBeButIDunno =>
  couldBeButIDunno != null &&
  typeof couldBeButIDunno === "object" &&
  Array.isArray(couldBeButIDunno) === false

export const isValidEmail = email => EMAIL_REGEX.test(email)

export const isEmpty = text => !text || String(text).trim() === ""

export const generateSub = text =>
  text &&
  text
    .replace(/co2/gi, "CO<sub>2</sub>")
    .replace(/nox/gi, "NO<sub>x</sub>")
    .replace(/pm10/gi, "PM<sub>10</sub>")

// No app exists without leftPad (https://www.davidhaney.io/npm-left-pad-have-we-forgotten-how-to-program/)
export const leftPad = (string, length, filler = " ") => {
  if (length < (string + "").length) return string // Never make a string shorter
  return ((filler + "").repeat(length) + string).slice(-length)
}

export const formatDateNicely = rawDate => {
  if (!rawDate) return

  const dto = rawDate instanceof Date ? rawDate : new Date(rawDate)
  const month = dto.getUTCMonth() + 1

  return `${dto.getUTCFullYear()}-${leftPad(month, 2, "0")}-${leftPad(
    dto.getUTCDate(),
    2,
    "0"
  )}`
}

export const startOfYear = year => new Date(`${year}-01-01T00:00:00.000Z`)

export const weeksBetween = (
  start,
  end,
  { rounded = true, inclusive = true } = {}
) => {
  if (typeof start === "number") start = startOfYear(start)
  else if (!(start instanceof Date)) start = new Date(start)

  if (typeof end === "number") end = startOfYear(end)
  else if (!(end instanceof Date)) end = new Date(end)

  if (inclusive) end.setUTCDate(end.getUTCDate() + 1)

  // 6048e5 is one week in milliseconds (7 * 24 * 60 * 60 * 1000)
  const weekCount = (end - start) / 6048e5
  if (rounded) return Math.ceil(weekCount)

  return weekCount < 0 ? 0 : weekCount
}

export const reduceToIndex = (index = 0, sets = [], startAt = 0) =>
  Array.from(Array(index + 1)).reduce((total, _, currentIndex) => {
    if (currentIndex < startAt - 1) return total
    if (currentIndex >= index + 1) return total

    return (
      total +
      sets.reduce((sum, statSet) => {
        const curr =
          statSet && statSet[currentIndex] ? statSet[currentIndex] || 0 : 0
        return sum + curr
      }, 0)
    )
  }, 0)

export const mergeDatasets = (firstSet, ...sets) => {
  const len = firstSet ? firstSet.length : 0
  return [].concat(firstSet, ...sets).reduce((out, item, index, arr) => {
    const i = index % len
    out[i] = (out[i] || 0) + arr[index]
    return out
  }, [])
}

export const datasetCanStack = (set = []) =>
  set.reduce((total, el) => el + total, 0) > 0

// This function adds all weeks before the showing weeks to the first week
// and hides the weeks that we don't need
export const selectWeeksFromData = ({
  data,
  weekStart,
  weekEnd,
  accumulative = false
}) => {
  const newData = {}
  for (const key in data) {
    if (!Object.prototype.hasOwnProperty.call(data, key)) continue
    if (accumulative)
      newData[key] = data[key].reduce((prev, curr, index) => {
        const show = index >= weekStart && index < weekEnd
        if (show) prev.push(curr)
        else if (prev.length <= 1)
          prev[0] = typeof curr === "number" ? (prev[0] || 0) + curr : curr
        return prev
      }, [])
    else
      newData[key] = data[key].filter((item, index) => {
        return index >= weekStart - 1 && index < weekEnd
      })
  }
  return newData
}

export const produceStackDataset = (set, unit, startIndex, endIndex) => {
  let runningTotal = 0

  const round = unit === "ton" ? 1 : 2

  return set
    .filter((_, index) => index >= startIndex && index <= endIndex)
    .reduce((set, current) => {
      runningTotal += current

      const datapoint = {
        y: runningTotal,
        total: {
          unit,
          current,
          runningTotal
        },
        actual: `${formatNumber(
          unitConvert(current, unit),
          { round }
        )} ${unit} (${formatNumber(
          unitConvert(runningTotal, unit),
          { round }
        )} ${unit} cumulatief)`
      }

      set.push(datapoint)

      return set
    }, [])
}

export const hasAGoal = (goal = {}, goalType = "ambition") => {
  return !(
    [undefined, null].includes(goal[`inner_city_${goalType}`]) &&
    [undefined, null].includes(goal[`outer_city_${goalType}`])
  )
}

export const getStat = (journeys, search, showSegments = false) => {
  const {
    type: searchType,
    trajectories,
    electric: searchElectric,
    trajectory: searchTrajectory
  } = search

  const trajectoriesArray = Array.isArray(trajectories)
    ? trajectories
    : [trajectories]

  // Find that journey
  const matched = journeys.filter(
    ({
      type: journeyType,
      trajectory: journeyTrajectory,
      electric: journeyElectric,
      count
    }) => {
      return (
        searchType === journeyType &&
        trajectoriesArray.indexOf(journeyTrajectory) > -1 &&
        searchElectric === journeyElectric &&
        count > 0
      )
    }
  )

  if (matched.length === 0) return null

  // If trajectory is set it means whe want to return the count of a specific trajectory
  const filtered = searchTrajectory
    ? matched.find(match => match.trajectory === searchTrajectory)
    : matched[0]

  if (filtered) {
    if (showSegments) return filtered.segments

    const { count } = filtered
    return `${count} ${
      searchType === "sail"
        ? count === 1
          ? "vaartdeel"
          : "vaartdelen"
        : searchType === "bike"
        ? count === 1
          ? "fietsritdeel"
          : "fietsritdelen"
        : count === 1
        ? "ritdeel"
        : "ritdelen"
    }${searchElectric ? " elektrisch" : ""}`
  }

  return filtered
}

export const showLine = ({
  journeys,
  direction: directionRequired,
  vertical,
  horizontal,
  journey
}) => {
  const directionDefined = getDirection({
    journeys,
    vertical,
    horizontal,
    journey
  })

  if (directionDefined === "all") return true

  return directionRequired === directionDefined
}

export const getDirection = ({
  journeys,
  journey,
  vertical = [],
  horizontal = []
}) => {
  const showVertical = vertical.length
    ? vertical.reduce((show, trajectory) => {
        if (show) return show

        return !!getStat(journeys, {
          type: journey.type,
          electric: journey.electric,
          trajectories: [trajectory]
        })
      }, false)
    : false

  const showHorizontal = horizontal.length
    ? horizontal.reduce((show, trajectory) => {
        if (show) return show

        return !!getStat(journeys, {
          type: journey.type,
          electric: journey.electric,
          trajectories: [trajectory]
        })
      }, false)
    : false

  if (showVertical && showHorizontal) return "all"
  if (!showVertical && !showHorizontal) return "none"
  if (showVertical) return "vertical"
  if (showHorizontal) return "horizontal"

  console.warn("getDirection(): Direction is unkown")
  return "none"
}

export const hasChartData = chartData => {
  if (!chartData || !chartData.datasets) return false

  const found = chartData.datasets.find(
    ({ data, label }) =>
      data.length > 0 &&
      ["referentie", "ambitie"].indexOf(label.toLowerCase()) === -1
  )

  return !!found
}
