import ajax from "../lib/ajax"

export default {
  async getUsecases({ commit, dispatch, state }, { slug } = {}) {
    const isRefresh = state.cities.length > 0

    if (!isRefresh) dispatch("currentAction", "getUsecases")

    let usecases = []
    try {
      usecases = await ajax(slug ? `usecases?slug=${slug}` : "usecases", {
        authToken: state.authToken
      })
      if (!usecases.error) commit("getUsecases", usecases)
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      if (!isRefresh) dispatch("completeAction", "getUsecases")
      return usecases
    }
  },

  async addUsecase({ commit, dispatch, state }, params) {
    dispatch("currentAction", "addUsecase")

    const body = { ...params }
    if (params.photo) {
      body.photo = btoa(params.photo)
    }

    if (params.documentation) body.documentation = btoa(params.documentation)

    try {
      const response = await ajax(`usecases`, {
        authToken: state.authToken,
        method: "POST",
        body
      })
      commit("addUsecase", response)
      return response
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addUsecase")
    }
  },

  async updateUsecase({ commit, dispatch, state }, payload) {
    dispatch("currentAction", "updateUsecase")

    try {
      const usecase = await ajax(`usecases/${payload.usecase_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: payload
      })
      commit("updateUsecase", usecase)
      return usecase
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateUsecase")
    }
  },

  async deleteUsecase({ commit, dispatch, state }, usecaseId) {
    dispatch("currentAction", "deleteUsecase")
    try {
      await ajax(`usecases/${usecaseId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteUsecase", usecaseId)
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "deleteUsecase")
    }
  },

  async getUsecaseDataset(
    { dispatch, state, commit },
    { usecase_id, start_date, end_date }
  ) {
    if (!usecase_id || !start_date || !end_date) return
    dispatch("currentAction", "getDataset")

    try {
      const start = new Date(start_date)
      const end = new Date(end_date)
      const now = new Date()

      let endpoint = `usecases/${usecase_id}/data`
      endpoint += `?start_date=${start.toISOString()}`
      endpoint += `&end_date=${
        end > now ? now.toISOString() : end.toISOString()
      }`

      return await ajax(endpoint, { authToken: state.authToken })
    } catch (error) {
      commit("saveError", error.message)
      return []
    } finally {
      dispatch("completeAction", "getDataset")
    }
  },

  async getUsecaseTraffic(
    { commit, dispatch, state },
    { usecase_id, start_date, end_date } = {}
  ) {
    if (!usecase_id) return
    dispatch("currentAction", "getUsecaseTraffic")

    try {
      const start = new Date(start_date)
      const end = new Date(end_date)
      const now = new Date()

      let endpoint = `usecases/${usecase_id}/traffic-by-segment`
      if (start_date && end_date) {
        endpoint += `?start_date=${start.toISOString()}`
        endpoint += `&end_date=${
          end > now ? now.toISOString() : end.toISOString()
        }`
      }

      return await ajax(endpoint, { authToken: state.authToken })
    } catch (error) {
      commit("saveError", error.message)
      return []
    } finally {
      dispatch("completeAction", "getUsecaseTraffic")
    }
  },

  async getUsecasePages({ commit, dispatch, state }, { usecase_id } = {}) {
    if (!usecase_id) return
    dispatch("currentAction", "getUsecasePages")

    try {
      const results = await ajax(`pages?usecase=${usecase_id}`, {
        authToken: state.authToken
      })
      commit("addPages", results)
    } catch (error) {
      commit("saveError", error.message)
      return []
    } finally {
      dispatch("completeAction", "getUsecasePages")
    }
  },

  async getUsecaseKPIData(
    { commit, dispatch, state },
    { usecase_id, start_date, end_date, week_start, week_end } = {}
  ) {
    if (!usecase_id) return
    dispatch("currentAction", "getUsecaseKPIData")
    try {
      let endpoint = `usecases/${usecase_id}/kpis`

      if (start_date && end_date) {
        const start = new Date(start_date)
        const end = new Date(end_date)
        const now = new Date()

        endpoint += `?start_date=${start.toISOString()}`
        endpoint += `&end_date=${
          end > now ? now.toISOString() : end.toISOString()
        }`
      } else if (week_start && week_end) {
        endpoint += `?week_start=${week_start}&week_end=${week_end}`
      }

      const results = await ajax(endpoint, { authToken: state.authToken })

      return {
        ...results,
        usecase_id,
        start_date,
        end_date,
        week_start,
        week_end
      }
    } catch (error) {
      // Do not log this error because it will show as a banner
      return {}
    } finally {
      dispatch("completeAction", "getUsecaseKPIData")
    }
  }
}
