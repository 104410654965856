import {
  weeksBetween,
  reduceToIndex,
  startOfYear,
  formatDateNicely
} from "./helpers"
import { DAY_OF_WEEK_LENGTH } from "../config/env"

/*
  An arrow function has no scope of its own in Javascript (by definition it takes the scope of its surroundings).

  Vue binds computed props within a component's scope, so the exported functions we use below have to be regular function expressions.
*/

/**
 * This computed prop calculator is designed to determine the min + max start_date and end_date of
 * all the usecases belonging to the component.
 *
 * Must contain a usecases key on its context (this.usecases)
 *
 * @returns {object} shape of { start_date, end_date }
 */
export function totalDuration() {
  if (!this.usecases) return

  return this.usecases
    .map(({ start_date, end_date }) => ({
      start_date: new Date(start_date),
      end_date: new Date(end_date)
    }))
    .reduce((range, { start_date, end_date }) => {
      if (!range.start_date) range.start_date = start_date
      else if (start_date < range.start_date) range.start_date = start_date

      if (!range.end_date) range.end_date = end_date
      else if (end_date > range.end_date) range.end_date = end_date

      return range
    }, {})
}

/**
 * Generates a list of years for which data should be available.
 *
 * Must contain a usecases key on its context (this.usecases)
 *
 * @returns {array[number]} shape of [ 2017, 2018 ]
 */
export function yearsToChoose() {
  if (!this.usecases) return

  const currentYear = new Date().getUTCFullYear()

  // Extract only years from our usecases
  return (
    this.usecases
      .flatMap(({ start_date, end_date }) => [
        new Date(start_date).getUTCFullYear(),
        new Date(end_date).getUTCFullYear()
      ])
      // Sort by lowest -> highest
      .sort((a, b) => a - b)
      // Only unique values need to be saved!
      .filter(
        (year, index, array) =>
          array.lastIndexOf(year) === index && year <= currentYear
      )
      // Fill in gap years between ones we have
      .reduce((all, year, index) => {
        // We have no years filled in
        if (all.length === 0) all.push(year)
        // This year is consecutive to the previous
        else if (year - all[index - 1] === 1) all.push(year)
        // We need to add some gap years here...
        else {
          let yearValue = year - all[index - 1]

          while (yearValue < year) {
            yearValue += 1
            all.push(yearValue)
          }
        }

        return all
      }, [])
  )
}

/**
 * The total goals of all given usecases.
 *
 * Must contain a usecases, measurements, kpis keys on its context (this.usecases, ...)
 *
 * @returns {array} array of goals with week-over-week totals as well in them
 */
export function totalGoals() {
  if (
    ((!this.usecases || this.usecases.length < 1) && !this.usecase) ||
    !this.kpis ||
    this.kpis.length < 1 ||
    !this.measurements ||
    this.measurements.length < 1 ||
    !this.totalDuration
  )
    return {}

  const type = this.usecase ? "usecase" : this.usecases ? "city" : null
  if (!type) return {}

  const goals = {}

  for (const kpi of this.kpis) {
    const usecase =
      this.usecase ||
      this.usecases.find(({ usecase_id }) => usecase_id === kpi.usecase)
    const measurement = this.measurements.find(
      ({ measurement_id }) => measurement_id === kpi.measurement
    )

    if (!usecase || !measurement) continue
    if (!goals[measurement.key]) goals[measurement.key] = []

    const start_date = new Date(usecase.start_date)
    const end_date = new Date(usecase.end_date)
    const weeksInUsecase = weeksBetween(start_date, end_date, {
      rounded: false
    })

    goals[measurement.key].push({
      usecase: usecase.slug,

      weeksInUsecase,

      start_date: new Date(usecase.start_date),
      end_date: new Date(usecase.end_date),

      inner_city_ambition: kpi.inner_city_ambition,
      outer_city_ambition: kpi.outer_city_ambition,
      inner_city_reference: kpi.inner_city_reference,
      outer_city_reference: kpi.outer_city_reference,

      // wow = week over week
      wow_inner_city_ambition: kpi.inner_city_ambition
        ? kpi.inner_city_ambition / weeksInUsecase
        : kpi.inner_city_ambition,
      wow_outer_city_ambition: kpi.outer_city_ambition
        ? kpi.outer_city_ambition / weeksInUsecase
        : kpi.outer_city_ambition,
      wow_inner_city_reference: kpi.inner_city_reference / weeksInUsecase,
      wow_outer_city_reference: kpi.outer_city_reference / weeksInUsecase
    })
  }

  return goals
}

export function goalsByWeek() {
  if (this.totalGoals.length < 1) return {}

  const type = this.usecase ? "usecase" : this.usecases ? "city" : null
  if (!type) return {}

  const startOfPeriod =
    type === "city" ? startOfYear(this.year) : new Date(this.usecase.start_date)

  const weekOverWeek = {}
  const amountOfWeeks =
    type === "usecase"
      ? weeksBetween(startOfPeriod, this.usecase.end_date, { rounded: false })
      : weeksBetween(this.year, this.year + 1, { rounded: false })

  for (const goalKey of Object.keys(this.totalGoals)) {
    if (weekOverWeek[goalKey]) continue

    const goal = this.totalGoals[goalKey]
    const dataset = []

    let week = 0

    while (week < amountOfWeeks) {
      const daysInWeek =
        amountOfWeeks - week >= 1
          ? 7
          : Math.ceil((amountOfWeeks - week) / DAY_OF_WEEK_LENGTH)

      const daysToStart = week * 7
      const startOfWeek = new Date(startOfPeriod)
      const endOfWeek = new Date(startOfPeriod)
      startOfWeek.setUTCDate(startOfWeek.getUTCDate() + daysToStart)
      endOfWeek.setUTCDate(
        startOfPeriod.getUTCDate() + daysToStart + daysInWeek
      )

      const frame = dataset.length > 0 ? { ...dataset[dataset.length - 1] } : {}

      for (const kpi of goal) {
        if (kpi.start_date >= endOfWeek) continue
        if (kpi.end_date < startOfWeek) continue

        const kpiFrameStartDate =
          kpi.start_date <= startOfWeek ? startOfWeek : kpi.start_date
        const kpiFrameEndDate =
          kpi.end_date >= endOfWeek ? endOfWeek : kpi.end_date
        const weekToEndDifference = weeksBetween(
          kpiFrameStartDate,
          kpiFrameEndDate,
          { rounded: false }
        )
        const daysInKPIWeek =
          weekToEndDifference >= 1
            ? 7
            : Math.ceil(weekToEndDifference / DAY_OF_WEEK_LENGTH)
        const isPartialWeek = daysInKPIWeek !== 7 || daysInWeek !== 7

        let daysinCurrentKPIFrame = daysInWeek
        if (daysInKPIWeek < daysInWeek) daysinCurrentKPIFrame = daysInKPIWeek

        let {
          wow_inner_city_reference,
          wow_outer_city_reference,
          wow_inner_city_ambition,
          wow_outer_city_ambition
        } = kpi

        if (isPartialWeek) {
          if (wow_inner_city_reference !== null)
            wow_inner_city_reference =
              (wow_inner_city_reference / 7) * daysinCurrentKPIFrame

          if (wow_outer_city_reference !== null)
            wow_outer_city_reference =
              (wow_outer_city_reference / 7) * daysinCurrentKPIFrame

          if (wow_inner_city_ambition !== null)
            wow_inner_city_ambition =
              (wow_inner_city_ambition / 7) * daysinCurrentKPIFrame

          if (wow_outer_city_ambition !== null)
            wow_outer_city_ambition =
              (wow_outer_city_ambition / 7) * daysinCurrentKPIFrame
        }

        if (wow_inner_city_reference !== null) {
          if (typeof frame.inner_city_reference !== "number")
            frame.inner_city_reference = 0
          frame.inner_city_reference += wow_inner_city_reference
        }

        if (wow_outer_city_reference !== null) {
          if (typeof frame.outer_city_reference !== "number")
            frame.outer_city_reference = 0
          frame.outer_city_reference += wow_outer_city_reference
        }

        if (wow_inner_city_ambition !== null) {
          if (typeof frame.inner_city_ambition !== "number")
            frame.inner_city_ambition = 0
          frame.inner_city_ambition += wow_inner_city_ambition
        }

        if (wow_outer_city_ambition !== null) {
          if (typeof frame.outer_city_ambition !== "number")
            frame.outer_city_ambition = 0
          frame.outer_city_ambition += wow_outer_city_ambition
        }
      }

      dataset.push(frame)
      week += 1
    }

    weekOverWeek[goalKey] = dataset
  }

  return weekOverWeek
}

export function linearGrowthByPercent({
  weekStart,
  weekEnd,
  slices,
  total,
  float = true,
  base = 1
} = {}) {
  if (!slices || !total || !base) return []
  const incrementBy = (total / slices) * base

  const array =
    weekStart && weekEnd
      ? Array.from(Array(slices))
          .map((_, index) => (index + 1) * incrementBy)
          .slice(weekStart - 1, weekEnd)
      : Array.from(Array(slices)).map((_, index) => (index + 1) * incrementBy)

  return array.map(y => ({
    y,
    actual: String(
      float ? Math.round(incrementBy * 100) / 100 : Math.round(incrementBy)
    ).replace(".", ",")
  }))
}

export function startIndex() {
  if (this.chartLoaded === false) return 0
  return this.weekStart ? this.weekStart - 1 : 0
}

export function endIndex() {
  if (this.chartLoaded === false) return 0

  if (this.city && this.preciseWeeks > 0) return Math.ceil(this.preciseWeeks)

  if (this.data.weeks && this.data.weeks.length > this.weekEnd - this.weekStart)
    return Math.ceil(this.weekEnd) - 1

  const now = new Date()

  const { index } = this.data.weeks.reduce((current, week, index) => {
    const weekDate = new Date(week)

    if (!current && weekDate < now) return { date: weekDate, index }
    else if (current && weekDate < now && weekDate > current.date)
      return { date: weekDate, index }

    return current
  }, null)

  return index || 0
}

const orZero = value => {
  return value || 0
}

export function getGoalForWeeks(
  goal,
  { cityType = "total", type = "ambition" } = {}
) {
  if (!goal) return false

  if (["ambition", "reference"].indexOf(type) === -1) return false
  if (["inner", "outer", "total"].indexOf(cityType) === -1) return false

  const { startIndex, endIndex } = this

  const innerKey = `inner_city_${type}`
  const outerKey = `outer_city_${type}`
  const totalKey = `${cityType}_city_${type}`

  if (typeof this.weekStart !== "number" || typeof this.weekEnd !== "number") {
    if (cityType !== "total") return goal[endIndex][totalKey]
    return orZero(goal[endIndex][innerKey]) + orZero(goal[endIndex][outerKey])
  }

  if (!goal[startIndex] || !goal[endIndex]) return false

  if (goal[startIndex] === goal[endIndex]) {
    if (cityType !== "total") return orZero(goal[0][totalKey])
    return orZero(goal[0][innerKey]) + orZero(goal[0][outerKey])
  }

  if (startIndex === 0) {
    if (cityType !== "total") return goal[endIndex][totalKey]
    return orZero(goal[endIndex][innerKey]) + orZero(goal[endIndex][outerKey])
  }

  if (cityType !== "total")
    return orZero(goal[endIndex][totalKey]) - orZero(goal[startIndex][totalKey])
  return (
    orZero(goal[endIndex][innerKey]) +
    orZero(goal[endIndex][outerKey]) -
    (orZero(goal[startIndex][innerKey]) + orZero(goal[startIndex][outerKey]))
  )
}

export const endIndexTotals = (statType, stats = []) =>
  function() {
    const totals = stats.reduce((all, one) => {
      all[one] = null
      return all
    }, {})

    if (this.chartLoaded === false) return totals
    if (!statType) return totals

    const [inner, outer] = [`inner_city_${statType}`, `outer_city_${statType}`]

    for (const stat of ["co2", "nox", "pm10"]) {
      const kpi = `${stat}-in-grams`
      if (!this.kpis[kpi]) continue

      totals[stat] = (this.kpis[kpi][inner] || 0) + (this.kpis[kpi][outer] || 0)
    }
    return totals
  }

export const cumulativeTotals = (measures = [], sets = []) =>
  function() {
    const totals = measures.reduce((all, one) => {
      all[one] = null
      return all
    }, {})

    if (this.chartLoaded === false) return totals

    for (const measure of measures) {
      const collection = sets.map(dataset => this.data[`${measure}_${dataset}`])
      totals[measure] = reduceToIndex(
        this.endIndex,
        collection,
        this.startIndex
      )
    }

    return totals
  }
