import ajax from "../lib/ajax"

export default {
  async getKPIs({ commit, dispatch, state }) {
    const isRefresh = state.kpis.length > 0
    if (!isRefresh) dispatch("currentAction", "getKPIs")
    try {
      const kpis = await ajax("kpis", { authToken: state.authToken })
      if (!kpis.error) commit("getKPIs", kpis)
      else throw new Error(kpis.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getKPIs")
    }
  },

  async addKPI({ commit, dispatch, state }, payload) {
    dispatch("currentAction", "addKPI")
    try {
      const kpi = await ajax(`kpis`, {
        authToken: state.authToken,
        method: "POST",
        body: payload
      })
      commit("addKPI", kpi)
      return kpi
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addKPI")
    }
  },

  async updateKPI({ commit, dispatch, state }, { kpi_id, ...params } = {}) {
    dispatch("currentAction", "updateKPI")
    try {
      const kpi = await ajax(`kpis/${kpi_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: params
      })
      commit("updateKPI", kpi)
      return kpi
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateKPI")
    }
  },

  async deleteKPI({ commit, dispatch, state }, kpiId) {
    dispatch("currentAction", "deleteKPI")
    try {
      await ajax(`kpis/${kpiId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteKPI", kpiId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteKPI")
    }
  }
}
