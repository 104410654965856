export default {
  gettingUsecaseList(state) {
    return state.currentWork.indexOf("getUsecases") > -1
  },

  gettingUsecaseKPIData(state) {
    return state.currentWork.includes("getUsecaseKPIData")
  },

  savingUsecase(state) {
    return (
      state.currentWork.indexOf("addUsecase") > -1 ||
      state.currentWork.indexOf("updateUsecase") > -1
    )
  }
}
