export default {
  getSegments(state, segments = []) {
    if (!state.authToken) return
    state.segments = segments
  },

  addSegment(state, segment) {
    state.segments.push(segment)
  },

  updateSegment(state, updatedSegment) {
    state.segments = state.segments.map(segment =>
      Number(segment.segment_id) === Number(updatedSegment.segment_id)
        ? { ...segment, ...updatedSegment }
        : segment
    )
  },

  deleteSegment(state, deletedSegmentId) {
    state.segments = state.segments.filter(
      ({ segment_id }) => deletedSegmentId !== segment_id
    )
  }
}
