import { ROUND_NUMBERS_FROM } from "../config/env"

/*
  Javascript sucks at rounding.
  See https://stackoverflow.com/a/12830454
*/
export const precisionRound = (number, scale) => {
  if (!`${number}`.includes("e"))
    return +(Math.round(`${number}e+${scale}`) + `e-${scale}`)

  const [float, fraction] = `${number}`.split("e")
  let sig = ""

  if (+fraction + scale > 0) sig = "+"

  return +(
    Math.round(`${+float}e${sig}` + `${+fraction}${scale}`) + `e-${scale}`
  )
}

const convert = (input = 0, factor = 1) => input * factor

export const formatNumber = (number, { round = 2 } = {}) => {
  if (typeof number !== "number") {
    console.warn(
      `formatNumber() got a ${typeof number} instead of a number: '${number}'`
    )
    return number
  }

  const fraction = parseInt(
    number
      .toExponential()
      .split("e")
      .slice(1)
      .shift(),
    10
  )

  if (fraction < -1) round = fraction * -1

  if (number > ROUND_NUMBERS_FROM || number < -ROUND_NUMBERS_FROM) round = 0

  const rounded =
    round === 0 ? Math.round(number) : precisionRound(number, round)

  if (number < 0.001 && number > 0)
    return "< " + new Intl.NumberFormat("nl-NL").format(0.001)

  return new Intl.NumberFormat("nl-NL").format(rounded)
}

export const gram2ton = grams => convert(grams, 0.000001)

export const gram2kg = grams => convert(grams, 0.001)

export const unitConvert = (value, unit) => {
  switch (unit) {
    case "ton":
      return gram2ton(value)
    case "kg":
      return gram2kg(value)
    default:
      return convert(value, 1)
  }
}
