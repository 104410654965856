import ajax from "../lib/ajax"

export default {
  async getPages({ commit, dispatch, state }) {
    const isRefresh = state.pages.length > 0
    if (!isRefresh) dispatch("currentAction", "getPages")

    try {
      const pages = await ajax("pages", { authToken: state.authToken })
      if (!pages.error) commit("getPages", pages)
      else throw new Error(pages.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getPages")
    }
  },

  async addPage({ commit, dispatch, state }, { title, slug, usecase } = {}) {
    dispatch("currentAction", "addPage")

    try {
      const page = await ajax(`pages`, {
        authToken: state.authToken,
        method: "POST",
        body: { title, slug, usecase }
      })
      commit("addPage", page)
      return page
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addPage")
    }
  },

  async updatePage(
    { commit, dispatch, state },
    { page_id, usecase, title, content } = {}
  ) {
    dispatch("currentAction", "updatePage")

    try {
      const page = await ajax(`pages/${page_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: { title, content, usecase }
      })
      commit("updatePage", page)
      return page
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updatePage")
    }
  },

  async deletePage({ commit, dispatch, state }, pageId) {
    dispatch("currentAction", "deletePage")

    try {
      await ajax(`pages/${pageId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deletePage", pageId)
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "deletePage")
    }
  }
}
