<template>
  <header>
    <nav v-click-outside="closeNav">
      <div>
        <router-link to="/"><img src="../../public/logo.svg"/></router-link>
      </div>
      <div class="breadcums">
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li v-for="(route, index) of breadcrumbs" :key="index">
            <router-link :to="route.link || route">{{
              route.displayName || route.name || "Admin"
            }}</router-link>
          </li>
        </ul>
      </div>
      <p class="auth" v-if="isAuthenticated">
        <strong v-if="currentUser">{{ currentUser.name }}</strong>
        <span v-if="userOrg">({{ userOrg.name }})</span>
        <a
          v-if="
            isAuthenticated && !userIsAdmin && !userIsUploader && !userIsGod
          "
          v-on:click="logout"
          >Uitloggen</a
        >
      </p>
      <div class="auth" v-else>
        <router-link to="/login">Inloggen</router-link>
      </div>
      <div v-if="isAuthenticated && (userIsAdmin || userIsUploader)">
        <button
          class="hamburger hamburger--minus"
          type="button"
          v-on:click="navOpen = !navOpen"
          v-bind:class="{ 'is-active': navOpen }"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </nav>
    <div
      class="dropdown"
      v-bind:class="{ 'is-active': navOpen }"
      v-if="isAuthenticated && (userIsAdmin || userIsUploader)"
    >
      <ul>
        <li v-if="isAuthenticated && userIsAdmin">
          <router-link to="/admin">Admin</router-link>
        </li>
        <li v-if="isAuthenticated && (userIsAdmin || userIsUploader)">
          <router-link to="/data/import">Data</router-link>
        </li>
        <li v-if="isAuthenticated"><a v-on:click="logout">Uitloggen</a></li>
      </ul>
    </div>
  </header>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import clickOutside from "../directives/click-outside"

export default Vue.extend({
  name: "Navbar",

  data() {
    return {
      email: null,
      password: null,
      navOpen: false
    }
  },

  props: ["parent"],

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser",
      "currentUseCase",
      "userOrg",
      "loggingIn",
      "userIsAdmin",
      "userIsUploader",
      "userIsGod"
    ]),

    breadcrumbs() {
      return this.$route.matched
        .filter(({ name, meta: { hideInBreadcrumbs } }) => {
          return name !== "landing" && hideInBreadcrumbs !== true
        })
        .reduce((prev, curr) => {
          if (curr.name === "Stad") {
            curr.displayName = this.convertSlugToName(this.$route.params.slug)
          } else if (curr.name === "Usecase" || curr.name === "Use case") {
            const citySlug = this.$route.params.citySlug
            const cityName = this.convertSlugToName(citySlug)

            curr.displayName = this.currentUseCase
              ? this.currentUseCase.name
              : ""

            const route = this.$router.options.routes.find(
              ({ name }) => name === "Stad"
            )

            prev.push({
              meta: { linkedInBreadscrumbs: true },
              ...route,
              link: citySlug ? `/city/${citySlug}` : null,
              displayName: this.convertSlugToName(cityName || route.name)
            })
          }
          prev.push(curr)
          return prev
        }, [])
    }
  },

  directives: {
    "click-outside": clickOutside
  },

  methods: {
    convertSlugToName(slug) {
      if (!slug) return
      return slug
        .replace(/[-_]+/g, " ")
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        .join(" ")
    },
    logout() {
      this.$store.dispatch("logout").then(() => this.$router.push("/login"))
    },
    closeNav() {
      this.navOpen = false
    }
  }
})
</script>

<style lang="scss" scoped>
header {
  background-color: $text-color;
}
nav {
  margin: 0 auto;
  width: $full-width;
  padding: 1rem 0;
  max-width: calc(100% - 2rem);
  color: white;
  display: flex;
  align-items: center;

  a,
  p {
    color: white;
  }
  @media (max-width: $medium-width) {
    padding: 0.5rem 0;
  }
}
.auth {
  display: block;
  margin-left: auto;

  & + div {
    margin-left: 1rem;
  }

  @media (max-width: $medium-width) {
    // This way the container element will still be there for it's "margin-left: auto;"
    & > * {
      display: none;
    }
  }
}
.breadcums {
  margin-left: 2rem;
  li {
    display: inline;
  }
  li + li::before {
    content: "\02192";
    color: white;
  }
  span {
    color: white;
    padding: 0 1rem;
  }
  a {
    padding: 0 1rem;
  }
  @media (max-width: $medium-width) {
    display: none;
  }
}
.hamburger {
  transform: scale(0.7);
  padding: 0;
}
img {
  width: 130px;

  @media (max-width: $medium-width) {
    width: 110px;
  }
}
p {
  margin: 0;
}
.dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  pointer-events: none;

  transition: max-height 0.15s ease-in-out;
  overflow: hidden;
  max-height: 0;

  &.is-active {
    max-height: 250px;
    z-index: 1;
  }

  @media (max-width: $medium-width) {
    top: 44px;
  }

  ul {
    list-style: none;
    display: flex;
    margin: 0 auto;
    width: $full-width;
    max-width: calc(100% - 2rem);
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: $medium-width) {
      max-width: 100%;
    }

    li {
      border: 1px solid black;
      border-top: none;
      padding: 1rem;
      width: 200px;
      max-width: 100%;
      background-color: white;
      pointer-events: all;

      @media (max-width: $medium-width) {
        width: 100%;
        border-left: none;
        border-right: none;
      }
    }

    a {
      color: black;
    }
  }
}
</style>
