export default {
  gettingVehicleList(state) {
    return state.currentWork.indexOf("getVehicles") > -1
  },

  savingVehicle(state) {
    return (
      state.currentWork.indexOf("addVehicle") > -1 ||
      state.currentWork.indexOf("updateVehicle") > -1
    )
  }
}
