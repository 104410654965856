<template>
  <section>
    <h1>TNO Monitoringstool<br />stadslogistiek</h1>
    <ErrorBox></ErrorBox>

    <p class="big">Ben je je wachtwoord vergeten? Geen probleem!</p>

    <h2 class="alt mt-4 mb-3">Wachtwoord vergeten</h2>

    <p v-if="sent">
      We hebben een herstellink verstuurd naar <strong>{{ email }}</strong
      >.
    </p>

    <div v-else>
      <div class="from-horizontal">
        <div :class="error ? 'field' : 'field'">
          <label>E-mailadres</label>
          <input
            type="email"
            placeholder="Vul email in..."
            v-model="email"
            v-on:keyup.enter="sendResetToken"
          />
          <p class="error-message">{{ error }}</p>
        </div>

        <div class="field field--button">
          <button v-on:click="sendResetToken" :disabled="passwordForgotRequest">
            Herstel mijn wachtwoord
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { isValidEmail } from "../../lib/helpers"
import ErrorBox from "../../components/ErrorBox"

export default Vue.extend({
  data() {
    return {
      email: null,
      sent: false,
      error: null
    }
  },

  components: { ErrorBox },

  computed: {
    ...mapGetters(["passwordForgotRequest"])
  },

  methods: {
    sendResetToken() {
      if (!this.email) {
        return (this.error = `Vul wel een e-mailadres in`)
      } else if (!isValidEmail(this.email)) {
        return (this.error = `Vul wel een geldig e-mailadres in`)
      } else this.error = null

      this.$store
        .dispatch("sendPasswordReset", this.email)
        .then(() => (this.sent = true))
    }
  }
})
</script>
