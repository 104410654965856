import ajax from "../lib/ajax"

export default {
  async getMeasurements({ commit, dispatch, state }) {
    const isRefresh = state.measurements.length > 0
    if (!isRefresh) dispatch("currentAction", "getMeasurements")

    try {
      const measurements = await ajax("measurements", {
        authToken: state.authToken
      })
      if (!measurements.error) commit("getMeasurements", measurements)
      else throw new Error(measurements.error)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      if (!isRefresh) dispatch("completeAction", "getMeasurements")
    }
  },

  async addMeasurement({ commit, dispatch, state }, { name } = {}) {
    dispatch("currentAction", "addMeasurement")

    try {
      const measurement = await ajax(`measurements`, {
        authToken: state.authToken,
        method: "POST",
        body: { name }
      })
      commit("addMeasurement", measurement)
      return measurement
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "addMeasurement")
    }
  },

  async updateMeasurement(
    { commit, dispatch, state },
    { measurement_id, name } = {}
  ) {
    dispatch("currentAction", "updateMeasurement")

    try {
      const measurement = await ajax(`measurements/${measurement_id}`, {
        authToken: state.authToken,
        method: "PUT",
        body: { name }
      })
      commit("updateMeasurement", measurement)
      return measurement
    } catch (error) {
      commit("saveError", error.message)
      return error
    } finally {
      dispatch("completeAction", "updateMeasurement")
    }
  },

  async deleteMeasurement({ commit, dispatch, state }, measurementId) {
    dispatch("currentAction", "deleteMeasurement")

    try {
      await ajax(`cities/${measurementId}`, {
        authToken: state.authToken,
        method: "DELETE",
        noReply: true
      })
      commit("deleteMeasurement", measurementId)
    } catch (error) {
      commit("saveError", error.message)
    } finally {
      dispatch("completeAction", "deleteMeasurement")
    }
  }
}
