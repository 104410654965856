<template>
  <div>
    <h1>Oeps..</h1>
    <p>Pagina niet gevonden, dit is waarschijnlijk een foutje van ons.</p>
    <p>
      Neem contact op met
      <a href="mailto:christian.vanommeren@tno.nl">Christian</a> (TNO) op het op
      te lossen.
    </p>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  data() {
    return {}
  }
})
</script>

<style lang="scss" scoped></style>
