<template>
  <p class="loading-message" v-if="gettingUsecaseList">
    use case aan het laden...
  </p>
  <p class="loading-message" v-else-if="!userHasAccess">
    Je hebt geen toegang tot {{ usecase.name }}
  </p>
  <div v-else>
    <ChartPickerBar
      mode="week"
      :weeks="weeksToChoose"
      @updateWeeks="updateWeeks"
      :weekStart="weekStart"
      :weekEnd="weekEnd"
    ></ChartPickerBar>

    <section class="main">
      <h1>{{ usecase.name }}</h1>
      <ErrorBox></ErrorBox>
      <p>{{ usecase.description }}</p>
      <p>
        De use case loopt van {{ usecaseStart }} tot {{ usecaseEnd }}. Op deze
        pagina wordt de data getoond van week {{ weekStart }} tot en met
        {{ weekEnd }}.
      </p>

      <div v-if="pages.length" class="mt-2">
        <h4>Pagina's voor {{ usecase.name }}</h4>
        <ul v-for="(page, index) of pages" :key="index" class="ml-1 mt-1">
          <li>
            <router-link :to="`/page/${page.slug}`">{{
              page.title
            }}</router-link>
          </li>
        </ul>
      </div>
    </section>

    <section class="stat-card">
      <h2 v-if="project && project.progress_section_title">
        {{ project.progress_section_title }}
      </h2>
      <h2 v-else>Voortgang</h2>
      <progress-stats
        :usecase="usecase"
        :weekStart="weekStart"
        :weekEnd="weekEnd"
        :data="rawData"
        :loading="gettingStatset"
        :kpis="kpis"
        :hasAmbitions="hasAmbitions"
        :preciseWeeks="preciseWeeks"
      >
      </progress-stats>
    </section>

    <section class="stat-card">
      <h2 v-if="project && project.emission_section_title">
        {{ project.emission_section_title }}
      </h2>
      <h2 v-else>Klimaat- en luchtkwaliteit</h2>

      <div class="split mb-2">
        <nav-tabs class="m-0">
          <ul class="keep-horizontal">
            <li>
              <a
                :class="activeVisual === 'donut' ? 'is-active' : ''"
                @click="changeVisual('donut')"
                >Verdeling</a
              >
            </li>
            <li>
              <a
                :class="activeVisual === 'time' ? 'is-active' : ''"
                @click="changeVisual('time')"
                >Tijd</a
              >
            </li>
            <li>
              <a
                :class="activeVisual === 'segment' ? 'is-active' : ''"
                @click="changeVisual('segment')"
                >Segment</a
              >
            </li>
          </ul>
        </nav-tabs>
      </div>

      <climate-graphs-stats
        v-if="activeVisual"
        :activeVisual="activeVisual"
        mode="usecase"
        :hasAmbitions="hasAmbitions"
        :chartType="chartType"
        :data="rawData"
        :loading="gettingStatset"
        :week="week"
        :usecase="usecase"
        :weekStart="weekStart"
        :weekEnd="weekEnd"
        :kpis="kpis"
        :preciseWeeks="preciseWeeks"
      >
      </climate-graphs-stats>
    </section>

    <section class="stat-card">
      <h2 v-if="project && project.traffic_section_title">
        {{ project.traffic_section_title }}
      </h2>
      <h2 v-else>Verkeersimpact</h2>
      <traffic-stats
        :data="rawData"
        :loading="gettingStatset"
        :kpis="kpis"
        :week="week"
        :usecase="usecase"
        :weekStart="weekStart"
        :weekEnd="weekEnd"
        :hasAmbitions="hasAmbitions"
        :preciseWeeks="preciseWeeks"
      ></traffic-stats>
    </section>

    <section class="stat-card">
      <h2 v-if="project && project.conclusion_section_title">
        {{ project.conclusion_section_title }}
      </h2>
      <h2 v-else>Conclusie</h2>
      <conclusion-stats
        :data="rawData"
        :loading="gettingStatset"
        :kpis="kpis"
        :weekStart="weekStart"
        :weekEnd="weekEnd"
        :preciseWeeks="preciseWeeks"
      ></conclusion-stats>
    </section>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import ErrorBox from "../components/ErrorBox.vue"
import SafeImage from "../components/SafeImage.vue"
import ChartPickerBar from "../components/ChartPickerBar.vue"
import ProgressStats from "../components/ProgressStats.vue"
import ClimateGraphsStats from "../components/ClimateGraphsStats.vue"
import TrafficStats from "../components/TrafficStats.vue"
import ConclusionStats from "../components/ConclusionStats.vue"
import NavTabs from "../components/NavTabs.vue"

import { weeksBetween, hasAGoal } from "../lib/helpers"
import { totalDuration, yearsToChoose } from "../lib/calculators"
import { MEASUREMENT_MAPPINGS } from "../config/env"

export default Vue.extend({
  name: "UsecasePage",

  data() {
    return {
      userHasAccess: true,
      showClimateDetails: false,
      rawData: null,
      week: null,
      weekStart: 1,
      weekEnd: 1,
      activeVisual: "donut",
      kpis: {}
    }
  },

  components: {
    ErrorBox,
    SafeImage,
    ChartPickerBar,
    ProgressStats,
    ClimateGraphsStats,
    TrafficStats,
    ConclusionStats,
    NavTabs
  },

  watch: {
    usecase() {
      if (this.usecase)
        this.$store.commit("updateCurrentUseCase", { name: this.usecase.name })

      if (!this.week)
        this.week = this.weeksToChoose[this.weeksToChoose.length - 1]

      this.fetchDataset()
      this.getKPIData()

      const end =
        new Date(this.usecase.end_date) < new Date()
          ? new Date(this.usecase.end_date)
          : new Date()
      const totalWeeks = Math.ceil(this.preciseWeeks)

      // Get start and end weeks from the URL if they are present
      // Validate them with the actual data
      if (this.$route.query.start && this.$route.query.end) {
        const start = parseInt(this.$route.query.start, 10)
        const end = parseInt(this.$route.query.end, 10)
        this.weekStart = start <= totalWeeks && start >= 1 ? start : 1
        this.weekEnd = end <= totalWeeks ? end : totalWeeks
      } else {
        // Default show data from start of usecase to today
        if (this.usecase.last_journey_date <= new Date()) {
          this.weekEnd = weeksBetween(
            this.usecase.start_date,
            this.usecase.last_journey_date
          )
        } else if (
          new Date(this.usecase.start_date) < Date.now() &&
          new Date(this.usecase.end_date) > Date.now()
        ) {
          this.weekEnd = weeksBetween(this.usecase.start_date, new Date())
        } else {
          this.weekEnd = totalWeeks
        }

        this.getKPIData()
      }
    },

    weekStart() {
      this.getKPIData()
    },

    weekEnd() {
      this.getKPIData()
    }
  },

  methods: {
    fetchDataset() {
      if (!this.usecase) return
      if (this.$route.query.week) this.week = Number(this.$route.query.week)

      this.$store.dispatch("getUsecaseDataset", this.usecase).then(result => {
        if (result) this.rawData = result
      })
    },

    async getKPIData() {
      if (!this.usecase || !this.weekEnd || !this.weekStart) return
      if (this.weekEnd === this.weekStart) return
      if (this.gettingUsecaseKPIData) return

      this.kpis = await this.$store.dispatch("getUsecaseKPIData", {
        usecase_id: this.usecase.usecase_id,
        week_start: this.weekStart,
        week_end: this.weekEnd
      })
    },

    updateWeeks(start, end) {
      this.weekStart = start
      this.weekEnd = end
      this.$router.replace({ query: { start, end } })
    },

    changeVisual(type) {
      this.activeVisual = type
    }
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "gettingUsecaseList",
      "gettingCityList",
      "gettingProjectList",
      "gettingStatset",
      "gettingUsecaseKPIData"
    ]),

    hasAmbitions() {
      if (!this.kpis) return false

      let usesAmbitions = false

      if (this.project && this.project.use_ambitions) {
        usesAmbitions =
          hasAGoal(this.kpis["pm10-in-grams"]) ||
          hasAGoal(this.kpis["co2-in-grams"]) ||
          hasAGoal(this.kpis["rides-on-the-road"])
      }

      return usesAmbitions
    },

    slug() {
      return this.$route.params.usecaseSlug
    },

    usecase() {
      return this.$store.state.usecases.find(({ slug }) => slug === this.slug)
    },

    project() {
      if (!this.usecase) return
      return this.$store.state.projects.find(
        ({ project_id }) => project_id === this.usecase.project
      )
    },

    chartType() {
      if (!this.project) return "doughnut"
      return this.project.use_barchart ? "bar" : "doughnut"
    },

    usecaseStart() {
      if (!this.usecase) return ""
      return new Intl.DateTimeFormat("nl-NL").format(
        new Date(this.usecase.start_date)
      )
    },

    usecaseEnd() {
      if (!this.usecase) return ""
      return new Intl.DateTimeFormat("nl-NL").format(
        new Date(this.usecase.end_date)
      )
    },

    pages() {
      if (!this.usecase) return []
      return this.$store.state.pages.filter(
        ({ usecase }) => usecase === this.usecase.usecase_id
      )
    },

    weeksToChoose() {
      if (!this.preciseWeeks) return []

      // Fractional weeks should still be displayed, so we take the next largest index
      const weekCeiling = Math.ceil(this.preciseWeeks)

      // Because JS does not have a doSomethingNTimes(5)
      return Array.from(Array(weekCeiling)).map((_, index) => index + 1)
    },

    measurements() {
      if (this.$store.state.measurements.length < 1) return []
      const relevantMeasurements = Object.keys(MEASUREMENT_MAPPINGS)

      return this.$store.state.measurements
        .filter(({ slug }) => relevantMeasurements.indexOf(slug) > -1)
        .map(measurement => ({
          ...measurement,
          key: MEASUREMENT_MAPPINGS[measurement.slug]
        }))
    },

    totalDuration() {
      if (!this.usecase) return

      const start_date = new Date(this.usecase.start_date)
      const end_date = new Date(this.usecase.end_date)
      const now = new Date()

      return {
        start_date,
        end_date: now > end_date ? end_date : now
      }
    },

    preciseWeeks() {
      if (!this.usecase) return 0

      const startDate = new Date(this.usecase.start_date)
      const endDate =
        new Date(this.usecase.end_date) < new Date()
          ? new Date(this.usecase.end_date)
          : new Date()

      return weeksBetween(startDate, endDate, { rounded: false })
    }
  },

  async beforeCreate() {
    try {
      await Promise.all([
        this.$store.dispatch("getCities"),
        this.$store.dispatch("getUsecases"),
        this.$store.dispatch("getMeasurements"),
        this.$store.dispatch("getProjects")
      ]).then(this.getKPIData)
    } catch ({ message }) {
      if (message === "unauthorised") return this.$router.push("/login")
    }

    const userOrg = this.$store.state.user.organisation
    const isGod = this.$store.state.user.scopes
      ? this.$store.state.user.scopes.indexOf("god") > -1
      : false
    const userHasAccess = isGod
      ? true
      : this.usecase.organisations.indexOf(userOrg) > -1

    this.userHasAccess = userHasAccess

    return this.$store.dispatch("getUsecasePages", this.usecase)
  }
})
</script>
