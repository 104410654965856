<template>
  <p v-if="gettingCityList" class="loading-message">Stad aan het laden...</p>
  <div v-else>
    <ChartPickerBar
      v-if="usecases.length"
      mode="year"
      :year-set-in-url="yearSetInUrl"
      :years="yearsToChoose"
      :last-journey-year="lastJourneyYear"
      :projects="choosableProjects"
      :date-value="year"
      :project-value="project"
      :onUpdate="updateYear"
      :onUpdateProject="updateProject"
    ></ChartPickerBar>

    <section class="main">
      <h1>{{ city.name }}</h1>

      <ErrorBox></ErrorBox>

      <div class="usecases-drawer">
        <ul>
          <li v-for="(usecase, index) in userUsecases" :key="index">
            <router-link
              v-if="usecase.userHasAccess"
              :to="`/city/${city.slug}/${usecase.slug}`"
            >
              <SafeImage
                :src="usecase.photo"
                width="100%"
                height="125px"
              ></SafeImage>
              <span>{{ usecase.name }}</span>
            </router-link>
            <div
              v-else
              class="muted"
              v-tooltip="'Je hebt geen toegang tot deze use case'"
              style="cursor: not-allowed;"
            >
              <SafeImage
                :src="usecase.photo"
                width="100%"
                height="125px"
              ></SafeImage>
              <span>{{ usecase.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="stat-card" v-if="showProgressStats && showCityDetails">
      <h2 v-if="project && project.progress_section_title">
        {{ project.progress_section_title }}
      </h2>
      <h2 v-else>Voortgang</h2>
      <progress-stats
        :weekStart="0"
        :weekEnd="weekEnd"
        :data="rawData"
        :hasAmbitions="false"
        :kpis="kpis"
        :loading="gettingStatset"
        :year="year"
        :city="city"
      >
      </progress-stats>
    </section>

    <section class="stat-card" v-if="showCityDetails">
      <h2 v-if="project && project.emission_section_title">
        {{ project.emission_section_title }}
      </h2>
      <h2 v-else>Klimaat- en luchtkwaliteit</h2>

      <div class="split mb-2">
        <nav-tabs class="m-0">
          <ul class="keep-horizontal">
            <li>
              <a
                :class="activeVisual === 'donut' ? 'is-active' : ''"
                @click="changeVisual('donut')"
              >
                Verdeling
              </a>
            </li>
            <li>
              <a
                :class="activeVisual === 'segment' ? 'is-active' : ''"
                @click="changeVisual('segment')"
              >
                Segment
              </a>
            </li>
            <li v-if="showMap">
              <a
                :class="activeVisual === 'map' ? 'is-active' : ''"
                @click="changeVisual('map')"
              >
                Kaart
              </a>
            </li>
          </ul>
        </nav-tabs>
      </div>

      <climate-graphs-stats
        v-if="['donut', 'segment'].includes(activeVisual)"
        :activeVisual="activeVisual"
        mode="city"
        :chartType="chartType"
        :hasAmbitions="false"
        :data="rawData"
        :loading="gettingStatset"
        :weekStart="0"
        :weekEnd="weekEnd"
        :kpis="kpis"
        :project="project"
        :year="year"
        :city="city"
      >
      </climate-graphs-stats>

      <climate-map-stats
        v-else-if="showMap && activeVisual === 'map'"
        :hasAmbitions="false"
        :activeVisual="activeVisual"
        :showMap="showMap"
        :year="year"
        :data="rawData"
        :kpis="kpis"
        :weekStart="0"
        :weekEnd="weekEnd"
        :measurements="measurements"
        :loading="gettingStatset"
        :usecases="usecases"
        :city="city"
      >
      </climate-map-stats>
    </section>

    <section class="stat-card" v-if="showCityDetails">
      <h2 v-if="project && project.traffic_section_title">
        {{ project.traffic_section_title }}
      </h2>
      <h2 v-else>Verkeersimpact</h2>
      <traffic-stats
        :weekStart="0"
        :weekEnd="weekEnd"
        :hasAmbitions="false"
        :data="rawData"
        :loading="gettingStatset"
        :kpis="kpis"
        :year="year"
        :city="city"
        :project="project"
      ></traffic-stats>
    </section>

    <section class="stat-card" v-if="showCityDetails">
      <h2 v-if="project && project.conclusion_section_title">
        {{ project.conclusion_section_title }}
      </h2>
      <h2 v-else>Conclusie</h2>
      <conclusion-stats
        :weekStart="0"
        :weekEnd="weekEnd"
        :year="year"
        :data="rawData"
        :loading="gettingStatset"
        :kpis="kpis"
        :city="city"
      ></conclusion-stats>
    </section>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

import ErrorBox from "../components/ErrorBox.vue"
import SafeImage from "../components/SafeImage.vue"
import ChartPickerBar from "../components/ChartPickerBar.vue"
import ProgressStats from "../components/ProgressStats.vue"
import ClimateMapStats from "../components/ClimateMapStats.vue"
import ClimateGraphsStats from "../components/ClimateGraphsStats.vue"
import TrafficStats from "../components/TrafficStats.vue"
import NavTabs from "../components/NavTabs.vue"

import { MEASUREMENT_MAPPINGS } from "../config/env"
import { totalDuration, yearsToChoose } from "../lib/calculators"
import { weeksBetween, hasAGoal, startOfYear } from "../lib/helpers"
import ConclusionStats from "../components/ConclusionStats.vue"

export default Vue.extend({
  name: "CityPage",

  data() {
    return {
      showClimateDetails: false,
      yearSetInUrl: null,
      rawData: null,
      year: null,
      project: null,
      activeVisual: null,
      kpis: {}
    }
  },

  components: {
    ErrorBox,
    SafeImage,
    ChartPickerBar,
    ProgressStats,
    ClimateMapStats,
    ClimateGraphsStats,
    TrafficStats,
    ConclusionStats,
    NavTabs
  },

  mounted() {
    if (!this.activeVisual) this.activeVisual = "donut"
  },

  created() {
    this.yearSetInUrl = !!this.$route.query.year
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "gettingUsecaseList",
      "gettingCityList",
      "gettingStatset",
      "gettingProjectList",
      "gettingCityKPIData",
      "currentUser",
      "userIsAdmin"
    ]),

    chartType() {
      if (!this.project) return "doughnut"
      return this.project.use_barchart ? "bar" : "doughnut"
    },

    showCityDetails() {
      const hasAccess = this.city.current_user_has_access
      return typeof hasAccess === "boolean" ? hasAccess : true
    },

    showMap() {
      // If user can't see use cases, well, we will not show a map
      if (
        !this.projects ||
        !this.projects.length ||
        !this.userUsecases ||
        !this.userUsecases.length
      ) {
        return false
      }

      // We grab all project IDs for this city
      const projectIds = this.userUsecases
        .filter(({ city }) => city === this.city.city_id)
        .reduce((projectIds, { project }) => {
          if (!projectIds.includes(project)) projectIds.push(project)
          return projectIds
        }, [])

      // We filter the projects based on the projects above
      // and check if the map needs to be shown
      const show =
        this.projects.length &&
        this.projects
          .filter(({ project_id }) => projectIds.includes(project_id))
          .every(x => x.show_map)

      return show
    },

    weekEnd() {
      const now = new Date()
      const endOfYear = startOfYear(this.year + 1)

      return weeksBetween(this.year, now > endOfYear ? endOfYear : now, {
        rounded: false
      })
    },

    city() {
      const { slug: routeSlug } = this.$route.params
      return this.$store.state.cities.find(
        ({ slug }) => slug && slug === routeSlug
      )
    },

    usecases() {
      if (!this.city) return []
      return this.$store.state.usecases.filter(
        ({ city, project }) => city && city === this.city.city_id
      )
    },

    selectedUsecases() {
      if (!this.usecases) return []

      return this.usecases.filter(
        ({ project }) =>
          this.project === "all" ||
          (this.project && this.project.project_id === project)
      )
    },

    lastJourneyYear() {
      if (!this.usecases.length) return

      return this.selectedUsecases
        .filter(({ last_journey_date }) => last_journey_date)
        .map(({ last_journey_date }) => {
          return parseInt(last_journey_date.split("-")[0], 10)
        })
        .sort((left, right) => {
          return left > right ? 1 : -1
        })
        .pop()
    },

    projects() {
      return this.$store.state.projects
    },

    choosableProjects() {
      if (!this.projects || !this.usecases || !this.city) return []

      const activeProjects = this.usecases.reduce(
        (all, { project, city, organisations }) => {
          if (all.indexOf(project) === -1 && city === this.city.city_id) {
            const theProject = this.projects.find(
              ({ project_id }) => project_id === project
            )

            if (
              organisations.indexOf(this.currentUser.organisation) > -1 ||
              this.currentUser.userIsAdmin ||
              (theProject &&
                theProject.cityview_allowed_for.indexOf(
                  this.currentUser.organisation
                ) > -1)
            )
              all.push(project)
          }

          return all
        },
        []
      )

      return this.projects.filter(
        ({ project_id }) => activeProjects.indexOf(project_id) > -1
      )
    },

    showProgressStats() {
      return (
        hasAGoal(this.kpis["pm10-in-grams"]) ||
        hasAGoal(this.kpis["co2-in-grams"]) ||
        hasAGoal(this.kpis["rides-on-the-road"]) ||
        hasAGoal(this.kpis["pm10-in-grams"], "reference") ||
        hasAGoal(this.kpis["co2-in-grams"], "reference") ||
        hasAGoal(this.kpis["rides-on-the-road"], "reference")
      )
    },

    userUsecases() {
      if (!this.selectedUsecases) return []
      const userOrg = this.$store.state.user.organisation
      const isGod = this.$store.state.user.scopes
        ? this.$store.state.user.scopes.indexOf("god") > -1
        : false
      return this.selectedUsecases.map(usecase => {
        usecase.userHasAccess = isGod
          ? true
          : usecase.organisations.indexOf(userOrg) > -1
        return usecase
      })
    },

    usecaseIds() {
      return this.selectedUsecases.map(({ usecase_id }) => usecase_id)
    },

    measurements() {
      if (this.$store.state.measurements.length < 1) return []
      const relevantMeasurements = Object.keys(MEASUREMENT_MAPPINGS)

      return this.$store.state.measurements
        .filter(({ slug }) => relevantMeasurements.indexOf(slug) > -1)
        .map(measurement => ({
          ...measurement,
          key: MEASUREMENT_MAPPINGS[measurement.slug]
        }))
    },

    totalDuration,
    yearsToChoose
  },

  watch: {
    usecases(loadedUsecases) {
      if (!this.year)
        this.year = this.yearsToChoose[this.yearsToChoose.length - 1]
      this.fetchDataset()
      this.getKPIData()
    },

    choosableProjects() {
      if (!this.project) this.project = "all"
      if (this.choosableProjects.length === 1)
        this.project = this.choosableProjects[0]

      this.fetchDataset()
      this.getKPIData()
    },

    city() {
      this.fetchDataset()
      this.getKPIData()
    },

    year() {
      this.fetchDataset()
      this.getKPIData()
    },

    project() {
      this.fetchDataset()
      this.getKPIData()
    }
  },

  methods: {
    fetchDataset() {
      if (
        !this.city ||
        !this.showCityDetails ||
        this.usecases.length < 1 ||
        this.projects.length < 1
      )
        return

      if (this.$route.query.year) this.year = Number(this.$route.query.year)

      const datasetParams = {
        cityId: this.city.city_id,
        year: this.year
      }

      if (this.project && this.project !== "all")
        datasetParams.projectId = this.project.project_id

      this.$store.dispatch("getCityDataset", datasetParams).then(result => {
        if (result) this.rawData = result[0]
      })
    },

    async getKPIData() {
      const projectId = this.project
        ? this.project !== "all"
          ? this.project.project_id
          : this.project
        : null

      if (!this.city || !this.year || !projectId) return
      if (this.gettingCityKPIData) return

      this.kpis = await this.$store.dispatch("getCityKPIData", {
        projectId,
        cityId: this.city.city_id,
        year: this.year
      })
    },

    updateYear(year) {
      if (this.year === Number(year)) return

      this.year = year
      this.$router.replace({ query: { year } })
    },

    updateProject(projectId) {
      if (projectId === "all") this.project = "all"
      else
        this.project = this.projects.find(
          ({ project_id }) => project_id === projectId
        )

      this.fetchDataset()
    },

    changeVisual(type) {
      this.activeVisual = type
    }
  },

  beforeCreate() {
    return Promise.all([
      this.$store.dispatch("getProjects"),
      this.$store.dispatch("getCities"),
      this.$store.dispatch("getUsecases"),
      this.$store.dispatch("getMeasurements")
    ])
      .catch(({ message } = {}) => {
        if (message === "unauthorised") this.$router.push("/login")
      })
      .then(this.getKPIData)
  }
})
</script>

<style lang="scss" scoped>
.usecases-drawer {
  width: 100%;
  overflow-x: scroll;

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: auto;

    li {
      width: 250px;
      margin: 0 1.5rem;
      flex-grow: 0;
      flex-shrink: 0;

      a {
        text-decoration: none;
      }

      &:first-child {
        margin-left: 0;
      }

      figure {
        padding-bottom: 50%;
      }

      span {
        display: block;
        font-size: 1.1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: bold;

        &:before {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="%23538CBD" d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z"/></svg>');
          background-size: 14px 14px;
          background-position: center center;
          background-repeat: no-repeat;
          content: "";
          width: 14px;
          height: 14px;
          padding-right: 5px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
