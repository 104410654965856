export default {
  getVehicles(state, vehicles = []) {
    if (!state.authToken) return

    state.vehicles = vehicles
  },

  addVehicle(state, vehicle) {
    state.vehicles.push(vehicle)
  },

  updateVehicle(state, updatedVehicle) {
    state.vehicles = state.vehicles.map(vehicle =>
      Number(vehicle.vehicle_id) === Number(updatedVehicle.vehicle_id)
        ? { ...vehicle, ...updatedVehicle }
        : vehicle
    )
  },

  deleteVehicle(state, deletedVehicleId) {
    state.vehicles = state.vehicles.filter(
      ({ vehicle_id }) => deletedVehicleId !== vehicle_id
    )
  }
}
