<template>
  <div>
    <h3 slot="header">
      {{
        this.$route.params.slug ? "Project wijzigen" : "Voeg een project toe"
      }}
    </h3>
    <div
      slot="body"
      :class="gettingProjectList || savingProject ? 'loading-form' : ''"
    >
      <div class="from-horizontal mt-2">
        <div class="field">
          <label>Project naam</label>
          <input
            type="text"
            placeholder="Vul project naam in..."
            v-model="name"
          />
          <p class="error-message" v-if="$v.$dirty && $v.name.$error">
            Vul een (geldige) naam in
          </p>
        </div>
      </div>
      <div class="from-horizontal mt-2">
        <!-- Hier heb je een lijst nodig van orgs -->
        <div class="field">
          <label>Organisaties met toegang tot stadsniveau</label>
          <v-select
            :options="orgs"
            :value="cityviewOrgs"
            @input="updateCityviewOrg"
            label="name"
            multiple
            placeholder="Kies organisatie..."
          ></v-select>

          <p class="error-message" v-if="$v.$dirty && $v.org.$error">
            Hier gaat iets mis!
          </p>
        </div>
      </div>

      <div class="mt-2">
        <div class="field">
          <label>Titel voortgang sectie</label>
          <input
            type="text"
            placeholder="Vul een titel in..."
            v-model="progress_section_title"
          />
          <p
            class="error-message"
            v-if="$v.$dirty && $v.progress_section_title.$error"
          >
            Vul een (geldige) titel in
          </p>
        </div>
        <div class="field">
          <label>Titel emissie sectie</label>
          <input
            type="text"
            placeholder="Vul een titel in..."
            v-model="emission_section_title"
          />
          <p
            class="error-message"
            v-if="$v.$dirty && $v.emission_section_title.$error"
          >
            Vul een (geldige) titel in
          </p>
        </div>
        <div class="field">
          <label>Titel verkeers-sectie</label>
          <input
            type="text"
            placeholder="Vul een titel in..."
            v-model="traffic_section_title"
          />
          <p
            class="error-message"
            v-if="$v.$dirty && $v.traffic_section_title.$error"
          >
            Vul een (geldige) titel in
          </p>
        </div>
        <div class="field">
          <label>Titel conclusie sectie</label>
          <input
            type="text"
            placeholder="Vul een titel in..."
            v-model="conclusion_section_title"
          />
          <p
            class="error-message"
            v-if="$v.$dirty && $v.conclusion_section_title.$error"
          >
            Vul een (geldige) titel in
          </p>
        </div>
      </div>

      <div class="from-horizontal mt-2">
        <div class="field">
          <label>
            <input type="checkbox" v-model="use_ambitions" />
            Heeft ambitie
          </label>
        </div>
        <div class="field">
          <label>
            <input type="checkbox" v-model="show_map" />
            Toon kaart
          </label>
        </div>
        <div class="field">
          <label>
            <input type="checkbox" v-model="use_barchart" />
            Gebruik barcharts
          </label>
        </div>
      </div>
      <button class="mt-2" @click="save" :disabled="savingProject">
        Opslaan
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import {
  required,
  numeric,
  minLength,
  minValue
} from "vuelidate/lib/validators"
import vSelect from "vue-select"

import { properName } from "../../../lib/validators"

import "vue-select/dist/vue-select.css"

export default Vue.extend({
  name: "AdminProjectsProjectForm",

  data() {
    return {
      name: null,
      show_map: false,
      use_ambitions: false,
      use_barchart: false,
      cityview_allowed_for: [],
      progress_section_title: null,
      emission_section_title: null,
      traffic_section_title: null,
      conclusion_section_title: null
    }
  },

  validations: {
    name: { required, properName, minLength: minLength(2) },
    org: { minLength: 0, $each: { required, minValue: minValue(0) } },
    progress_section_title: { minLength: 2 },
    emission_section_title: { minLength: 2 },
    traffic_section_title: { minLength: 2 },
    conclusion_section_title: { minLength: 2 }
  },

  computed: {
    ...mapGetters([
      "gettingUsecaseList",
      "gettingOrgList",
      "gettingProjectList",
      "savingProject"
    ]),
    usecases() {
      return this.$store.state.usecases.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    orgs() {
      return this.$store.state.orgs.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    projects() {
      return this.$store.state.projects.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    project() {
      if (!this.projects) return
      return this.projects.find(({ slug }) => slug === this.$route.params.slug)
    },
    slug() {
      return this.$route.params.slug
    },
    cityviewOrgs() {
      if (!this.orgs || this.orgs.length < 1) return []
      return this.orgs.filter(
        ({ organisation_id }) =>
          this.cityview_allowed_for &&
          this.cityview_allowed_for.indexOf(organisation_id) > -1
      )
    }
  },

  components: { vSelect },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getProjects")
  },

  methods: {
    async save() {
      if (this.savingProject) return

      this.$v.$touch()
      if (this.$v.$invalid) return

      const action = this.project ? "updateProject" : "addProject"

      const payload = {
        name: this.name,
        use_ambitions: this.use_ambitions,
        show_map: this.show_map,
        use_barchart: this.use_barchart,
        cityview_allowed_for: this.cityview_allowed_for,
        progress_section_title: this.progress_section_title,
        emission_section_title: this.emission_section_title,
        traffic_section_title: this.traffic_section_title,
        conclusion_section_title: this.conclusion_section_title
      }
      if (this.project) payload.project_id = this.project.project_id

      await this.$store.dispatch(action, payload)
      this.$router.push("/admin/projects")
    },

    updateCityviewOrg(newValue) {
      this.cityview_allowed_for = newValue.map(
        ({ organisation_id }) => organisation_id
      )
    }
  },

  beforeCreate() {
    this.$store.dispatch("getOrgs")
    this.$store.dispatch("getCities")
    this.$store.dispatch("getProjects")

    this.$store.dispatch("getProjects", { queueWork: true }).then(() => {
      if (this.project) {
        this.name = this.project.name
        this.cityview_allowed_for = this.project.cityview_allowed_for
        this.show_map = this.project.show_map
        this.use_ambitions = this.project.use_ambitions
        this.use_barchart = this.project.use_barchart
        this.progress_section_title = this.project.progress_section_title
        this.emission_section_title = this.project.emission_section_title
        this.traffic_section_title = this.project.traffic_section_title
        this.conclusion_section_title = this.project.conclusion_section_title
      }

      if (this.$v) this.$v.$reset()
    })
  },

  async beforeRouteUpdate(to, from, next) {
    if (!to.params.slug) {
      this.name = null
      this.cityview_allowed_for = null
      this.show_map = null
      this.use_ambitions = null
      this.use_barchart = null
      this.progress_section_title = null
      this.emission_section_title = null
      this.traffic_section_title = null
      this.conclusion_section_title = null

      if (this.$v) this.$v.$reset()

      return next()
    }

    const project = this.projects.find(({ slug }) => slug === to.params.slug)

    if (project) {
      this.name = project.name
      this.cityview_allowed_for = project.cityview_allowed_for
      this.show_map = project.show_map
      this.use_ambitions = project.use_ambitions
      this.use_barchart = project.use_barchart
      this.progress_section_title = project.progress_section_title
      this.emission_section_title = project.emission_section_title
      this.traffic_section_title = project.traffic_section_title
      this.conclusion_section_title = project.conclusion_section_title

      if (this.$v) this.$v.$reset()
    }

    next()
  }
})
</script>
