<template>
  <file-pond
    :server="server"
    class="filepond--cover"
    :label-idle="label"
    :image-crop-aspect-ratio="aspectRatio"
    :allow-multiple="false"
    :accepted-file-types="fileTypes"
    :files="fileArray"
  ></file-pond>
</template>

<script>
import Vue from "vue"
import { API_URI } from "../config/env"
import vueFilePond from "vue-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginImageCrop from "filepond-plugin-image-crop"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
)

export default Vue.extend({
  name: "UploadFile",

  props: [
    "crop",
    "type",
    "label",
    "fileTypes",
    "aspectRatio",
    "file",
    "resource",
    "maxDimensions",
    "onUpload"
  ],

  computed: {
    fileArray() {
      if (!this.file) return
      return [
        {
          source: this.file,
          options: { type: "local" }
        }
      ]
    },

    server() {
      const { authToken } = this.$store.state

      let processUri = `/upload?type=${this.type}`
      if (this.crop) processUri += `&crop=${encodeURIComponent(this.crop)}`
      if (this.resource)
        processUri += `&resource=${encodeURIComponent(this.resource)}`
      if (this.maxDimensions)
        processUri += `&max_dimensions=${encodeURIComponent(
          this.maxDimensions
        )}`

      return {
        url: API_URI,

        load: {
          url: "/files/",
          headers: { Authorization: `Bearer ${authToken}` }
        },

        process: {
          url: processUri,
          headers: { Authorization: `Bearer ${authToken}` },
          onload: filePath => this.onUploadComplete(filePath)
        }
      }
    }
  },

  methods: {
    onUploadComplete(filePath) {
      if (typeof this.onUpload === "function") this.onUpload(filePath)
    }
  }
})
</script>

<style lang="scss" scoped>
figure {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $grey-lighter;

  &.round {
    border-radius: 1000px;
  }
}
</style>
