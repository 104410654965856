export default {
  getPages(state, pages = []) {
    if (!state.authToken) return

    state.pages = pages
  },

  addPage(state, page) {
    state.pages.push(page)
  },

  addPages(state, pages) {
    const insertedPages = []
    for (const updatedPage of pages) {
      state.pages = state.pages.map(storePage => {
        const matched =
          Number(storePage.page_id) === Number(updatedPage.page_id)
        if (matched) insertedPages.push(storePage.page_id)
        return matched ? { ...storePage, ...updatedPage } : storePage
      })
    }
    for (const updatedPage of pages) {
      if (insertedPages.indexOf(updatedPage.page_id) === -1)
        state.pages.push(updatedPage)
    }
  },

  updatePage(state, updatedPage) {
    state.pages = state.pages.map(page =>
      Number(page.page_id) === Number(updatedPage.page_id)
        ? { ...page, ...updatedPage }
        : page
    )
  },

  deletePage(state, deletedPageId) {
    state.pages = state.pages.filter(({ page_id }) => deletedPageId !== page_id)
  }
}
