/*
  This is a simple Ajax library that formats our API calls. I prefer to use this re-usable
  lib cause it's simple and accounts for a customisable timeout, as well as wraps errors,
  sets headers, etc.
*/

import { API_URI, ENV_NAME } from "../config/env"

export default async (
  endpoint,
  {
    authToken,
    method = "GET",
    body,
    file,
    timeout = 20000,
    noReply = false,
    headers
  } = {}
) => {
  const uri = `${API_URI}/${endpoint}`
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers
    }
  }

  if (authToken) options.headers.Authorization = `Bearer ${authToken}`
  if (body)
    options.body = typeof body === "string" ? body : JSON.stringify(body)

  if (file) {
    options.headers["Content-Type"] = file.type
    options.body = file
  }

  const onTimeout = setTimeout(() => {
    throw new Error(`network_timeout on ${endpoint}`)
  }, timeout)
  let response

  try {
    const request = await fetch(uri, options)
    if (request.status === 401) throw new Error("unauthorised")
    if (noReply) return
    response = await request.json()
  } catch (error) {
    if (ENV_NAME !== "production") console.error(error, uri)
    if (error.message === "unauthorised") throw error
    throw new Error("network_request_failed")
  } finally {
    clearTimeout(onTimeout)
  }

  if (response.error) {
    throw new Error(response.message || response.error)
  }

  return response
}
