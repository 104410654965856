<template>
  <div>
    <nav-tabs>
      <ul>
        <li>
          <router-link to="/admin/users" :class="isActiveTab('users')"
            >Gebruikers</router-link
          >
        </li>
        <li>
          <router-link
            to="/admin/organisations"
            :class="isActiveTab('organisations')"
            >Organisaties</router-link
          >
        </li>
        <li>
          <router-link to="/admin/projects" :class="isActiveTab('projects')"
            >Projects</router-link
          >
        </li>
        <li>
          <router-link to="/admin/usecases" :class="isActiveTab('usecases')"
            >Use cases</router-link
          >
        </li>
        <li>
          <router-link to="/admin/cities" :class="isActiveTab('cities')"
            >Steden</router-link
          >
        </li>
        <li>
          <router-link to="/admin/kpis" :class="isActiveTab('kpis')"
            >KPIs</router-link
          >
        </li>
        <li>
          <router-link to="/admin/vehicles" :class="isActiveTab('vehicles')"
            >Vervoersmiddelen</router-link
          >
        </li>
        <li>
          <router-link to="/admin/segments" :class="isActiveTab('segments')"
            >Logistieke segmenten</router-link
          >
        </li>
        <li>
          <router-link to="/admin/pages" :class="isActiveTab('pages')"
            >Pagina's</router-link
          >
        </li>
      </ul>
      <div class="ml-a" v-if="showAddButton()">
        <router-link :to="addLink()" class="button">Nieuw</router-link>
      </div>
    </nav-tabs>
  </div>
</template>

<script>
import Vue from "vue"
import Modal from "./Modal.vue"
import NavTabs from "./NavTabs.vue"

export default Vue.extend({
  name: "AdminTabs",

  components: { Modal, NavTabs },

  data() {
    return {}
  },

  methods: {
    isActiveTab(tab) {
      return this.$route.path.startsWith(`/admin/${tab}`) ? "is-active" : ""
    },
    currentPageName() {
      return this.$route.path.split("/")[2].slice(0, -1)
    },
    showAddButton() {
      if (this.$route.path.endsWith("create")) return false
      if (this.$route.path.indexOf("/update/") > -1) return false

      for (const route of this.$route.matched) {
        if (route.meta && route.meta.showAddButton)
          return route.meta.showAddButton
      }

      return false
    },
    addLink() {
      return `${this.$route.path}/create`
    }
  }
})
</script>
