<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Titel</th>
          <th>URL</th>
          <th>Use case</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(page, index) in pages" :key="index">
          <td>{{ page.title }}</td>
          <td>
            <router-link :to="`/page/${page.slug}`"
              >/page/{{ page.slug }}</router-link
            >
          </td>
          <td>{{ usecaseName(page.usecase) }}</td>
          <td class="ta-r actions">
            <router-link :to="`/admin/pages/update/${page.slug}`"
              >Wijzig</router-link
            >
            <a
              v-if="userIsAdmin && page.slug !== 'tno-berekeningen'"
              v-on:click="deletePage(page)"
            >
              Verwijder
            </a>
            <a
              v-else-if="userIsAdmin && page.slug === 'tno-berekeningen'"
              v-tooltip="
                'Deze pagina wordt gelinkt in de app en kan daarom niet verwijderd worden'
              "
              class="muted"
            >
              Verwijder
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <modal
      v-if="creating"
      @close="closeModal"
      @submit="save"
      :buttonDisabled="saving"
    >
      <h3 slot="header">Voeg een pagina toe</h3>
      <div slot="body">
        <div class="field">
          <label>Titel</label>
          <input
            type="text"
            v-model.trim="title"
            placeholder="Bijv. TNO berekeningen"
          />
          <p class="error-message" v-if="$v.$dirty && !$v.title.required">
            Vul een (geldige) titel in
          </p>
        </div>
        <div class="field">
          <label>Naam in URL</label>
          <input
            type="text"
            v-model.trim="slug"
            @keyup="userTouchedSlug = true"
            placeholder="Bijv. tno-berekeningen"
          />
          <p class="error-message" v-if="$v.$dirty && !$v.slug.required">
            Vul een naam in
          </p>
          <p class="error-message" v-else-if="$v.$dirty && !$v.slug.isSlug">
            Vul een geldige naam in
          </p>
        </div>
        <div class="field">
          <label>Use case</label>
          <select v-model="usecase">
            <option disabled selected="selected">Kies een use case...</option>
            <option :value="null">Geen use case, algemene pagina</option>
            <option
              v-for="(usecase, index) in usecases"
              :key="index"
              :value="usecase.usecase_id"
              >{{ usecase.name }}</option
            >
          </select>
          <p class="error-message" v-if="$v.$dirty && $v.usecase.$error">
            Selecteer een use case
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import Modal from "../../components/Modal.vue"
import { required, minLength, numeric } from "vuelidate/lib/validators"
import { isSlug } from "../../lib/validators"

export default Vue.extend({
  name: "Pages",

  components: { Modal },

  validations: {
    title: { required, minLength: minLength(2) },
    slug: { required, minLength: minLength(2), isSlug },
    usecase: { numeric }
  },

  data() {
    return {
      title: "",
      slug: "",
      usecase: null,
      userTouchedSlug: false,
      saving: false
    }
  },

  computed: {
    pages() {
      return this.$store.state.pages.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
      })
    },
    creating() {
      return this.$route.path.endsWith("/create")
    },
    usecases() {
      return this.$store.state.usecases
    },
    ...mapGetters(["userIsAdmin"])
  },

  watch: {
    title(newTitle) {
      if (!this.userTouchedSlug)
        this.slug = newTitle
          .toLowerCase()
          .replace(/[^a-z0-9-]+/g, "-")
          .replace(/(^-|-$)/g, "")
    }
  },

  methods: {
    usecaseName(usecaseId) {
      const usecase = this.usecases.find(
        ({ usecase_id }) => usecase_id === usecaseId
      )
      if (usecase) return usecase.name
      return ""
    },

    closeModal() {
      return this.$router.push("/admin/pages")
    },

    async save() {
      if (this.saving) return
      this.saving = true

      this.$v.$touch()
      if (this.$v.$invalid) return (this.saving = false)

      const payload = {
        title: this.title,
        slug: this.slug
      }

      if (this.usecase) payload.usecase = this.usecase

      const error = await this.$store.dispatch("addPage", payload)
      if (!(error instanceof Error)) {
        this.title = ""
        this.slug = ""
        this.usecase = null
        if (this.$v) this.$v.$reset()
        this.closeModal()
      }
      this.saving = false
    },

    deletePage({ page_id, title }) {
      if (
        !confirm(
          `Weet je zeker dat je deze pagina (${title}) wilt verwijderen?`
        )
      )
        return

      this.$store.dispatch("deletePage", page_id)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
    this.$store.dispatch("getPages")
  }
})
</script>

<style lang="scss" scoped></style>
