<template>
  <div>
    <nav-tabs>
      <ul>
        <li>
          <router-link to="/data/import" :class="isActiveTab('import')"
            >Importeren</router-link
          >
        </li>
        <li>
          <router-link to="/data/export" :class="isActiveTab('export')"
            >Exporteren</router-link
          >
        </li>
      </ul>
    </nav-tabs>
  </div>
</template>

<script>
import Vue from "vue"
import Modal from "./Modal.vue"
import NavTabs from "./NavTabs.vue"

export default Vue.extend({
  name: "DataTabs",

  components: { Modal, NavTabs },

  methods: {
    isActiveTab(tab) {
      return this.$route.path.startsWith(`/data/${tab}`) ? "is-active" : ""
    }
  }
})
</script>
