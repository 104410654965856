export default {
  currentAction(state, action) {
    if (state.currentWork.indexOf(action) > -1) return
    state.currentWork = [...state.currentWork, action]
  },

  completeAction(state, action) {
    if (state.currentWork.indexOf(action) === -1) return
    state.currentWork = state.currentWork.filter(
      runningAction => runningAction !== action
    )
  }
}
