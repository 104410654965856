<template>
  <div>
    <p class="loading-message" v-if="gettingUsecaseList">
      Use case aan het laden...
    </p>

    <div v-else v-for="usecase of usecases" class="upload-data-box">
      <div>
        <h2>{{ usecase.name }}</h2>
        <p class="mb-0" v-if="usecase.last_journey_date">
          <a :href="downloadUri + usecase.usecase_id"
            >Download een zip-bestand</a
          >
          met alle geimporteerde data van use case {{ usecase.name }}.
        </p>
        <p class="mb-0" v-else>
          Er is nog geen data voor use case {{ usecase.name }}.
        </p>
      </div>
      <div class="field ml-a" v-if="usecase.last_journey_date">
        <label>Scheidingsteken</label>
        <select v-model="delimiter">
          <option v-for="delimiter in delimiters" :value="delimiter.value">{{
            delimiter.label
          }}</option>
        </select>
      </div>
      <div class="ml-2" v-if="usecase.last_journey_date">
        <a class="button button--small" :href="downloadUri + usecase.usecase_id"
          >Download</a
        >
      </div>
      <div v-else class="ml-a"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"

import { API_URI, CSVU_DELIMITERS } from "../../config/env"

export default Vue.extend({
  data() {
    return {
      delimiter: CSVU_DELIMITERS[0].value,
      delimiters: CSVU_DELIMITERS
    }
  },

  computed: {
    ...mapGetters(["gettingUsecaseList", "currentUser"]),

    usecases() {
      return this.$store.state.usecases.filter(
        ({ organisations }) =>
          organisations &&
          organisations.indexOf(this.currentUser.organisation) > -1
      )
    },
    downloadUri() {
      return `${API_URI}/export?delimiter=${this.delimiter}&auth_token=${this.$store.state.authToken}&usecase_id=`
    }
  },

  methods: {
    uploadData(usecase) {
      alert(`wow we are uploading massive datas for ${usecase.name}!`)
    }
  },

  beforeCreate() {
    this.$store.dispatch("getUsecases")
  }
})
</script>

<style lang="scss" scoped>
.upload-data-box {
  border: 1px solid $muted;
  padding: 2em;
  display: flex;
  margin-bottom: 2em;
  align-items: center;
  justify-content: center;

  select {
    border-bottom: none;
  }

  h2 {
    color: $tno-blue;
    font-size: 1.1em;
  }
}

select {
  width: 170px;
}
</style>
