export default {
  getKPIs(state, kpis = []) {
    if (!state.authToken) return

    state.kpis = kpis
  },

  addKPI(state, kpi) {
    state.kpis.push(kpi)
  },

  updateKPI(state, updatedKPI) {
    state.kpis = state.kpis.map(kpi =>
      Number(kpi.kpi_id) === Number(updatedKPI.kpi_id)
        ? { ...kpi, ...updatedKPI }
        : kpi
    )
  },

  deleteKPI(state, deletedKPIId) {
    state.kpis = state.kpis.filter(({ kpi_id }) => deletedKPIId !== kpi_id)
  }
}
